import {Card, Checkbox, Layout} from "antd";
import {useEffect, useState} from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {Book, Building3, Edit2, PathTool, Personalcard, Setting2, Trash, User, UserOctagon} from "iconsax-react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../components/ui/alert-dialog";
import { Editor } from "@tinymce/tinymce-react";
import {getFetcher} from "../../../API";
import {Button} from "../../../components/ui/button";
import {Input} from "../../../components/ui/input";
import {Label} from "../../../components/ui/label";
import {Switch} from "../../../components/ui/switch";
import {postFetcher} from "../../../lib/API";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";

const { Content } = Layout;

export default function DistanceUnits() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [distanceUnits, setDistanceUnits] = React.useState<any[]>([])
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [form, setForm] = useState({
        titleAr: '',
        titleEn: '',
        is_active: false
    });
    const [currentForm, setCurrentForm] = useState({
        titleAr: '',
        titleEn: '',
        is_active: false
    });


    async function fetchData(){
        try {
            const request = await getFetcher(token,"distance-units",true)
            if (request) setLoading(false)
            setDistanceUnits(request.distanceUntis)


            console.log(request)
        }catch (e) {

        }
    }


    useEffect(() => {
        window.document.title = "الاعدادات"

        fetchData()
    }, [])
    const handleFormInputChange = (event:any, field:any) => {
        const value = event.target.value;
        if (isEditing) {
            setCurrentForm(prevForm => ({ ...prevForm, [field]: value }));
        } else {
            setForm(prevForm => ({ ...prevForm, [field]: value }));
        }
    };

    const handleCheckboxChange = (event:any) => {
        const isChecked = event.target.checked;
        if (isEditing) {
            setCurrentForm(prevForm => ({ ...prevForm, is_active: isChecked }));
        } else {
            setForm(prevForm => ({ ...prevForm, is_active: isChecked }));
        }
    };

    const handleEdit = (item:any) => {
        setIsEditing(true);
        setCurrentForm({
            titleAr: item.translation[0].value,
            titleEn: item.translation[1].value,
            is_active: item.is_active,
        });
        setOpen(true);
        setDeleteId(item.id);
    };

    const handleDelete = (id:any) => {
        setDeleteId(id);
        setIsDeleting(true);
    };

    const handleCloseDialog = () => {
        setForm({ titleAr: '', titleEn: '', is_active: false });
        setCurrentForm({ titleAr: '', titleEn: '', is_active: false });
        setOpen(false);
        setIsEditing(false);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}distance-units/${deleteId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            if (response.ok) {
                toast.success('تم حذف الوحدة بنجاح');
                setIsDeleting(false);
                setDeleteId('');
                fetchData();
            } else {
                throw new Error('Failed to delete distance unit');
            }
        } catch (error) {
            console.error('Error deleting distance unit:', error);
            toast.error('Failed to delete distance unit');
        }
    };

    const handleSaveUnit = async () => {
        try {
            let endpoint;
            let method;
            let body;

            if (isEditing) {
                endpoint = `${process.env.REACT_APP_DEV_ADMIN_API_URL}distance-units/${deleteId}`;
                method = 'PUT';
                body = currentForm;
            } else {
                endpoint = `${process.env.REACT_APP_DEV_ADMIN_API_URL}distance-units`;
                method = 'POST';
                body = form;
            }

            const response = await fetch(endpoint, {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                toast.success(isEditing ? 'تم تعديل الوحدة بنجاح' : 'تم إضافة الوحدة بنجاح');
                setOpen(false);
                setIsEditing(false);
                fetchData();
            } else {
                throw new Error('Failed to save distance unit');
            }
        } catch (error) {
            console.error('Error saving distance unit:', error);
            toast.error('Failed to save distance unit');
        }
    };






    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <PathTool variant="Bulk"
                                                          size="48" color="#475467" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>وحدة المسافة</h1>
                                        </div>

                                            <div>
                                                <Button onClick={() => {
                                                    setIsDeleting(false)
                                                    setIsEditing(false)
                                                    setOpen(true)
                                                }
                                                }>
                                                    اضافة وحدة جديدة
                                                </Button>
                                            </div>
                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div className="mx-4 mt-6">
                            <div className="grid grid-cols-3 gap-4">
                                {distanceUnits.map((item) => (
                                    <Card key={item.id}>
                                        <CardHeader>
                                            <div className="flex items-center justify-between flex-row">
                                                <div className="flex items-center gap-4">
                                                    <div style={{ background: '#f9f9f9' }} className="p-2 rounded-md">
                                                        <PathTool variant="Bulk" size="48" color="#475467" />
                                                    </div>
                                                    <h1 className="text-xl">{item.title}</h1>
                                                    <h1 className={`text-black mr-2 ${item.is_active ? 'success-text' : 'error-text'}`}>
                                                        ( {item.is_active ? 'مفعل' : 'غير مفعل'} )
                                                    </h1>
                                                </div>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                        <Icon width={24} height={24} className="cursor-pointer" icon="pepicons-pop:dots-y" />
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent className="w-56">
                                                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                        <DropdownMenuSeparator />
                                                        <DropdownMenuGroup>
                                                            <DropdownMenuItem onClick={() => handleEdit(item)}>
                                                                <Edit2 color="#475467" variant="Bulk" className="ml-2 h-8 w-8" />
                                                                <span>تعديل</span>
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem onClick={() => handleDelete(item.id)}>
                                                                <Trash color="#F04438" variant="Bulk" className="ml-2 h-8 w-8" />
                                                                <span>حذف</span>
                                                            </DropdownMenuItem>
                                                        </DropdownMenuGroup>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        </CardHeader>
                                        <CardContent>
                                            <div className="flex items-center mr-2">{item.description}</div>
                                        </CardContent>
                                    </Card>
                                ))}
                                <AlertDialog open={open}>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>{isEditing ? 'تعديل وحدة المسافة' : 'إضافة وحدة جديدة'}</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                <div className="flex flex-col gap-4">
                                                    <form className="grid grid-cols-2 gap-4" action="">
                                                        <div>
                                                            <Label className="my-1">العنوان بالعربي</Label>
                                                            <Input
                                                                value={isEditing ? currentForm.titleAr : form.titleAr}
                                                                onChange={(event) => handleFormInputChange(event, 'titleAr')}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Label>العنوان بالانجليزي</Label>
                                                            <Input
                                                                value={isEditing ? currentForm.titleEn : form.titleEn}
                                                                onChange={(event) => handleFormInputChange(event, 'titleEn')}
                                                            />
                                                        </div>
                                                        <div className="flex items-start">
                                                            <Checkbox checked={isEditing ? currentForm.is_active : form.is_active} onChange={handleCheckboxChange}>
                                                                مفعل
                                                            </Checkbox>
                                                        </div>
                                                    </form>
                                                </div>
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel onClick={handleCloseDialog}>الغاء</AlertDialogCancel>
                                            <AlertDialogAction onClick={handleSaveUnit}>اضافة الوحدة</AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                                <AlertDialog open={isDeleting}>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>هل أنت متأكد من الحذف؟</AlertDialogTitle>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel onClick={() => setIsDeleting(false)}>الغاء</AlertDialogCancel>
                                            <AlertDialogAction onClick={handleConfirmDelete}>تأكيد الحذف</AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            </div>
                        </div>


                    </Content>
                </>
            )}


        </>
    )
}