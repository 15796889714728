import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import {  useNavigate } from "react-router-dom";
import {
    House,
    Trash,
    Star,
    Star1,
    Personalcard,
    Eye,
    ProgrammingArrow,
    TickCircle,
    PenClose,
    UserOctagon
} from "iconsax-react";
import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { Icon } from "@iconify/react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {putFettcher} from "../../../API";

const { Content } = Layout;

export default function Reviews() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [currentSelected, setCurrentSelected] = useState("client")
    const [data, setData] = useState([])

    const translations: { [key: string]: string }[] = [{
        "client": "العملاء",
        "hosting": "أصحاب الوحدات السكنية",
        "hosting-requests": "طلبات التقيم للوحدات السكنية",
        "properties": "الوحدات السكنية"
    }];

 
    const selectedTranslation = translations.find((item) => item[currentSelected]);
    const translationText = selectedTranslation ? selectedTranslation[currentSelected] : '';


    async function deleteReview(id: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}reviews/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            const data = await response.json();
            if (response.status == 200) {
                setLoading(false)
                toast.success("تم الحذف بنجاح")
                window.location.reload()
            } else {
                dispatch(setIsAuth(false))
                dispatch(setUserToken(''))
                navigate('/auth/admin/login')
            }
        }
        catch (error) {
            console.error(error)
        }

    }
    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}reviews/${currentSelected}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.reviews)
            } else {

            }
        } catch (error) {
            dispatch(setIsAuth(false))
            dispatch(setUserToken(''))
            navigate('/auth/admin/login')
            console.error(error)
        }

    }





    useEffect(() => {
        window.document.title = "التقيمات";

        fetchData()

    }, [currentSelected])





    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <Star variant="Bulk"
                                                    size="32" color="#475467" />

                                            </div>
                                            <div>
                                                <h1 className="text-xl">التقيمات ( {translationText} )</h1>
                                            </div>



                                        </div>

                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Icon width={24} height={24} className="cursor-pointer" icon="pepicons-pop:dots-y" />

                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="w-56">
                                                <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                <DropdownMenuSeparator />
                                                <DropdownMenuGroup>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("client")}>
                                                        <Personalcard
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>تقيمات العملاء</span>
                                                    </DropdownMenuItem>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("hosting")}>
                                                        <UserOctagon
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>تقيمات أصحاب الوحدات السكنية </span>
                                                    </DropdownMenuItem>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("properties")}>
                                                        <House
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>
                                                            تقيمات الوحدات السكنية
                                                        </span>
                                                    </DropdownMenuItem>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("hosting-requests")}>
                                                        <ProgrammingArrow
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>
                                                         طلبات التقيم للوحدات السكنية
                                                        </span>
                                                    </DropdownMenuItem>




                                                </DropdownMenuGroup>

                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>

                                </CardHeader>
                            </Card>
                        </div>
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mx-6 mt-6">


                            {data?.map((item: any) => (
                                <Card>
                                    <CardHeader>


                                        <div className="flex items-center justify-between flex-row">
                                            <div className="flex items-center">
                                                <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                    <Personalcard variant="Bulk"
                                                        size="32" color="#475467" />
                                                </div>
                                                <h1>
                                                    {item.customer.name}
                                                </h1>
                                                <div className="flex items-center mr-2">
                                                    <Star color="#1D2939" variant="Bulk"></Star>
                                                    {item.rate}
                                                </div>

                                            </div>

                                            <h1 className={`text-black mr-2 ${item.is_published ? "success-text" : "error-text"}`}>( {item.is_published ? "مفعل" : "غير مفعل"} )</h1>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        <span>
                                            {item.review}

                                        </span>
                                    </CardContent>
                                    <CardFooter>

                                        <div className="rtl">
                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button>
                                                        تعديل
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent style={{direction:"rtl"}} className="w-56 rtl">
                                                    <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                    <DropdownMenuSeparator />
                                                    <DropdownMenuGroup>


                                                        <DropdownMenuItem onSelect={() => deleteReview(item.id)}>
                                                            <Trash
                                                                color="#F04438"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>حذف</span>
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem className="gap-4" onSelect={() =>{
                                                            const request = putFettcher(token, `reviews/${item.id}`, {
                                                                is_published: true,
                                                                id: item.id
                                                            })
                                                            request.then((response) => {
                                                                if(response.status == 200){
                                                                    toast.success("تم نشر التقيم بنجاح")
                                                                    fetchData()

                                                                }
                                                            })
                                                        }}>
                                                            <TickCircle
                                                                color="#36D7B7"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>
                                                                نشر التقيم
                                                            </span>
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem onSelect={() =>{
                                                            const request = putFettcher(token, `reviews/${item.id}`, {
                                                                is_published: false,
                                                                id: item.id
                                                            })
                                                            request.then((response) => {
                                                                if(response.status == 200){
                                                                    toast.success("تم إلغاء نشر التقيم بنجاح")
                                                                    fetchData()

                                                                }
                                                            })
                                                        }}>
                                                            <PenClose
                                                                color="#475467"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>
                                                                إلغاء نشر التقيم
                                                            </span>
                                                        </DropdownMenuItem>


                                                    </DropdownMenuGroup>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </div>
                                    </CardFooter>
                                </Card>
                            ))}

                        </div>








                    </Content>
                </>
            )}




        </>
    )
}