import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";

import { Textarea } from "src/components/ui/textarea";
import { SelectShadncn, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import GoogleMapReact from 'google-map-react';
import {LocationAdd} from "iconsax-react";
import {useMediaQuery} from "react-responsive";
import Carousel from 'react-bootstrap/Carousel';

const { Content } = Layout;

export default function PropertyView() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const status = [
    {
      arabic: "مفعل",
      english: "PUBLISHED",
    },
    {
      arabic: "قيد المراجعة",
      english: "PENDING",
    },
    {
      arabic: "مرفوض",
      english: "REJECTED",
    },
    {
      arabic: "مسودة",
      english: "DRAFT",
    }
  ]
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isEthereumAvailable, setIsEthereumAvailable] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsEthereumAvailable(true);
    }
  }, [typeof window]);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    centerMode: true,
    speed: 500,
    slidesToShow: isEthereumAvailable && isMobile ? 1 : 3,
    swipeToSlide: true,
    arrows: false,
  };
  console.log(state);

  const MarkerComponent = ({ lat, lng, text }: any) => (
      <div><LocationAdd size="32" color="#2ccce4" variant="Bulk" /></div>
  );
  // @ts-ignore
  const defaultProps = {
    center: {
      lat: state.latitude,
      lng: state.longitude
    },
    zoom: 11
  };
  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <h1 className="text-xl">بيانات الوحدة السكنية  ({state.title})</h1>
                    <div>
                      <Label>الحالة</Label>
                      <SelectShadncn onValueChange={async (value) => {
                        const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties/status/${state.id}`, {
                          method: 'PUT',
                          headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${token}`,
                            "Accept": "application/json"
                          },
                          body: JSON.stringify({
                                status: value

                              }
                          )
                        })
                        if (request.status === 200) {
                          toast.success('تم تغيير حالة الوحدة بنجاح')
                        }
                      }}>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder={status.find(s => s.english === state.status)?.arabic}/>
                        </SelectTrigger>
                        <SelectContent>
                          {
                            status.map((s) => (
                                <SelectItem key={s.english} value={s.english}>{s.arabic}</SelectItem>
                            ))
                          }
                        </SelectContent>
                      </SelectShadncn>
                    </div>
                  </div>

                </CardHeader>
              </Card>
            </div>
            <div className=" mx-6 mt-6">
              <Card>
                <CardHeader>
                  <CardTitle>بيانات الوحدة السكنية</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <Label>اسم الوحدة السكنية</Label>
                      <Input
                          type="text"
                          value={state.title ? state.title : ""}
                          disabled
                      />
                    </div>
                    <div>
                      <Label>رقم الوحدة السكنية</Label>
                      <Input
                          type="text"
                          value={state.id ? state.id : ""}
                          disabled
                      />
                    </div>
                    <div>
                      <Label>المساحة</Label>
                      <Input
                          type="text"
                          value={state.area ? state.area : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>
                        سعر الوحدة السكنية يوم الخميس
                      </Label>
                      <Input
                          type="text"
                          value={state.prices ? state.prices?.thursday : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>
                        سعر الوحدة السكنية يوم الجمعة
                      </Label>
                      <Input
                          type="text"
                          value={state.prices ? state.prices?.friday : ""}
                          disabled
                      />
                    </div>
                    <div>
                      <Label>
                        سعر الوحدة السكنية يوم السبت
                      </Label>
                      <Input
                          type="text"
                          value={state.prices ? state.prices?.saturday : ""}
                          disabled
                      />
                    </div>
                    <div>
                      <Label>
                        سعر الوحدة السكنية الأيام العادية
                      </Label>
                      <Input
                          type="text"
                          value={state.prices ? state.prices?.sunday : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>السعر لكل 6 ساعات </Label>
                      <Input
                          type="text"
                          value={state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "six")?.price ? state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "six")?.price : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>السعر لكل 12 ساعة</Label>
                      <Input
                          type="text"
                          value={state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "twelve")?.price ? state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "twelve")?.price : ""}
                          disabled
                      />
                    </div>


                    <div>
                      <Label>الوصف</Label>
                      <Textarea
                          value={state.description ? state.description : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>عدد الحمامات</Label>
                      <Input
                          type="text"
                          value={state.num_of_bathrooms ? state.num_of_bathrooms : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>عدد غرف النوم</Label>
                      <Input
                          type="text"
                          value={state.num_of_bedrooms ? state.num_of_bedrooms : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>عدد الأسرة الزوجية</Label>
                      <Input
                          type="text"
                          value={state.num_of_double_beds ? state.num_of_double_beds : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>عدد الأسرة الفردية</Label>
                      <Input
                          type="text"
                          value={state.num_of_single_beds ? state.num_of_single_beds : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>عدد الضيوف</Label>
                      <Input
                          type="text"
                          value={state.num_of_guests ? state.num_of_guests : ""}
                          disabled
                      />
                    </div>


                    <div>
                      <Label>أيام الإلغاء</Label>
                      <Input
                          type="text"
                          value={state.cancellation_days ? state.cancellation_days : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>وقت الوصول</Label>
                      <Input
                          type="text"
                          value={state.check_in_time ? state.check_in_time : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>وقت المغادرة</Label>
                      <Input
                          type="text"
                          value={state.check_out_time ? state.check_out_time : ""}
                          disabled
                      />
                    </div>


                    <div>
                      <Label>
                        وقت الدخول في حال التأجير كل ست ساعات

                      </Label>
                      <Input
                          type="text"
                          value={state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "six")?.check_in_time ? state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "six")?.check_in_time : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>
                        وقت الخروج في حال التأجير كل ست ساعات

                      </Label>
                      <Input
                          type="text"
                          value={state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "six")?.check_out_time ? state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "six")?.check_out_time : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>
                        وقت الدخول في حال التأجير كل 12 ساعة

                      </Label>
                      <Input
                          type="text"
                          value={state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "twelve")?.check_in_time ? state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "twelve")?.check_in_time : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>
                        وقت الخروج في حال التأجير كل 12 ساعة

                      </Label>
                      <Input
                          type="text"
                          value={state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "twelve")?.check_out_time ? state.hour_prices.find((hp: {
                            type: string;
                          }) => hp.type === "twelve")?.check_out_time : ""}
                          disabled
                      />
                    </div>


                    <div>
                      <Label>المدينة</Label>
                      <Input
                          type="text"
                          value={state.city ? state.city.title || "" : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>الدولة</Label>
                      <Input
                          type="text"
                          value={state.country ? state.country.title || "" : ""}
                          disabled
                      />
                    </div>
                    <div>
                      <Label>
                        المالك
                      </Label>
                      <Input
                          type="text"
                          value={state.owner ? state.owner.email : ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>
                        رقم الجوال
                      </Label>
                      <Input
                          type="text"
                          value={state.owner ? state.owner.phone : ""}
                          disabled
                      />
                    </div>


                    <div>
                      <Label>تأمين الاسترداد</Label>
                      <Input
                          type="text"
                          value={state.refundable_deposit || ""}
                          disabled
                      />
                    </div>

                    <div>
                      <Label>المرافق</Label>
                      <Input
                          type="text"
                          value={state.amenities ? state.amenities.map((a: any) => a.title).join(', ') : ""}
                          disabled
                      />
                    </div>
                  </div>
                </CardContent>
                <CardContent>

                  <div className="text-center text-xl font-bold mt-12">
                    <h1>الصور</h1>
                  </div>
                  <div className="flex items-center justify-center">
                    {state.images && state.images.length > 0 ? (
                        <Carousel activeIndex={currentSlide} onSelect={(index) => setCurrentSlide(index)}>
                          {state.images.map((image: any, idx: any) => (
                              <Carousel.Item key={idx}>
                                <img
                                    className="d-block w-100"
                                    src={image.storage_url}
                                    alt={`Slide ${idx}`}
                                />
                              </Carousel.Item>
                          ))}
                        </Carousel>
                    ) : (
                        <p>No images available</p>
                    )}
                  </div>


                </CardContent>
                <CardContent>
                  <div className="text-center text-xl font-bold mt-12">
                    <h1>
                      الموقع
                    </h1>
                  </div>
                  <div className="mt-12" style={{height: '100vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}

                    >
                      <MarkerComponent
                          lat={state.latitude || 0}
                          lng={state.longitude || 0}
                          text="My Marker"
                      />
                    </GoogleMapReact>
                  </div>
                </CardContent>
              </Card>
            </div>
          </Content>
        </>
      )}
    </>
  );
}
