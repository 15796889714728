import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./style.css"
import * as Sentry from "@sentry/react";

import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { persistor, store } from './redux/store';
import { Login } from './pages/Auth/Login';
import AdminDashboard from './pages/Admin/Core/dashboard';
import Pages from './pages/Admin/SystemSettings/Pages';
import Settings from './pages/Admin/SystemSettings/Settings';
import SystemSettings from './pages/Admin/SystemSettings/SystemSettings';
import PropertySettings from './pages/Admin/SystemSettings/PropertySettings';
import Property from './pages/Property/Property';
import Reviews from './pages/Admin/Core/Reviews';
import PropertyEdit from './pages/Property/PropertyEdit';
import CalendarPage from './pages/Admin/Core/Calendar';
import Reservation from './pages/Admin/Reservation/reservation';
import PropertyCreate from './pages/Property/PropertyCreate';
import Payouts from './pages/Admin/Payouts/Payouts';
import PropertyView from './pages/Property/PropertyView';
import ContactRequests from './pages/CustomerService/ContactRequests';
import Subscriptions from './pages/Marketing/Subscriptions';
import Users from './pages/Admin/Core/Users';
import CustomerServiceDashboard from './pages/CustomerService/CustomerServiceDashboard';
import FinanceDashboard from './pages/Finance/FinanceDashboard';
import MarktingDashboard from './pages/Marketing/MarktingDashboard';
import Notifications from './pages/Admin/Core/notifications';
import EditPage from "./pages/Admin/SystemSettings/EditPage";
import Home from "./pages/Home";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import DistanceUnits from "./pages/Admin/SystemSettings/DistanceUnits";
import Currency from "./pages/Admin/SystemSettings/Currency";
import AddNewPayout from "./pages/Admin/Payouts/AddNewPayout";
import ViewReservation from "./pages/Admin/Reservation/ViewReservation";
import Roles from "./pages/Admin/SystemSettings/Roles";
import AddNewRole from "./pages/Admin/SystemSettings/AddNewRole";
import EditRoles from "./pages/Admin/SystemSettings/EditRoles";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Finance from "./pages/Admin/Payouts/Finance";
import FinanceDetails from "./pages/Admin/Payouts/FinanceDetails";
import FinanceDetailsReservation from "./pages/Admin/Payouts/FinanceDetailsReservation";
import MyProfile from "./pages/Admin/Core/MyProfile";
import AddNewFacility from "./pages/Admin/SystemSettings/AddNewFacility";
import {AuthProvider} from "./helpers/AuthProvider";
import HostUsers from './pages/Admin/Core/HostUsers';
import Reservations from './pages/Admin/Reservation/Reservations';
import DiscountCodes from './pages/Marketing/DiscountCodes';
import Customers from "./pages/Admin/Core/Customers";
import Promotions from "./pages/Marketing/Promotions";
import CreatePromotion from "./pages/Marketing/CreatePromotion";
import EditPromotion from "./pages/Marketing/EditPromotion";
import CreateDiscountCode from "./pages/Marketing/CreateDiscountCode";
import EditDiscountCode from "./pages/Marketing/EditDiscountCode";
import Transactions from "./pages/Admin/Core/Transactions";
import HostProperties from "./pages/Admin/Core/HostProperties";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();

export const LOCALE_KEY = 'LOCALE_KEY';

const router = createBrowserRouter([

  {
    path: "/auth/admin/login",
    element: <Login />,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/admin/pages",
    element: <Pages />,
  },
  {
    path: "/admin/settings",
    element: <Settings />,
  },
  {
    path: "/admin/transactions",
    element: <Transactions />,
  },
  {
    path: "/admin/system/settings",
    element: <SystemSettings />,
  },
  {
    path: "/admin/system/properties",
    element: <PropertySettings />,
  },
  {
    path: "/admin/properties",
    element: <Property />,
  },
  {
    path: "/admin/property/edit",
    element: <PropertyEdit />,
  },
  {
    path: "/admin/property/create",
    element: <PropertyCreate />,
  },
  {
    path: "/admin/property/view/:id",
    element: <PropertyView />,
  },
  {
    path: "/admin/host/properties/:id",
    element: <HostProperties />,
  },
  {
    path: "/admin/reviews",
    element: <Reviews />,
  }, {
    path: "/admin/calendar",
    element: <CalendarPage />,
  },

  {
    path: "/admin/reservation/*",
    element: <Reservation />,
  },
  {
    path: "/admin/reservations",
    element: <Reservations />,
  },
  {
    path: "/admin/reservations/*",
    element: <Reservations />,
  },
  {
    path: "/admin/payouts",
    element: <Payouts />,
  },
  {
    path: "/contact/requests",
    element: <ContactRequests />,
  },
  {
    path: "/admin/marketing/subscriptions",
    element: <Subscriptions />,
  },
  {
    path: "/admin/marketing/discount-codes",
    element: <DiscountCodes />,
  },
  {
    path: "/admin/marketing/discount-codes/create",
    element: <CreateDiscountCode />,
  },
  {
    path: "/admin/marketing/discount-codes/edit/:id",
    element: <EditDiscountCode />,
  },


  {
    path: "/admin/marketing/promotions",
    element: <Promotions />,
  },
  {
    path: "/admin/marketing/promotion/create",
    element: <CreatePromotion />,
  },
  {
    path: "/admin/marketing/promotion/edit/:id",
    element: <EditPromotion />,
  },
  {
    path: "/admin/users",
    element: <Users />,
  },
  {
    path: "/admin/users/host",
    element: <HostUsers />,
  },
  {
    path: "/admin/users/customer",
    element: <Customers />,
  },
  {
    path:"/customer-service/dashboard",
    element: <CustomerServiceDashboard></CustomerServiceDashboard>
  },
  {
    path:"/finance/dashboard",
    element: <FinanceDashboard></FinanceDashboard>
  },
  {
    path:"/marketing/dashboard",
    element: <MarktingDashboard></MarktingDashboard>
  },
  {
    path: "/admin/notifications",
    element: <Notifications />,
  },
  {
    path: "/404",
    element: <h1>404</h1>,
  },
  {
    path:"/admin/EditPage",
    element:<EditPage></EditPage>
  },
  {
    path:"/",
    element:<Home></Home>
  },
  {
    path: "/admin/distance-units",
    element: <DistanceUnits />,
  },
  {
    path:"/admin/currencies",
    element:<Currency></Currency>
  },
  {
    path: "/admin/payouts/add",
    element: <AddNewPayout></AddNewPayout>,

  },
  {
    path:"/admin/reservation/view",
    element:<ViewReservation></ViewReservation>
  },
  {
    path:"/admin/users/roles",
    element:<Roles></Roles>
  },
  {
    path:"/admin/users/role/add",
    element:<AddNewRole></AddNewRole>
  },
  {
    path:"/admin/users/role/edit",
    element:<EditRoles></EditRoles>
  },
  {
    path:"/admin/finance",
    element:<Finance></Finance>
  },
  {
    path:"/admin/finance/finance-details",
    element:<FinanceDetails></FinanceDetails>
  },
  {
    path:"/admin/finance/reservations",
    element:<FinanceDetailsReservation></FinanceDetailsReservation>
  },
  {
    path:"/admin/my-profile",
    element:<MyProfile></MyProfile>
  },
  {
    path:"/admin/add-new-facility",
    element:<AddNewFacility></AddNewFacility>
  }






]);
Sentry.init({
  dsn: "https://7fdc9efafc0d79d41adeb023095826c9@o4507640803950592.ingest.us.sentry.io/4507641788170240",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
    <React.StrictMode>
      <Provider store={store}>

      <AuthProvider>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
          </PersistGate>

        </QueryClientProvider>
      </I18nextProvider>
      </AuthProvider>
      </Provider>
    </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
