import {Card, DatePicker, Layout, Select} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  CalendarAdd,
  CalendarRemove,
  Clock,
  CardReceive,
  TransactionMinus, Star, User, Personalcard, House, Eye, CardSlash, Buliding, UserOctagon, VideoTime,
} from "iconsax-react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";
import useGetAllCategories from "src/hooks/categories/list";
import {BookingType} from "../../../types/booking";
import {useGetAllUsers} from "../../../hooks/users";
import {cn} from "../../../lib/utils";
import {Popover, PopoverContent, PopoverTrigger} from "../../../components/ui/popover";
import {CalendarIcon} from "lucide-react";
import {addDays, format} from "date-fns";
import {Calendar} from "../../../components/ui/calendar";
import {DateRange} from "react-day-picker";
import {Input} from "../../../components/ui/input";

const { Content } = Layout;

export default function Finance() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const [data, setData] = useState([]);
  const currentDate = new Date();

  const [selected, setSelected] = useState<any>("Hosting");
  const state = useLocation().state
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: addDays(new Date(2024, 0, 20), 10),
    to: addDays(new Date(2024, 0, 20), 20),
  })
  const [selectedDateFromFilter, setSelectedDateFromFilter]:any = useState(null);
  const [selectedDateToFilter, setSelectedDateToFilter]:any = useState(null);
  const [remainingTo, setRemainingTo]:any = useState(0);
  const [remainingFrom, setRemainingFrom]:any = useState(0);

  console.log(state)
  useEffect(() => {
    window.document.title = "الماليه";
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}finance?type=${selected}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        })
        const data = await response.json();
        const users = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users?status=${selected}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        });
        const usersData = await users.json();
        setUsers(usersData.users)
        console.log(usersData)


        console.log(data)
        if (response.status == 200) {
          setLoading(false)
          setData(data.providers)
        }
      } catch (error) {
        dispatch(setIsAuth(false))
        dispatch(setUserToken(''))
        navigate('/auth/admin/login')
      }
    }
    fetchData()
  }, [selected])
  function exportToExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "Finance" + '.xlsx');
  }


  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction: "rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                        <TransactionMinus size="48" color="#2dacf4" variant="Bulk"/>

                      </div>
                      <h1 className="text-xl">الماليه (
                        {selected === "Hosting" ? "اصحاب الوحدات السكنية" : "العملاء"}
                        )</h1>

                    </div>
                    <div className="flex items-center gap-4">
                      <Button onClick={() => {
                        navigate("/admin/payouts/add", {state: selected ? "HOSTING" : "CUSTOMER"})

                      }}>
                        اضافة مدفوعات جديدة
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>

                          <Icon className="cursor-pointer" width={24} icon="eva:more-vertical-fill"/>


                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                          <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                          <DropdownMenuSeparator/>
                          <DropdownMenuGroup>
                            <DropdownMenuItem onClick={() =>
                                setSelected("Hosting")
                            } className="gap-2">
                              <Buliding size="32" color="#2dacf4" variant="Bulk"/>

                              <span>
                              اصحاب الوحدات السكنية
                            </span>
                            </DropdownMenuItem>

                            <DropdownMenuItem onClick={() =>
                                setSelected("Customer")
                            } className="gap-2">
                              <UserOctagon size="32" color="#2dacf4" variant="Bulk"/>

                              <span>
                              العملاء
                            </span>
                            </DropdownMenuItem>

                            <DropdownMenuItem onClick={() => {
                              exportToExcel()
                            }} className="gap-2">
                              <Icon width={32} height={32} icon="vscode-icons:file-type-excel"/>

                              <span>
                              تصدير الى أكسل
                            </span>
                            </DropdownMenuItem>


                          </DropdownMenuGroup>

                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>


                  </div>
                </CardHeader>
              </Card>
            </div>
            <div

                className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 gap-4 mx-6 mt-6">

            <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                   حسب التاريخ
                </h1>
                <div className={cn("grid gap-2")}>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                          id="date"
                          variant={"outline"}
                          className={cn(
                              "w-[300px] justify-start text-left font-normal",
                              !date && "text-muted-foreground"
                          )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4"/>
                        {date?.from ? (
                            date.to ? (
                                <>
                                  {format(date.from, "LLL dd, y")} -{" "}
                                  {format(date.to, "LLL dd, y")}
                                </>
                            ) : (
                                format(date.from, "LLL dd, y")
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                          initialFocus
                          mode="range"
                          defaultMonth={
                            date?.from ? new Date(date.from) : new Date()
                          }
                          selected={date}
                          onSelect={(newDate: any) => {
                            setDate(newDate)
                            console.log(newDate)
                            if (newDate?.from && newDate?.to) {
                              setSelectedDateFromFilter(format(newDate?.from, "y-MM-dd"))
                              setSelectedDateToFilter(format(newDate?.to, "y-MM-dd"))
                              console.log(selectedDateFromFilter, selectedDateToFilter)
                            }
                            // selectedDateFromFilter(format(newDate.from, "LLL dd, y"))
                            // selectedDateToFilter(format(newDate.to, "LLL dd, y"))


                          }
                          }
                          numberOfMonths={2}
                      />
                    </PopoverContent>
                  </Popover>
                </div>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                   حسب العميل
                </h1>
                <Select
                    placeholder="العميل"
                    className="w-full"
                    allowClear
                    showSearch
                    onChange={(value: any) => {
                      console.log(value)
                      setSelectedUser(value)
                    }
                    }
                >
                  {users.map((user: any) => (
                      <Select.Option key={user.phone} value={user.phone}>
                        {user.phone}
                      </Select.Option>
                  ))}
                </Select>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  حسب المبلغ المتبقي من
                </h1>
                <Input
                    type="number"
                    placeholder="من"
                    onChange={(e) => {
                      setRemainingFrom(e.target.value)
                    }}
                />


              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                    حسب المبلغ المتبقي الى
                </h1>
                <Input
                    type="number"
                    placeholder="الى"
                    onChange={(e) => {
                        setRemainingTo(e.target.value)
                    }}
                />


              </div>

            </div>
            <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
              {data
                  .filter((item: any) => {
                        if (selectedUser) {
                          return item.phone === selectedUser
                        }
                        return item
                      }
                  )
                  .filter((item: any) => {
                    if (selectedDateFromFilter && selectedDateToFilter) {
                          return item.created_at.split('T')[0] >= selectedDateFromFilter && item.created_at.split('T')[0] <= selectedDateToFilter
                        }
                        return item
                      }
                  )
                    .filter((item: any) => {
                        if (remainingFrom && remainingTo) {
                        return item.sum_of_reservations_total - item.sum_of_payouts >= remainingFrom && item.sum_of_reservations_total - item.sum_of_payouts <= remainingTo
                        }
                        return item
                    })
                    .filter((item: any) => {
                        if (remainingFrom) {
                            return item.sum_of_reservations_total - item.sum_of_payouts >= remainingFrom
                        }
                        return item
                    }
                    )
                    .filter((item: any) => {
                        if (remainingTo) {
                            return item.sum_of_reservations_total - item.sum_of_payouts <= remainingTo
                        }
                        return item
                    }
                    )
                    .filter((item: any) => {
                        if (selectedDateFromFilter) {
                            return item.created_at.split('T')[0] >= selectedDateFromFilter
                        }
                        return item
                    }
                    )
                    .filter((item: any) => {
                        if (selectedDateToFilter) {
                            return item.created_at.split('T')[0] <= selectedDateToFilter
                        }
                        return item
                    }
                    )


                  .map((item: any) => (

                      <Card className="w-full">
                        <div className="flex items-center my-1 justify-between">
                          <div className="flex items-center">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                {selected === "Hosting" ? <Buliding size="48" color="#333" variant="Bulk"/> :
                                    <UserOctagon size="48" color="#333" variant="Bulk"/>}
                              </div>
                            </div>

                            <h1 className={"text-xl mr-2"}>{item.name}</h1>


                          </div>

                          <div className="flex items-center gap-4">
                            <h1 className={"text-xl"}>
                              {item.sum_of_reservations_total.toFixed(2)} ر.س
                            </h1>
                            <Button onClick={() => {
                              navigate("/admin/finance/finance-details", {state: item.id})

                            }}>
                              <Eye
                                  color="white"
                                  variant="Bulk"
                                  className="ml-2 h-8 w-8"
                              />
                              عرض المعلومات الماليه

                            </Button>
                          </div>

                        </div>
                        <div className="items-center justify-between grid  gap-4 mt-6 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-5  ">
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <House size="24" color="#28c76f" variant="Bulk"/>
                            </div>
                            <h4>
                              عدد الشقق
                            </h4>
                            <h4>
                              {item.num_of_properties}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <TransactionMinus size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              عدد الحجوزات
                            </h4>
                            <h4>
                              {item.num_of_reservations}
                            </h4>
                          </div>

                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>

                              الأجمالي
                            </h4>
                            <h4>
                              {item.sum_of_reservations_total} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#7A5AF8" variant="Bulk"/>
                            </div>
                            <h4>
                              المجموع الفرعي
                            </h4>
                            <h4>
                              {item.sum_of_reservations_sub_total} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع الضريبة
                            </h4>
                            <h4>
                              {item.sum_of_reservations_tax.toFixed(2)} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع الخدمة
                            </h4>
                            <h4>
                              {item.sum_of_reservations_service} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع الدفعات
                            </h4>
                            <h4>
                              {item.sum_of_payouts} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع المتبقي
                            </h4>
                            <h4>
                              {item.sum_of_payouts} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardSlash size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع المتبقي
                            </h4>
                            <h4>
                              {
                                  item.sum_of_reservations_total &&
                                  item.sum_of_payouts &&
                                  (item.sum_of_reservations_total - item.sum_of_payouts).toFixed(1)
                              } ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <VideoTime size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                            تاريخ العملية
                            </h4>
                            <h4>
                                {item.created_at.split('T')[0]}
                            </h4>
                          </div>


                        </div>
                      </Card>
                  ))
              }

            </div>


          </Content>
        </>
      )}
    </>
  );
}
