import {
  CheckCircleOutlined,
  DollarOutlined,
  HomeOutlined,
  PhoneOutlined,
  PictureOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function HowItWork() {
  const { t: translate, i18n } = useTranslation();

  return (
    <div id="how-it-work" className="m-home mx-5 pb-10 lg:mx-60 lg:pb-36">
      <div className="grid grid-cols-12">
        <div data-aos="fade-up-right" className="col-span-12 lg:col-span-9">
          <h2
            className={`mx-0 text-center font-semibold text-[#545B67] lg:mx-2 ${
              i18n.language === 'ar' ? 'lg:text-right' : 'lg:text-left'
            }`}>
            {translate('common:home.easy-fast')}
          </h2>
          <p
            className={`mt-4 text-center text-xl font-extrabold capitalize leading-6 ${
              i18n.language === 'ar' ? 'lg:text-right' : 'lg:text-left'
            } lg:text-5xl lg:leading-[60px]`}>
            {translate('common:home.easy-fast-desc1')}
          </p>
          <div className="mx-5 mt-12 lg:mx-0">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="">
                {/*  */}
                <div className="mt-10 flex items-center justify-start">
                  <div className="h-12 w-12 rounded-xl bg-[#F0BB1F] text-center leading-[3.25rem] text-white">
                    <HomeOutlined className="text-xl" />
                  </div>
                  <div className="mx-5">
                    <h2 className="font-bold">{translate('common:home.how1-head')}</h2>
                    <p className="w-full text-sm text-[#545B67] lg:w-4/6">{translate('common:home.how1-desc')}</p>
                  </div>
                </div>
                {/*  */}
                <div className="mt-10 flex items-center justify-start">
                  <div className="h-12 w-12 rounded-xl bg-[#F15A2B] text-center leading-[3.25rem] text-white">
                    <CheckCircleOutlined className="text-xl" />
                  </div>
                  <div className="mx-5">
                    <h2 className="font-bold">{translate('common:home.how2-head')}</h2>
                    <p className="w-full text-sm text-[#545B67] lg:w-4/6">{translate('common:home.how2-desc')}</p>
                  </div>
                </div>
                {/*  */}
                <div className="mt-10 flex items-center justify-start">
                  <div className="h-12 w-12 rounded-xl bg-[#006380] text-center leading-[3.25rem] text-white">
                    <DollarOutlined className="text-xl" />
                  </div>
                  <div className="mx-5">
                    <h2 className="font-bold">{translate('common:home.how3-head')}</h2>
                    <p className="w-full text-sm text-[#545B67] lg:w-4/6">{translate('common:home.how3-desc')}</p>
                  </div>
                </div>
              </div>
              <div>
                {/*  */}
                <div className="mt-10 flex items-center justify-start">
                  <div className="h-12 w-12 rounded-xl bg-[#F0BB1F] text-center leading-[3.25rem] text-white">
                    <PhoneOutlined className="text-xl" />
                  </div>
                  <div className="mx-5">
                    <h2 className="font-bold">{translate('common:home.how4-head')}</h2>
                    <p className="w-full text-sm text-[#545B67] lg:w-4/6">{translate('common:home.how4-desc')}</p>
                  </div>
                </div>
                {/*  */}
                <div className="mt-10 flex items-center justify-start">
                  <div className="h-12 w-12 rounded-xl bg-[#F15A2B] text-center leading-[3.25rem] text-white">
                    <SearchOutlined className="text-xl" />
                  </div>
                  <div className="mx-5">
                    <h2 className="font-bold">{translate('common:home.how5-head')}</h2>
                    <p className="w-full text-sm text-[#545B67] lg:w-4/6">{translate('common:home.how5-desc')}</p>
                  </div>
                </div>
                {/*  */}
                <div className="mt-10 flex items-center justify-start">
                  <div className="h-12 w-12 rounded-xl bg-[#006380] text-center leading-[3.25rem] text-white">
                    <PictureOutlined className="text-xl" />
                  </div>
                  <div className="mx-5">
                    <h2 className="font-bold">{translate('common:home.how6-head')}</h2>
                    <p className="w-full text-sm text-[#545B67] lg:w-4/6">{translate('common:home.how6-desc')}</p>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up-left" className="col-span-12 lg:col-span-3">
          <div className="relative mt-10">
            <img className="w-full shadow-xl" src="/assets/imgs/Splash.png" alt="property" />
            {/* <div className="relative -right-[2px] bottom-40 lg:absolute lg:-bottom-[50px] lg:-right-[75px]">
              <img className="w-full" src="/assets/imgs/client.svg" alt="client" />
            </div> */}
            <div className="property-shadow absolute right-20 top-14" />
          </div>
        </div>
      </div>
    </div>
  );
}
