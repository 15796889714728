import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {CardContent, CardFooter, CardHeader, CardTitle} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Icon } from "@iconify/react";

import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  BookSquare,
  Copyright,
  Edit,
  Eye,
  Flag,
  Messages1,
  Notification1, Personalcard, Receipt,
  SecuritySafe, Star, TransactionMinus,
  Trash,
  User, UserOctagon
} from "iconsax-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTrigger } from "src/components/ui/alert-dialog";
import { Editor } from "@tinymce/tinymce-react";
import { getByPageName, getFetcher, updateByPageName } from "src/lib/API";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import {setIsAuth, setUserToken} from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";

const { Content } = Layout;

export default function Notifications() {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate();
  const [is_open, setIs_open] = React.useState(false)

  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const [currentFilter, setCurrentFilter] = useState("reservation")


  const [notifications, setNotifications]: any = React.useState([])
  useEffect(() => {
    window.document.title = "الاشعارات"
    async function fetchData() {
      try {
        const request = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}notifications`)

        console.log(request.data.data)
        setNotifications(request.data.data)
        setLoading(false)
      }
        catch (error) {
            console.error(error)
          dispatch(setIsAuth(false))
          dispatch(setUserToken(''))
          navigate('/auth/admin/login')
        }


    }
    fetchData()
  }, [])







  return (
    <>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>

      ) : (
        <>
          <div><Toaster /></div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-row items-center">
                      <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                        <Notification1 size="48" color="#2dacf4" variant="Bulk"/>
                      </div>

                      <h1 className="text-xl mr-2">الاشعارات</h1>
                    </div>

                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Icon
                            width={24}
                            height={24}
                            className="cursor-pointer"
                            icon="pepicons-pop:dots-y"
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                        <DropdownMenuSeparator/>
                        <DropdownMenuGroup>
                          <DropdownMenuItem onClick={() => setCurrentFilter("reservation")}>
                            <Receipt
                                color="#475467"
                                variant="Bulk"
                                className="ml-2 h-8 w-8"
                            />
                            <span>الحجوزات</span>
                          </DropdownMenuItem>
                          {/*<DropdownMenuItem onClick={() => setCurrentFilter("CUSTOMER")}>*/}
                          {/*  <UserOctagon*/}
                          {/*      color="#475467"*/}
                          {/*      variant="Bulk"*/}
                          {/*      className="ml-2 h-8 w-8"*/}
                          {/*  />*/}
                          {/*  <span>العملاء</span>*/}
                          {/*</DropdownMenuItem>*/}


                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>

                  </div>
                </CardHeader>
              </Card>
            </div>

            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mx-6 mt-6">


              {notifications?.map((item: any) => (
                  <Card>
                    <CardHeader>


                      <div className="flex items-center justify-between flex-row">
                        <div className="flex items-center">
                          <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                            <Personalcard variant="Bulk"
                                          size="32" color="#475467"/>
                          </div>
                          <h1>
                            {item.title}
                          </h1>


                        </div>

                        {/*<h1 className={`text-black mr-2 ${item.type ? "success-text" : "error-text"}`}>( {item.type ? "مفعل" : "غير مفعل"} )</h1>*/}

                      </div>

                    </CardHeader>
                    <CardContent>

                      <div className="flex items-center mr-2">
                        {item.description}
                      </div>
                    </CardContent>

                  </Card>
              ))}

            </div>


          </Content>
        </>
      )}


    </>
  )
}