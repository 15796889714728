
import {Card, Checkbox, Input, Layout, Table} from "antd";
import {ArrowLeft2, ArrowRight2, Data2, House, MoneyTime, TransactionMinus} from "iconsax-react";
import AdminLayout from "src/Layouts/Main";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader } from "src/components/ui/alert-dialog";
import { CardContent, CardHeader } from "src/components/ui/card";
import toast, { Toaster } from "react-hot-toast";
import { HashLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setIsAuth, setUserToken } from "src/redux/slices/user_slice";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Button} from "../../../components/ui/button";
import {Icon} from "@iconify/react";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {ColumnsType} from "antd/es/table";
import {SortOrder} from "antd/es/table/interface";


const { Content } = Layout;
interface Transaction {
    id: number;
    amount: number;
    currency: string;
    status: string;
    checkout_id: string;
    trackable_data: object;

}


export default function Transactions() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)



    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}transactions?page=${page}&limit=${pageSize}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.data.items)
                setPage(data.data.pagination.currentPage)
                setPageSize(data.data.pagination.perPage)
                setTotal(data.data.pagination.total)
            } else {

            }
        } catch (error) {
        }

    }


    useEffect(() => {
        window.document.title = "العمليات"
        fetchData()

    }, [page, pageSize])
    const columns: ColumnsType<Transaction> = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => {
                const valueA = a.id ?? Infinity;
                const valueB = b.id ?? Infinity;
                return valueA - valueB;
            },
            defaultSortOrder: 'ascend',
        },
        {
            title: "المبلغ",
            dataIndex: "amount",
            key: "amount",

        },
        {
          title: "العملة",
            dataIndex: "currency",
            key: "currency",
        },
        {
          title: "الحالة",
            dataIndex: "status",
            key: "status",

        },
        {
            title: "ايميل العميل",
            dataIndex: "trackable_data",
            key: "trackable_data",
            render: (trackable_data: { CustomerEmail: string }) => trackable_data.CustomerEmail,
        },
        // Existing columns...
        {
            title: "البريد الإلكتروني للعميل",
            dataIndex: "trackable_data",
            key: "CustomerEmail",
            render: (trackable_data: any) => trackable_data.CustomerEmail || 'غير متوفر',
        },
        {
            title: "جوال العميل",
            dataIndex: "trackable_data",
            key: "CustomerMobile",
            render: (trackable_data: any) => trackable_data.CustomerMobile || 'غير متوفر',
        },
        {
            title: "اسم العميل",
            dataIndex: "trackable_data",
            key: "CustomerName",
            render: (trackable_data: any) => trackable_data.CustomerName || 'غير متوفر',
        },
        {
            title: "مرجع العميل",
            dataIndex: "trackable_data",
            key: "CustomerReference",
            render: (trackable_data: any) => trackable_data.CustomerReference || 'غير متوفر',
        },
        {
            title: "عملة العرض",
            dataIndex: "trackable_data",
            key: "DisplayCurrencyIso",
            render: (trackable_data: any) => trackable_data.DisplayCurrencyIso || 'غير متوفر',
        },
        {
            title: "قيمة الفاتورة",
            dataIndex: "trackable_data",
            key: "InvoiceValue",
            render: (trackable_data: any) => trackable_data.InvoiceValue || 'غير متوفر',
        },
        {
            title: "اللغة",
            dataIndex: "trackable_data",
            key: "Language",
            render: (trackable_data: any) => trackable_data.Language || 'غير متوفر',
        },
        {
            title: "رمز دولة الجوال",
            dataIndex: "trackable_data",
            key: "MobileCountryCode",
            render: (trackable_data: any) => trackable_data.MobileCountryCode || 'غير متوفر',
        },
        {
            title: "المبلغ",
            dataIndex: "trackable_data",
            key: "amount",
            render: (trackable_data: any) => trackable_data.amount || 'غير متوفر',
        },
        // For nested properties, use a render function to safely access them
        {
            title: "مدينة الفوترة",
            dataIndex: "trackable_data",
            key: "billingCity",
            render: (trackable_data: any) => trackable_data.billing?.city || 'غير متوفر',
        },
        {
            title: "دولة الفوترة",
            dataIndex: "trackable_data",
            key: "billingCountry",
            render: (trackable_data: any) => trackable_data.billing?.country || 'غير متوفر',
        },
        {
            title: "الرمز البريدي للفوترة",
            dataIndex: "trackable_data",
            key: "billingPostcode",
            render: (trackable_data: any) => trackable_data.billing?.postcode || 'غير متوفر',
        },
        {
            title: "شارع الفوترة",
            dataIndex: "trackable_data",
            key: "billingStreet1",
            render: (trackable_data: any) => trackable_data.billing?.street1 || 'غير متوفر',
        },
        {
            title: "رقم العملية",
            dataIndex: "checkout_id",
            key: "checkout_id",
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger>الإجراءات</DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>الإجراءات</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>
                                تحت التطوير
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            ),
        },
    ];

    const itemRender = (_: any, type: string, originalElement: any) => {
        if (type === "prev") {
            return <ArrowRight2 size="32" color="#333" />;
        }
        if (type === "next") {
            return <ArrowLeft2 size="32" color="#333" />;
        }
        return originalElement;
    };
    const exportToExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, "host_users.xlsx");
    };
    return (
        <>

        {loading ? (
            <div className="flex justify-center items-center h-screen">
                <HashLoader size={64} color="#36d7b7" />
            </div>

        ) : (
            <>
                <div><Toaster /></div>

                <AdminLayout></AdminLayout>
                <Content style={{direction:"rtl"}}>
                    <div className="mx-6 mt-6">
                        <Card>
                            <CardHeader>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                        <MoneyTime
                                                            color="#475467"
                                                            variant="Bulk"
                                                           size={32}
                                                        />

                                        </div>
                                        <div>
                                            <h1 className="text-xl">
                                                العمليات
                                            </h1>
                                        </div>



                                    </div>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button>
                                                تصدير
                                            </Button>

                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="w-56">
                                            <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                            <DropdownMenuSeparator />
                                            <DropdownMenuGroup>
                                                <DropdownMenuItem onClick={() => exportToExcel(data)} className="gap-2">
                                                    <Icon width={32} icon="vscode-icons:file-type-excel" />
                                                    <span>تصدير الى Excel</span>
                                                </DropdownMenuItem>
                                                {/*<DropdownMenuItem>*/}
                                                {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                                                {/*  <span>تصدير الى pdf</span>*/}
                                                {/*</DropdownMenuItem>*/}




                                            </DropdownMenuGroup>

                                        </DropdownMenuContent>
                                    </DropdownMenu>

                                </div>

                            </CardHeader>
                        </Card>
                    </div>
                    <div className="mx-6 mt-6">
                        <Input
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder="
                                ابحث برقم العملية
                            "
                            />
                    </div>
                    <div className="mx-6 mt-6">

                    <Table dataSource={
                        data.filter((item: any) => {
                            return item.id.toString().includes(searchText)
                        })
                    }
                           rowKey="id"
                           showHeader={true}
                           bordered={true}
                           scroll={{ x: 'max-content' }}
                           locale={{
                                 emptyText: (
                                      <div className="flex flex-col items-center justify-center">
                                        <Data2 size={64} color="#6B7280" />
                                        <span className="mt-2 text-xl font-bold text-gray-600">
                                             لا توجد بيانات
                                        </span>
                                      </div>
                                 ),

                           }}
                           virtual={true}
                           pagination={{
                               showSizeChanger: true,
                               showQuickJumper: true,
                               showTotal: (total) => `Total ${total} items`,
                               total: total,
                               current: page,
                               position: ["bottomCenter"],
                               size: "default",
                               itemRender: itemRender

                           }}
                           onChange={(pagination, filters, sorter, extra) => {
                               console.log('params', pagination, filters, sorter, extra);
                               setPage(pagination.current ?? 1)
                               setPageSize(pagination.pageSize ?? 10)
                           }}
                           sortDirections={["ascend", "descend"]}
                           showSorterTooltip={true}
                           loading={loading}
 columns={columns} />;


                    </div>
                    
                </Content>
            </>
        )}




    </>
    )
}