import {Card, DatePicker, Input, Layout, Select} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  CalendarAdd,
  CalendarRemove,
  Clock,
  CardReceive,
  TransactionMinus, Star, User, Personalcard, House, Eye, Buliding, InfoCircle,
} from "iconsax-react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";
import {DateRange} from "react-day-picker";
import {addDays, format} from "date-fns";
import {Popover, PopoverContent, PopoverTrigger} from "../../../components/ui/popover";
import {Calendar} from "../../../components/ui/calendar";
import {CalendarIcon} from "lucide-react";
import {cn} from "../../../lib/utils";
import {SelectShadncn} from "../../../components/ui/select";
import {getFetcher} from "../../../API";

const { Content } = Layout;

export default function Reservation() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const [data, setData] = useState([]);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState('all');
  const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
const [rentTypeFilter, setRentTypeFilter] = useState('all');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: addDays(new Date(2024, 0, 20), 10),
    to: addDays(new Date(2024, 0, 20), 20),
  })
  const [selectedDateFromFilter, setSelectedDateFromFilter]:any = useState(null);
    const [selectedDateToFilter, setSelectedDateToFilter]:any = useState(null);
  const state = useLocation().state
  console.log(state)
  useEffect(() => {
    window.document.title = "الحجوزات";
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}reservations?status=${state}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        })
        const request = await getFetcher(token,"categories",true)
        const requestUsers = await getFetcher(token,"users",true)
        const data = await response.json();
        if (state == "active") {
            window.document.title = "الحجوزات الحالية";
          if (response.status == 200) {
            setCategories(request.categories)
            setData(data.data.items)
            setUsers(requestUsers.data.items)
            setLoading(false)

          }

        }
        else {
          const pastReservationsData = data.data.items
          console.log(pastReservationsData)
          setData(pastReservationsData)


          window.document.title = "الحجوزات السابقة";
          setCategories(request.categories)
          setUsers(requestUsers.data.items)
          setLoading(false)
        }

      } catch (error) {
        console.log(error)
        dispatch(setIsAuth(false))
        dispatch(setUserToken(''))
        navigate('/auth/admin/login')
      }
    }
    fetchData()
  }, [state])


    const serializeIds = (data: any[]) => {
        return data.map((item) => {
        return {
            "اسم العميل": item.username,
            "الوحدة السكنية": item.property.title,
            "نوع الوحدة السكنية": item.property.category.title,
            "بداية الحجز": item.reserved_from,
            "نهاية الحجز": item.reserved_to,
            "نوع الحجز": item.type === "daily" ? "يومي" : "بالساعة",
            "طريقة الدفع": item.payment_method,
            "رسوم الخدمة": item.service,
            "الضريبة": item.tax,
            "الاجمالي": item.total,
            "حالة الدفع": item.payment_status === "paid" ? "مدفوعة" : "غير مدفوعة",
        };
        });
    };

  const exportToExcel = (data: any[]) => {
    const serializedData = serializeIds(data); // Serialize IDs before exporting
    const worksheet = XLSX.utils.json_to_sheet(serializedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "reservations.xlsx");
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                        <TransactionMinus size="48" color="#2dacf4" variant="Bulk" />

                      </div>
                      <h1 className="text-xl">الحجوزات ( {state == "active" ? "الحالية" :"السابقة"} )</h1>

                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button>
                          تصدير
                        </Button>

                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                          <DropdownMenuItem onClick={() => exportToExcel(data)} className="gap-2">
                            <Icon width={32} icon="vscode-icons:file-type-excel" />
                            <span>تصدير الى Excel</span>
                          </DropdownMenuItem>
                          {/*<DropdownMenuItem>*/}
                          {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                          {/*  <span>تصدير الى pdf</span>*/}
                          {/*</DropdownMenuItem>*/}




                        </DropdownMenuGroup>

                      </DropdownMenuContent>
                    </DropdownMenu>


                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="grid grid-cols-3 gap-4 mx-6 mt-6">
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب التاريخ
                </h1>
                <div className={cn("grid gap-2")}>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                          id="date"
                          variant={"outline"}
                          className={cn(
                              "w-[300px] justify-start text-left font-normal",
                              !date && "text-muted-foreground"
                          )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4"/>
                        {date?.from ? (
                            date.to ? (
                                <>
                                  {format(date.from, "LLL dd, y")} -{" "}
                                  {format(date.to, "LLL dd, y")}
                                </>
                            ) : (
                                format(date.from, "LLL dd, y")
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                          initialFocus
                          mode="range"
                          defaultMonth={
                            date?.from ? new Date(date.from) : new Date()
                          }
                          selected={date}
                          onSelect={(newDate: any) => {
                            setDate(newDate)
                            console.log(newDate)
                            if (newDate?.from && newDate?.to) {
                              setSelectedDateFromFilter(format(newDate?.from, "y-MM-dd"))
                              setSelectedDateToFilter(format(newDate?.to, "y-MM-dd"))
                              console.log(selectedDateFromFilter, selectedDateToFilter)
                            }
                            // selectedDateFromFilter(format(newDate.from, "LLL dd, y"))
                            // selectedDateToFilter(format(newDate.to, "LLL dd, y"))


                          }
                          }
                          numberOfMonths={2}
                      />
                    </PopoverContent>
                  </Popover>
                </div>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب نوع الوحدة السكنية
                </h1>
                <Select
                    placeholder="نوع الوحدة السكنية"
                    className="w-full"
                    allowClear
                    showSearch
                    onChange={(value: any) => {
                      setSelectedCategory(value)
                    }
                    }

                >
                  {categories.map((category: any) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.title}
                      </Select.Option>
                  ))}
                </Select>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب طريقة الدفع
                </h1>
                <Select
                    placeholder="نوع الوحدة السكنية"
                    className="w-full"
                    allowClear
                    showSearch
                    onChange={(value: any) => {
                      setPaymentMethodFilter(value)
                    }
                    }

                >
                  <Select.Option value="all">
                    الكل
                  </Select.Option>
                  <Select.Option value="paid">
                    مدفوعة
                  </Select.Option>
                  <Select.Option value="unpaid">
                    غير مدفوعة
                  </Select.Option>

                </Select>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب نوع الحجز
                </h1>
                <Select
                    placeholder="نوع الحجز"
                    className="w-full"
                    allowClear
                    showSearch
                    onChange={(value: any) => {
                      setRentTypeFilter(value)
                    }
                    }

                >
                  <Select.Option value="all">
                    الكل
                  </Select.Option>
                  <Select.Option value="daily">
                    يومي
                  </Select.Option>
                  <Select.Option value="hourly">
                    بالساعة
                  </Select.Option>
                </Select>


              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب العميل
                </h1>
                <Select
                    placeholder="العميل"
                    className="w-full"
                    allowClear
                    showSearch
                    virtual={users.length > 100}

                    onChange={(value: any) => {
                      console.log(value)
                      setSelectedUser(value)
                    }
                    }
                >
                  {users.map((user: any) => (
                      <Select.Option key={user.phone} value={user.phone}>
                        {user.phone}
                      </Select.Option>
                  ))}
                </Select>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                 بحث حسب رقم الحجز
                </h1>
                <Input
                    placeholder="رقم الحجز"
                    className="w-full"
                    type="number"

                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}

                >

                </Input>
              </div>

            </div>
            <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
              {data
                  .filter((item: any) => {
                        if (paymentMethodFilter === "all") {
                          return item;
                        } else if (paymentMethodFilter === "paid") {
                          return item.payment_status === "paid";
                        } else {
                          return item.payment_status === "unpaid";
                        }
                      }
                  )
                  .filter((item: any) => {
                    if (selectedDateFromFilter && selectedDateToFilter) {
                      const reservedFromDate = new Date(item.reserved_from);
                      const reservedToDate = new Date(item.reserved_to);

                      const selectedFromDate = new Date(selectedDateFromFilter);
                      const selectedToDate = new Date(selectedDateToFilter);

                      return reservedFromDate >= selectedFromDate && reservedToDate <= selectedToDate;
                    }
                    return true;
                  })
                    .filter((item: any) => {
                        if (selectedCategory) {
                        return item.property.category.id === selectedCategory;
                        }
                        return true;
                    })
                    .filter((item: any) => {
                        if (rentTypeFilter === "all") {
                            return item;
                        } else if (rentTypeFilter === "daily") {
                            return item.type === "daily";
                        } else {
                            return item.type === "hourly";
                        }
                    }
                    )
                    .filter((item: any) => {
                        if (selectedUser) {

                            return item.user?.phone === selectedUser;
                        }
                        return true;
                    })
                    .filter((item: any) => {
                        if (searchText) {
                            return item.id.toString().includes(searchText);
                        }
                        return true;
                    })
                  .map((item: any) => (

                      <Card className="w-full">
                        <div className="flex items-center my-1 justify-between">
                          <div className="flex items-center">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                <User size="48" color="#333" variant="Bulk"/>
                              </div>
                            </div>

                            <h1 className={"text-xl mr-2"}>
                              {item.username}
                            </h1>

                            <h1 className={item.payment_status === "paid" ? `text-black mr-2 success-text` : `text-black mr-2 error-text`}>( {item.payment_status === "paid" ? "مدفوعة" : "غير مدفوعة"} )</h1>


                          </div>

                          <div className="flex items-center gap-4">
                            <h1 className={"text-xl"}>
                              {item.total} ر.س
                            </h1>
                            <Button onClick={() => {
                              navigate("/admin/reservation/view", {state: item})

                            }}>
                            <Eye
                                  color="white"
                                  variant="Bulk"
                                  className="ml-2 h-8 w-8"
                          />
                          عرض الحجز

                        </Button>
                      </div>

                    </div>
                        <div className=" items-center justify-between grid-cols-5 grid gap-2 mt-6  ">
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <Buliding size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              اسم الوحدة السكنية
                            </h4>
                            <h4>
                              {item.property.title}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <Buliding size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                                نوع الوحدة السكنية
                            </h4>
                            <h4>
                              {item.property.category.title}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CalendarAdd size="24" color="#28c76f" variant="Bulk"/>
                            </div>
                            <h4>
                              بدايه الحجز
                            </h4>
                            <h4>
                              {item.reserved_from}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CalendarRemove size="24" color="#F04438" variant="Bulk"/>
                            </div>
                            <h4>
                              نهايه الحجز
                            </h4>
                            <h4>
                              {item.reserved_to}
                            </h4>
                          </div>

                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <Clock size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              نوع الحجز
                            </h4>
                            <h4>
                              {item.type === "daily" ? "يومي" : "بالساعة"}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#7A5AF8" variant="Bulk"/>
                            </div>
                            <h4>
                              طريقة الدفع
                            </h4>
                            <h4>
                              {item.payment_method}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              رسوم الخدمة
                            </h4>
                            <h4>
                              {item.service} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              الضريبة
                            </h4>
                            <h4>
                              {item.tax} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#F4F7FD"}}
                                className={" p-2 rounded-md"}
                            >
                              <InfoCircle size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              رقم الحجز
                            </h4>
                            <h4>
                                {item.id}
                            </h4>
                          </div>


                        </div>
                      </Card>
                  ))
              }

            </div>


          </Content>
        </>
      )}
    </>
  );
}
