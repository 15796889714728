import {
  Card,
  Checkbox,
  Form,
  GetProp,
  Layout,
  message,
  Select,
  TimePicker,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";

import { Button } from "src/components/ui/button";
import { Label } from "src/components/ui/label";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { Textarea } from "src/components/ui/textarea";
import GoogleMapReact from "google-map-react";
import { LocationAdd } from "iconsax-react";
import { getFetcher, postFetcher } from "src/lib/API";
import {useToast} from "../../components/ui/use-toast";
import {setIsAuth, setUserToken} from "../../redux/slices/user_slice";
import {useLocation, useNavigate} from "react-router-dom";
import {Input} from "../../components/ui/input";
import {Controller, useForm} from "react-hook-form";
import {InboxIcon} from "lucide-react";
import {putFettcher} from "../../API";
import dayjs from "dayjs";
import ImgCrop from 'antd-img-crop';
import moment from "moment/moment";

const { Content } = Layout;
const { Dragger } = Upload;
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export default function PropertyEdit() {
  const [loading, setLoading] = React.useState(true);
  const { toast } = useToast()

  const token = useAppSelector((state) => state.user.token);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [currentMarker, setCurrentMarker] = useState({ lat: 24.6584748, lng: 46.6264939 })
  const [categories, setCategories] = useState([]);
  const [fileList, setFileList] = useState<any[]>([])
  const [nearestFacilities, setNearestFacilities] = useState([])
  const propertyId = useLocation().state.id
  const defaultProps = {
    center: {
      lat: 24.6584748,
      lng: 46.6264939
    },
    zoom: 11
  };
  const [currentTap, setCurrentTap] = useState("account");
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const status = [
    {
      arabic: "مفعل",
      english: "PUBLISHED",
    },
    {
      arabic: "قيد المراجعة",
      english: "PENDING",
    },
    {
      arabic: "مرفوض",
      english: "REJECTED",
    },
    {
      arabic: "مسودة",
      english: "DRAFT",
    }
  ]



  const { trigger,control,register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data: any) => {
    console.log(createFormData)

    const putRequest = await putFettcher(token, `properties/${propertyId}`, createFormData)
    if (putRequest) {
      toast({
        title: "تم تعديل الوحدة السكنية بنجاح",
        description: "تم تعديل الوحدة السكنية بنجاح",

      })
      console.log("Property Updated")
      if (currentTap === "account") {
        setCurrentTap("images")
      }
        if (currentTap === "images") {
            setCurrentTap("finiance")
        }
      fetchAllData()
    }
  };


  const [createFormData, setCreateFormData] = useState({
    titleEn: "",
    titleAr: "",
    descriptionAr: "",
    descriptionEn: "",
    area: 0,
    num_of_guests: 0,
    num_of_bedrooms: 0,
    num_of_single_beds: 0,
    num_of_double_beds: 0,
    num_of_bathrooms: 0,
    city_id: 0,
    country_id: 0,
    category_id: 0,
    status: "",
    latitude: 0,
    longitude: 0,
    cancelation_days: 0,
    images: [],
    price_per_hour: false,
    hour_price: 1,
    system_cancelation: false,
    refund_insurance: 0,
    refund_insurance_flag: false,
    check_in_time: "12:00",
    check_out_time: "12:00",
    facilityOptions: [],
    mainFacilities: [],
    price_saturday: 0,
    price_friday: 0,
    price_thursday: 0,
    price_rest_days: 0,
    price: 0,
    id: 0,
    twelve_hour_price: 0,
    six_hour_price: 0,
    monthly_price: 0,
    six_hour_checkin: "12:00",
    six_hour_checkout: "12:00",
    twelve_hour_checkin: "12:00",
    twelve_hour_checkout: "12:00",
    six_hour_active: false,
    twelve_hour_active: false,
    nearest_facilities: []
  });

  useEffect(() => {
    setCreateFormData(prevState => ({
      ...prevState,
      latitude: currentMarker.lat,
      longitude: currentMarker.lng,
    }));
  }, [currentMarker]);





  const MarkerComponent = ({ lat, lng, text }: any) => (
      <div><LocationAdd size="32" color="#2ccce4" variant="Bulk" /></div>
  );
  const handleMapClick = (location: { lat: number; lng: number }) => {
    setCurrentMarker({
      lat: location.lat,
      lng: location.lng

    })
    setCreateFormData((prevState: any) => ({
      ...prevState,
      latitude: location.lat,
      longitude: location.lng
    }))

  };
  async function fetchAllData() {
    try {
      const citesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}cities`);
      const countriesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}countries`);
      const facilitiesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}facilities`);
      const categoriesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}categories`);
      const propertyRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}properties/${propertyId}`);
      const nearestFacilitiesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}nearest-facilities`);

      setCities(citesRequest.cities)
      setCountries(countriesRequest.countries)
      setFacilities(facilitiesRequest.facilities)
      setCategories(categoriesRequest.categories)
        setNearestFacilities(nearestFacilitiesRequest.nearestFacilities)
      // @ts-ignore


      setCreateFormData((prevState) => {
        const sixHourPrice = propertyRequest.property.hour_prices.find((hp: { type: string; }) => hp.type === "six")?.price || 0;
        const twelveHourPrice = propertyRequest.property.hour_prices.find((hp: { type: string; }) => hp.type === "twelve")?.price || 0;
        const sixHourCheckin = propertyRequest.property.hour_prices.find((hp: { type: string; }) => hp.type === "six")?.check_in_time;
        const sixHourCheckout = propertyRequest.property.hour_prices.find((hp: { type: string; }) => hp.type === "six")?.check_out_time;
        const twelveHourCheckin = propertyRequest.property.hour_prices.find((hp: { type: string; }) => hp.type === "twelve")?.check_in_time;
        const twelveHourCheckout = propertyRequest.property.hour_prices.find((hp: { type: string; }) => hp.type === "twelve")?.check_out_time;
        const sixHourActive = propertyRequest.property.hour_prices.find((hp:any) => hp.type === "six")?.active || false;
        const twelveHourActive = propertyRequest.property.hour_prices.find((hp:any) => hp.type === "twelve")?.active || false;

        console.log(sixHourActive)
        console.log(twelveHourActive)

        return {
          titleEn: propertyRequest.property.translation.find((t:any) => t.key === "title" && t.language_id === 2)?.value || "",
          titleAr: propertyRequest.property.translation.find((t:any) => t.key === "title" && t.language_id === 1)?.value || "",
          descriptionAr: propertyRequest.property.translation.find((t:any) => t.key === "description" && t.language_id === 1)?.value || "",
          descriptionEn: propertyRequest.property.translation.find((t:any) => t.key === "description" && t.language_id === 2)?.value || "",
          area: propertyRequest.property.area,
          num_of_guests: propertyRequest.property.num_of_guests,
          num_of_bedrooms: propertyRequest.property.num_of_bedrooms,
          num_of_single_beds: propertyRequest.property.num_of_single_beds,
          num_of_double_beds: propertyRequest.property.num_of_double_beds,
          num_of_bathrooms: propertyRequest.property.num_of_bathrooms,
          city_id: propertyRequest.property.city?.id || 0,
          country_id: propertyRequest.property.country?.id || 0,
          category_id: propertyRequest.property.category?.id || 0,
          status: propertyRequest.property.status,
          latitude: propertyRequest.property.latitude,
          longitude: propertyRequest.property.longitude,
          cancelation_days: propertyRequest.property.cancelation_days,
          images: propertyRequest.property.images,
          price_per_hour: propertyRequest.property.price_per_hour !== 0,
          hour_price: propertyRequest.property.hour_price,
          system_cancelation: propertyRequest.property.system_cancelation !== 0,
          refund_insurance: propertyRequest.property.refund_insurance,
          refund_insurance_flag: propertyRequest.property.refund_insurance !== 0,
          check_in_time: propertyRequest.property.check_in_time,
          check_out_time: propertyRequest.property.check_out_time,
          facilityOptions: propertyRequest.property.facility_options.map((fo:any) => fo.id),
          mainFacilities: propertyRequest.property.facilities.map((f:any) => f.id),
          price_rest_days: propertyRequest.property.prices?.sunday || 0,
          price_saturday: propertyRequest.property.prices?.saturday || 0,
          price_friday: propertyRequest.property.prices?.friday || 0,
          price_thursday: propertyRequest.property.prices?.thursday || 0,
          price: propertyRequest.property.price_rest_days,
          id: propertyRequest.property.id,
          nearest_facilities: propertyRequest.property.nearest_facilities.map((nf:any) => nf.id),
          six_hour_price: sixHourPrice,
          twelve_hour_price: twelveHourPrice,
          monthly_price: propertyRequest.property.monthly_price || 0,
          six_hour_checkin: sixHourCheckin,
          six_hour_checkout: sixHourCheckout,
          twelve_hour_checkin: twelveHourCheckin,
          twelve_hour_checkout: twelveHourCheckout,
          six_hour_active: sixHourActive,
          twelve_hour_active: twelveHourActive,
        };
      });


      setFileList(propertyRequest.property.images.map((image:any) => ({
        uid: image.url,
        name: image.url,
        status: 'done',
        url: image.url.startsWith('https://') ? image.url : `${process.env.REACT_APP_API_URL}storage/${image.url}`
      })));
      console.log(fileList)
      // @ts-ignore

      setTimeout(() => {
        setLoading(false);
        console.log(createFormData)
      }, 1000);


    } catch (error) {
      dispatch(setIsAuth(false))
      dispatch(setUserToken(''))
      navigate('/auth/admin/login')

    }
  }
  useEffect(() => {

    console.log(createFormData)

    fetchAllData()

  }, [])


  const handleMainFacilitiesChange = (value:any) => {
    setCreateFormData(prevState => ({
      ...prevState,
      mainFacilities: value,
      facilityOptions: [],
    }));
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  // Determine if the selected facility has options
  const selectedFacilityWithOptions = facilities.find(facility =>
      // @ts-ignore
      createFormData.mainFacilities.includes(facility.id) && facility.options.length > 0
  );

  useEffect(() => {

  }, [fileList]);






  // @ts-ignore
  return (
      <>
        {loading ? (
            <div className="flex justify-center items-center h-screen">
              <HashLoader size={64} color="#36d7b7" />
            </div>
        ) : (
            <>
              <div>
                <Toaster />
              </div>

              <AdminLayout></AdminLayout>
              <Content style={{direction:"rtl"}}>
                <form id="create-property-from" onSubmit={handleSubmit(onSubmit)}  className="space-y-8">

                  <div className="mx-6 mt-6">
                    <Card>
                      <CardHeader>
                        <div className="flex items-center justify-between">
                          <h1 className="text-xl">أعدادات الوحدة السكنية</h1>
                          <Button type="submit" >
                            التالي
                          </Button>

                        </div>

                      </CardHeader>
                    </Card>
                  </div>
                  <div className=" mx-6 mt-6">

                    <Card style={{direction: "rtl"}}>
                      <Tabs onValueChange={async (value) => {
                        setCurrentTap(value)



                      }} value={currentTap} style={{direction: "rtl"}} defaultValue="account" className="w-full">
                        <TabsList className="grid w-full grid-cols-3">
                          <TabsTrigger value="account">
                            المعلومات الاساسية
                          </TabsTrigger>
                          {/*<TabsTrigger value="password">معلومات الموقع</TabsTrigger>*/}
                          {/*<TabsTrigger value="property_info">معلومات الوحدة السكنية</TabsTrigger>*/}
                          <TabsTrigger value="images">صور الوحدة السكنية</TabsTrigger>

                          {/*{createdProperty && (*/}
                          {/*    <>*/}

                          {/*    </>*/}

                          {/*)}*/}

                          {/*{createdProperty && currentStep === 3 && (*/}
                          {/*    */}
                          {/*)}*/}
                          <TabsTrigger value="finiance">
                            المعلومات الماليه
                          </TabsTrigger>


                        </TabsList>

                        <TabsContent value="account">
                          <Card style={{direction: "rtl"}} className="mt-4">
                            <CardHeader>
                              <CardTitle>اسم الوحدة السكنية</CardTitle>
                              <CardDescription></CardDescription>
                            </CardHeader>
                            <CardContent style={{direction: "rtl"}}
                                         className="space-y-2 flex flex-row justify-between w-full items-baseline">
                              <div className="w-1/2">
                                <Label htmlFor="titleEn">بالأنجليزي</Label>
                                <Input
                                    {...register("titleEn", { required: true })}
                                    className="mt-2" id="titleEn" value={createFormData.titleEn}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      titleEn: e.target.value
                                    }))} name="titleEn"/>
                                {errors.titleEn && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                              <div className="mx-2 w-1/2">
                                <Label htmlFor="titleAr">بالعربي</Label>
                                <Input
                                    {...register("titleAr", { required: true })}
                                    className="mt-2" id="titleAr" value={createFormData.titleAr}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      titleAr: e.target.value
                                    }))} name="titleAr"/>
                                {errors.titleAr && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                            </CardContent>
                            <CardHeader className="mt-4">
                              <CardTitle className="mb-4">وصف الوحدة السكنية</CardTitle>
                              <CardDescription>
                                <div className="grid grid-cols-2">
                                  <div className="mx-2">
                                    <Label className="my-2" htmlFor="descriptionEn">
                                      بالأنجليزي
                                    </Label>
                                    <Textarea
                                        {...register("descriptionEn", { required: true })}
                                        className="mt-2" id="descriptionEn" value={createFormData.descriptionEn}
                                        onChange={e => setCreateFormData(prevState => ({
                                          ...prevState,
                                          descriptionEn: e.target.value
                                        }))} name="descriptionEn"/>
                                    {errors.descriptionEn && <span className="text-red-500">هذا الحقل مطلوب</span>}
                                  </div>
                                  <div>
                                    <Label htmlFor="descriptionAr">
                                      بالعربي
                                    </Label>
                                    <Textarea
                                        {...register("descriptionAr", { required: true })}
                                        className="mt-2" id="descriptionAr" value={createFormData.descriptionAr}
                                        onChange={e => setCreateFormData(prevState => ({
                                          ...prevState,
                                          descriptionAr: e.target.value
                                        }))} name="descriptionAr"/>
                                    {errors.descriptionAr && <span className="text-red-500">هذا الحقل مطلوب</span>}
                                  </div>
                                </div>
                              </CardDescription>
                            </CardHeader>
                            <CardHeader className="mt-4">
                              <CardTitle className="mb-4">معلومات الوحدة السكنية</CardTitle>
                              <CardDescription>
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <label htmlFor="category_id">فئة الوحدة السكنية</label>
                                    <Controller
                                        name="category_id"
                                        control={control}
                                        rules={{required: false}}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                onChange={(value ) => {
                                                    field.onChange(value)
                                                    setCreateFormData(prevState => ({
                                                        ...prevState,
                                                        category_id: value
                                                    }))

                                                }}
                                                className="mt-2 w-full "
                                                placeholder="فئة الوحدة السكنية"
                                                value={createFormData.category_id}
                                                options={categories.map((category: any) => ({
                                                  label: category.title,
                                                  value: category.id
                                                }))
                                                }
                                            >

                                            </Select>
                                        )}
                                    />
                                    {errors.category_id && <span className="text-red-500 mt-12">هذا الحقل مطلوب</span>}
                                  </div>

                                  <div>
                                    <label htmlFor="status">حالة الوحدة السكنية</label>
                                    <Controller
                                        name="status"

                                        control={control}
                                        rules={{required: false}}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                onChange={(value ) => {
                                                  field.onChange(value)
                                                  setCreateFormData(prevState => ({
                                                    ...prevState,
                                                    status: value
                                                  }))

                                                }}
                                                className="mt-2 w-full "
                                                placeholder="حالة الوحدة السكنية"
                                                value={createFormData.status}
                                                options={status.map((status: any) => ({
                                                  label: status.arabic,
                                                  value: status.english
                                                }))
                                                }
                                            >

                                            </Select>
                                        )}
                                    />
                                    {errors.status && <span className="text-red-500">هذا الحقل مطلوب</span>}
                                  </div>
                                </div>
                              </CardDescription>
                            </CardHeader>

                          </Card>
                          <Card style={{direction: "rtl"}}>
                            <CardHeader>
                              <CardTitle>معلومات موقع الوحدة السكنية</CardTitle>
                            </CardHeader>
                            <CardContent className="space-y-2 grid grid-cols-2 gap-4 justify-between w-full items-baseline">
                              <div className="w-full">
                                <Label htmlFor="current">الدولة</Label>
                                <Controller
                                    name="country_id"

                                    control={control}
                                    rules={{required: false}}
                                    render={({field}) => (
                                        <Select
                                            {...field}
                                            onChange={(value) => field.onChange(value)}
                                            className="mt-2 w-full"
                                            placeholder="الدولة"
                                            value={createFormData.country_id}
                                            options={countries.map((country: any) => ({
                                              label: country.title,
                                              value: country.id
                                            }))
                                            }
                                        >

                                        </Select>
                                    )}



                                />
                                {errors.country_id && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                              <div className="w-full">
                                <Label htmlFor="new">المدينة</Label>
                                <Controller
                                    name="city_id"
                                    control={control}
                                    rules={{required: false}}
                                    render={({field}) => (
                                        <Select
                                            {...field}
                                            onChange={(value) => field.onChange(value)}
                                            className="mt-2 w-full "
                                            placeholder="المدينة"
                                            value={createFormData.city_id}
                                            options={cities.map((city: any) => ({
                                              label: city.title,
                                              value: city.id
                                            }))
                                            }
                                        >

                                        </Select>
                                    )}
                                />
                                {errors.city_id && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                              <div className="w-full">
                                <Label htmlFor="current">longtuide</Label>
                                <Input  value={currentMarker.lng} placeholder="longtuide" id="current"/>
                              </div>
                              <div className="w-full">
                                <Label htmlFor="new">latituide</Label>
                                <Input value={currentMarker.lat} placeholder="latituide" id="new"/>
                              </div>
                            </CardContent>
                            <div className="rounded-lg" style={{height: '400px', width: '100%'}}>
                              <GoogleMapReact
                                  bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}}
                                  defaultCenter={defaultProps.center}
                                  defaultZoom={defaultProps.zoom}
                                  onClick={(location) => handleMapClick(location)}
                              >
                                <MarkerComponent
                                    lat={currentMarker.lat}
                                    lng={currentMarker.lng}
                                    text="My Marker"
                                />
                              </GoogleMapReact>
                            </div>
                          </Card>
                          <Card style={{direction: "rtl"}}>
                            <CardHeader>
                              <CardTitle>معلومات الوحدة السكنية</CardTitle>
                            </CardHeader>
                            <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                              <div className="w-1/2">
                                <Label htmlFor="current">المساحة</Label>
                                <Input
                                    {...register("area", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}


                                    className="mt-2" id="current" value={createFormData.area}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      area: e.target.value ? Number(e.target.value) : 0
                                    }))}


                                    name="area"/>
                                {errors.area && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>

                              <div className="w-1/2">
                                <Label htmlFor="new">عدد الضيوف</Label>
                                <Input
                                    {...register("num_of_guests", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                    className="mt-2" value={createFormData.num_of_guests}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      num_of_guests: e.target.value ? Number(e.target.value) : 0
                                    }))} id="new"/>
                                {errors.num_of_guests && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                            </CardContent>
                            <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                              <div className="w-1/2">
                                <Label htmlFor="current">عدد الغرف</Label>
                                <Input
                                    {...register("num_of_bedrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                    className="mt-2" id="current" value={createFormData.num_of_bedrooms}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      num_of_bedrooms: e.target.value ? Number(e.target.value) : 0
                                    }))} name="num_of_bedrooms"/>
                                {errors.num_of_bedrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}

                              </div>
                              <div className="mx-2 w-1/2">
                                <Label htmlFor="new">عدد الأسرة </Label>
                                <Input
                                    {...register("num_of_single_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                    className="mt-2" id="new" value={createFormData.num_of_single_beds}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      num_of_single_beds: e.target.value ? Number(e.target.value) : 0
                                    }))} name="num_of_single_beds"/>
                                {errors.num_of_single_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>

                            </CardContent>
                            <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                              <div className="w-1/2">
                                <Label htmlFor="current">عدد دورات المياه</Label>
                                <Input
                                    {...register("num_of_bathrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                    className="mt-2" id="current" value={createFormData.num_of_bathrooms}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      num_of_bathrooms: e.target.value ? Number(e.target.value) : 0
                                    }))} name="num_of_bathrooms"/>
                                {errors.num_of_bathrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                              <div className="mx-2 w-1/2">
                                <Label htmlFor="new">عدد الأسرة المزودجه</Label>
                                <Input
                                    {...register("num_of_double_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                    className="mt-2" id="new" value={createFormData.num_of_double_beds}
                                    onChange={e => setCreateFormData(prevState => ({
                                      ...prevState,
                                      num_of_double_beds: e.target.value ? Number(e.target.value) : 0
                                    }))} name="num_of_double_beds"/>
                                {errors.num_of_double_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}
                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>


                        {/*<TabsContent value="password">*/}
                        {/*  <Card style={{direction: "rtl"}}>*/}
                        {/*    <CardHeader>*/}
                        {/*      <CardTitle>معلومات موقع الوحدة السكنية</CardTitle>*/}
                        {/*    </CardHeader>*/}
                        {/*    <CardContent className="space-y-2 grid grid-cols-2 gap-4 justify-between w-full items-baseline">*/}
                        {/*      <div className="w-full">*/}
                        {/*        <Label htmlFor="current">الدولة</Label>*/}
                        {/*        <Controller*/}
                        {/*              name="country_id"*/}

                        {/*              control={control}*/}
                        {/*              rules={{required: true}}*/}
                        {/*              render={({field}) => (*/}
                        {/*                  <Select*/}
                        {/*                      {...field}*/}
                        {/*                      onChange={(value) => field.onChange(value)}*/}
                        {/*                      className="mt-2 w-full"*/}
                        {/*                      placeholder="الدولة"*/}
                        {/*                      options={countries.map((country: any) => ({*/}
                        {/*                          label: country.title,*/}
                        {/*                          value: country.id*/}
                        {/*                      }))*/}
                        {/*                      }*/}
                        {/*                  >*/}

                        {/*                  </Select>*/}
                        {/*              )}*/}



                        {/*        />*/}
                        {/*        {errors.country_id && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}
                        {/*      <div className="w-full">*/}
                        {/*          <Label htmlFor="new">المدينة</Label>*/}
                        {/*          <Controller*/}
                        {/*                  name="city_id"*/}
                        {/*                  control={control}*/}
                        {/*                  rules={{required: true}}*/}
                        {/*                  render={({field}) => (*/}
                        {/*                      <Select*/}
                        {/*                          {...field}*/}
                        {/*                          onChange={(value) => field.onChange(value)}*/}
                        {/*                          className="mt-2 w-full "*/}
                        {/*                          placeholder="المدينة"*/}
                        {/*                          options={cities.map((city: any) => ({*/}
                        {/*                              label: city.title,*/}
                        {/*                              value: city.id*/}
                        {/*                          }))*/}
                        {/*                          }*/}
                        {/*                      >*/}

                        {/*                      </Select>*/}
                        {/*                  )}*/}
                        {/*          />*/}
                        {/*          {errors.city_id && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}
                        {/*      <div className="w-full">*/}
                        {/*          <Label htmlFor="current">longtuide</Label>*/}
                        {/*          <Input  value={currentMarker.lng} placeholder="longtuide" id="current"/>*/}
                        {/*      </div>*/}
                        {/*      <div className="w-full">*/}
                        {/*        <Label htmlFor="new">latituide</Label>*/}
                        {/*        <Input value={currentMarker.lat} placeholder="latituide" id="new"/>*/}
                        {/*      </div>*/}
                        {/*    </CardContent>*/}
                        {/*    <div className="rounded-lg" style={{height: '400px', width: '100%'}}>*/}
                        {/*      <GoogleMapReact*/}
                        {/*          bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}}*/}
                        {/*          defaultCenter={defaultProps.center}*/}
                        {/*          defaultZoom={defaultProps.zoom}*/}
                        {/*          onClick={(location) => handleMapClick(location)}*/}
                        {/*      >*/}
                        {/*        <MarkerComponent*/}
                        {/*            lat={currentMarker.lat}*/}
                        {/*            lng={currentMarker.lng}*/}
                        {/*            text="My Marker"*/}
                        {/*        />*/}
                        {/*      </GoogleMapReact>*/}
                        {/*    </div>*/}
                        {/*  </Card>*/}
                        {/*</TabsContent>*/}

                        {/*<TabsContent value="property_info">*/}
                        {/*  <Card style={{direction: "rtl"}}>*/}
                        {/*    <CardHeader>*/}
                        {/*      <CardTitle>معلومات الوحدة السكنية</CardTitle>*/}
                        {/*    </CardHeader>*/}
                        {/*    <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">*/}
                        {/*      <div className="w-1/2">*/}
                        {/*        <Label htmlFor="current">المساحة</Label>*/}
                        {/*        <Input*/}
                        {/*            {...register("area", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}


                        {/*            className="mt-2" id="current" value={createFormData.area}*/}
                        {/*              onChange={e => setCreateFormData(prevState => ({*/}
                        {/*                  ...prevState,*/}
                        {/*                  area: e.target.value ? Number(e.target.value) : 0*/}
                        {/*              }))}*/}


                        {/*            name="area"/>*/}
                        {/*        {errors.area && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}

                        {/*      <div className="w-1/2">*/}
                        {/*        <Label htmlFor="new">عدد الضيوف</Label>*/}
                        {/*          <Input*/}
                        {/*              {...register("num_of_guests", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                        {/*              className="mt-2" value={createFormData.num_of_guests}*/}
                        {/*              onChange={e => setCreateFormData(prevState => ({*/}
                        {/*                  ...prevState,*/}
                        {/*                  num_of_guests: e.target.value ? Number(e.target.value) : 0*/}
                        {/*              }))} id="new"/>*/}
                        {/*          {errors.num_of_guests && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}
                        {/*    </CardContent>*/}
                        {/*    <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">*/}
                        {/*     <div className="w-1/2">*/}
                        {/*      <Label htmlFor="current">عدد الغرف</Label>*/}
                        {/*      <Input*/}
                        {/*          {...register("num_of_bedrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                        {/*          className="mt-2" id="current" value={createFormData.num_of_bedrooms}*/}
                        {/*          onChange={e => setCreateFormData(prevState => ({*/}
                        {/*              ...prevState,*/}
                        {/*              num_of_bedrooms: e.target.value ? Number(e.target.value) : 0*/}
                        {/*          }))} name="num_of_bedrooms"/>*/}
                        {/*      {errors.num_of_bedrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}

                        {/*      </div>*/}
                        {/*      <div className="mx-2 w-1/2">*/}
                        {/*        <Label htmlFor="new">عدد الأسرة </Label>*/}
                        {/*          <Input*/}
                        {/*              {...register("num_of_single_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                        {/*              className="mt-2" id="new" value={createFormData.num_of_single_beds}*/}
                        {/*              onChange={e => setCreateFormData(prevState => ({*/}
                        {/*                  ...prevState,*/}
                        {/*                  num_of_single_beds: e.target.value ? Number(e.target.value) : 0*/}
                        {/*              }))} name="num_of_single_beds"/>*/}
                        {/*          {errors.num_of_single_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}

                        {/*    </CardContent>*/}
                        {/*    <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">*/}
                        {/*      <div className="w-1/2">*/}
                        {/*        <Label htmlFor="current">عدد دورات المياه</Label>*/}
                        {/*        <Input*/}
                        {/*            {...register("num_of_bathrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                        {/*            className="mt-2" id="current" value={createFormData.num_of_bathrooms}*/}
                        {/*            onChange={e => setCreateFormData(prevState => ({*/}
                        {/*              ...prevState,*/}
                        {/*              num_of_bathrooms: e.target.value ? Number(e.target.value) : 0*/}
                        {/*            }))} name="num_of_bathrooms"/>*/}
                        {/*        {errors.num_of_bathrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}
                        {/*      <div className="mx-2 w-1/2">*/}
                        {/*        <Label htmlFor="new">عدد الأسرة المزودجه</Label>*/}
                        {/*        <Input*/}
                        {/*            {...register("num_of_double_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                        {/*            className="mt-2" id="new" value={createFormData.num_of_double_beds}*/}
                        {/*            onChange={e => setCreateFormData(prevState => ({*/}
                        {/*              ...prevState,*/}
                        {/*              num_of_double_beds: e.target.value ? Number(e.target.value) : 0*/}
                        {/*            }))} name="num_of_double_beds"/>*/}
                        {/*        {errors.num_of_double_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                        {/*      </div>*/}
                        {/*    </CardContent>*/}
                        {/*  </Card>*/}
                        {/*</TabsContent>*/}

                        <TabsContent value="images">
                          <Card style={{direction: "rtl"}}>
                            <CardHeader>
                              <CardTitle>صور الوحدة السكنية</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <ImgCrop rotationSlider>
                              <Upload
                                  multiple={true}
                                  name="file"
                                  action={`${process.env.REACT_APP_DEV_ADMIN_API_URL}upload/properties`}
                                  listType="picture"
                                  headers={{
                                    Authorization: `Bearer ${token}`,
                                  }}
                                  
                                  fileList={fileList}
                                  onPreview={onPreview}
                                  onChange={(info) => {
                                    const { status } = info.file;
                                    if (status !== 'uploading') {
                                    }
                                    if (status === 'done') {
                                      message.success(`${info.file.name} file uploaded successfully.`);
                                      const uploadedImageUrl = info.file.response.data;

                                      // Update createFormData with uploaded image URL
                                      setCreateFormData((prevState:any) => ({
                                        ...prevState,
                                        images: [
                                          ...prevState.images,
                                          {
                                            url: uploadedImageUrl
                                          }
                                        ]
                                      }));
                                    } else if (status === 'error') {
                                      message.error(`${info.file.name} file upload failed.`);
                                    }
                                    setFileList(info.fileList);
                                  }}
                                  onRemove={(file) => {
                                    setFileList((prevFileList) => {
                                      const index = prevFileList.indexOf(file);
                                      const newFileList = prevFileList.slice();
                                      newFileList.splice(index, 1);
                                      return newFileList;
                                    });
                                    setCreateFormData((prevState:any) => ({
                                      ...prevState,
                                      images: prevState.images.filter((image: { url: string | undefined; }) => image.url !== file.url)
                                    }));
                                  }}
                              >
                                <Button type="button">Click to Upload</Button>
                              </Upload>
                                </ImgCrop>




                              {errors.images && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </CardContent>
                            <CardContent>
                              <div className="w-full">
                                <Label htmlFor="current">المرافق</Label>
                                <Controller
                                    name="facilities"
                                    control={control}
                                    defaultValue={createFormData.mainFacilities}
                                    rules={{ required: true, validate: value => value.length > 0 }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            mode="multiple"
                                            value={createFormData.mainFacilities}
                                            onChange={(value) => {
                                              field.onChange(value);
                                              handleMainFacilitiesChange(value);
                                            }}
                                            showSearch
                                            optionFilterProp={"label"}
                                            className="mt-2 w-full"
                                            placeholder="المرافق"
                                            virtual={facilities.length > 100}
                                            options={facilities.map((facility: any) => ({
                                              label: facility.title,
                                              value: facility.id
                                            }))}
                                        />
                                    )}
                                />
                                {errors.facilities && <span className="text-red-500">
                                الرجاء اختيار مرفق واحد على الأقل
                              </span>}
                                {selectedFacilityWithOptions && (
                                    <Controller
                                        name="facilityOptions"
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                mode="multiple"
                                                value={createFormData.facilityOptions}
                                                onChange={(value) => {
                                                  field.onChange(value);
                                                  setCreateFormData(prevState => ({
                                                    ...prevState,
                                                    facilityOptions: value,
                                                  }));
                                                }}
                                                showSearch
                                                optionFilterProp={"label"}
                                                className="mt-2 w-full"
                                                placeholder="خيارات المرافق"
                                                virtual={facilities.length > 100}
                                                // @ts-ignore
                                                options={selectedFacilityWithOptions.options.map((option: any) => ({
                                                  label: option.title,
                                                  value: option.id
                                                }))}
                                            />
                                        )}
                                    />
                                )}
                                {errors.facilityOptions && <span className="text-red-500">
                                الرجاء اختيار خيار واحد على الأقل
                              </span>}
                              {/*<div>*/}
                              {/*  <Label htmlFor="current">*/}
                              {/*    اقرب المرافق*/}
                              {/*  </Label>*/}
                              {/*  <Controller*/}
                              {/*      name="nearFacilities"*/}
                              {/*      control={control}*/}
                              {/*      defaultValue={createFormData.nearest_facilities}*/}
                              {/*      rules={{ required: true, validate: value => value.length > 0 }}*/}
                              {/*      render={({ field }) => (*/}
                              {/*          <Select*/}
                              {/*              {...field}*/}
                              {/*              mode="multiple"*/}
                              {/*              value={createFormData.nearest_facilities}*/}
                              {/*              onChange={(value) => {*/}
                              {/*                field.onChange(value);*/}
                              {/*                setCreateFormData(prevState => ({*/}
                              {/*                  ...prevState,*/}
                              {/*                  nearest_facilities: value,*/}
                              {/*                }));*/}
                              {/*              }}*/}
                              {/*              showSearch*/}
                              {/*              optionFilterProp={"label"}*/}
                              {/*              className="mt-2 w-full"*/}
                              {/*              placeholder="المرافق"*/}
                              {/*              virtual={nearestFacilities?.length > 100}*/}
                              {/*              options={nearestFacilities.map((facility: any) => ({*/}
                              {/*                label: facility.title,*/}
                              {/*                value: facility.id*/}
                              {/*              }))}*/}
                              {/*          />*/}
                              {/*      )}*/}
                              {/*  />*/}
                              {/*</div>*/}

                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>

                        <TabsContent value="finiance">
                              <Card style={{ direction: "rtl" }} className="mt-4">
                                <CardHeader>
                                  <CardTitle>أسعار الوحدة السكنية</CardTitle>
                                </CardHeader>
                                <CardContent className="space-y-2 gap-4 grid-cols-2 justify-between w-full ">
                                  {/* Price Thursday */}
                                  <div className="grid-cols-2 gap-4 grid">
                                    <div className="w-full">
                                      <Label htmlFor="price_thursday">سعر الوحدة السكنية يوم الخميس</Label>
                                      <Input
                                          {...register("price_thursday", {
                                            required: false,
                                          })}
                                          onChange={e => setCreateFormData(prevState => ({
                                            ...prevState,
                                            price_thursday: e.target.value ? Number(e.target.value) : 0
                                          }))}
                                          className="mt-2"
                                          type="number"
                                          id="price_thursday"
                                          value={createFormData.price_thursday}
                                          name="price_thursday"/>
                                      {errors.price_thursday && <span className="text-red-500">
                                 الحقل مطلوب
                              </span>}
                                    </div>

                                    {/* Price Friday */}
                                    <div className="w-full">
                                      <Label htmlFor="price_friday">سعر الوحدة السكنية يوم الجمعة</Label>
                                      <Input
                                          {...register("price_friday", {
                                            required: false,
                                          })}
                                          onChange={e => setCreateFormData(prevState => ({
                                            ...prevState,
                                            price_friday: e.target.value ? Number(e.target.value) : 0
                                          }))}
                                          className="mt-2"
                                          type="number"
                                          id="price_friday"
                                            value={createFormData.price_friday}
                                          name="price_friday"/>
                                      {errors.price_friday && <span className="text-red-500">
                                الحقل مطلوب
                              </span>}
                                    </div>
                                  </div>

                                  {/* Price Saturday */}
                                  <div className="grid-cols-2 gap-4 grid">
                                    <div className="w-full">
                                      <Label htmlFor="price_saturday">سعر الوحدة السكنية يوم السبت</Label>
                                      <Input
                                          {...register("price_saturday", {
                                            required: false,
                                          })}
                                          onChange={e => setCreateFormData(prevState => ({
                                            ...prevState,
                                            price_saturday: e.target.value ? Number(e.target.value) : 0
                                          }))}

                                          className="mt-2"
                                          type="number"
                                          id="price_saturday"
                                            value={createFormData.price_saturday}
                                          name="price_saturday"/>
                                      {errors.price_saturday && <span className="text-red-500">
                                 الحقل مطلوب
                              </span>}
                                    </div>

                                    {/* Price Rest Days */}
                                    <div className="w-full">
                                      <Label htmlFor="price_rest_days">سعر الوحدة السكنية في الأيام العادية</Label>
                                      <Input
                                          {...register("price_rest_days", {
                                            required: false,
                                          })}
                                          onChange={e => setCreateFormData(prevState => ({
                                            ...prevState,
                                            price_rest_days: e.target.value ? Number(e.target.value) : 0,
                                            price: e.target.value ? Number(e.target.value) : 0
                                          }))}
                                          className="mt-2"
                                          type="number"
                                          id="price_rest_days"
                                            value={createFormData.price_rest_days}
                                          name="price_rest_days"/>
                                      {errors.price_rest_days && <span className="text-red-500">
                                 الحقل مطلوب
                              </span>}

                                    </div>
                                  </div>


                                  <div className="grid grid-cols-1 gap-4 mt-12">
                                    <div className="flex items-center">
                                      <Label>وقت الدخول</Label>
                                      <Controller
                                          name="check_in_time"
                                          control={control}
                                          defaultValue={createFormData.check_in_time}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                              <TimePicker
                                                  {...field}
                                                  value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                  onChange={(time, timeString:any) => {
                                                    if (!timeString) {
                                                      field.onChange(null);
                                                    } else {
                                                      setCreateFormData(prevState => ({
                                                        ...prevState,
                                                        check_in_time: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                      }));
                                                      field.onChange(timeString);
                                                    }
                                                  }}
                                                  format="HH:mm"
                                                  className="mx-2"
                                              />
                                          )}
                                      />
                                      {errors.check_in_time &&
                                          <span className="text-red-500 mr-4">
                                          الرجاء اختيار وقت الدخول
                                        </span>}
                                    </div>

                                    <div className="flex items-center">
                                      <Label>وقت الخروج</Label>
                                      <Controller
                                          name="check_out_time"
                                          control={control}
                                          defaultValue={createFormData.check_out_time}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                              <TimePicker
                                                  {...field}
                                                  value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                  onChange={(time, timeString:any) => {
                                                    if (!timeString) {
                                                      field.onChange(null);
                                                    } else {
                                                      setCreateFormData(prevState => ({
                                                        ...prevState,
                                                        check_out_time: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                      }));
                                                      field.onChange(timeString);
                                                    }
                                                  }}
                                                  format="HH:mm"
                                                  className="mx-2"
                                              />
                                          )}
                                      />
                                      {errors.check_out_time &&
                                          <span className="text-red-500 mr-4">
                                            الرجاء اختيار وقت الخروج
                                        </span>}
                                    </div>


                                    <Controller
                                        name="freeCancellation"
                                        control={control}
                                        render={({field}) => (
                                            <div className="flex items-center gap-4">
                                              <Label>الغاء مجاني للحجز</Label>
                                              <Checkbox {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}>
                                                مفعل
                                              </Checkbox>
                                            </div>
                                        )}
                                    />



                                    <Controller
                                        name="insuranceRefunded"
                                        control={control}
                                        render={({field}) => (
                                            <div className="flex items-center gap-4">
                                              <Label>التأمين مسترد</Label>
                                              <Checkbox {...field}

                                                        checked={createFormData.refund_insurance_flag}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.checked);
                                                            setCreateFormData((prevState:any) => ({
                                                                ...prevState,
                                                              refund_insurance_flag: e.target.checked ? 1 : 0
                                                            }));

                                                        }}
                                                       >
                                                مفعل
                                              </Checkbox>
                                              {createFormData.refund_insurance_flag && (
                                                  <div className="flex items-center gap-4">
                                                    <Label htmlFor="refund_insurance">
                                                      السعر
                                                    </Label>
                                                    <Input


                                                        {...register("refund_insurance", field.value ? {required: false} : {})}
                                                           className="mt-2" type="number" id="refund_insurance"
                                                           name="refund_insurance"
                                                        value={createFormData.refund_insurance}
                                                        onChange={e => setCreateFormData((prevState:any) => ({
                                                          ...prevState,
                                                          refund_insurance: parseInt(e.target.value)
                                                        }))
                                                        }


                                                    />
                                                    {errors.refund_insurance && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                                  </div>
                                              )}
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="price_per_month"
                                        control={control}
                                        render={({field}) => (
                                            <div className="flex items-center gap-4">
                                              <Label>
                                                التأجير بالشهر
                                              </Label>
                                              <Checkbox

                                                  {...field}



                                                  checked={field.value}
                                                  onChange={(e) => {
                                                    field.onChange(e.target.checked);
                                                    setCreateFormData((prevState:any) => ({
                                                      ...prevState,
                                                      price_per_month: e.target.checked ? 1 : 0
                                                    }));
                                                  }}>
                                                مفعل
                                              </Checkbox>
                                              {field.value && (
                                                  <div className="flex items-center gap-4">
                                                    <Label htmlFor="monthly_price">
                                                      السعر
                                                    </Label>
                                                    <Input {...register("monthly_price", field.value ? {required: false} : {})}
                                                           className="mt-2" type="number" id="monthly_price"
                                                           name="monthly_price"
                                                           onChange={e => setCreateFormData(prevState => ({
                                                             ...prevState,
                                                             monthly_price: parseInt(e.target.value)
                                                           }))}
                                                    />
                                                    {errors.monthly_price && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                                  </div>
                                              )}
                                            </div>
                                        )}
                                    />
                                    {/*<Controller*/}
                                    {/*    name="six_hour_active"*/}
                                    {/*    control={control}*/}
                                    {/*    render={({field}) => (*/}
                                    {/*        <div className="flex items-center w-full gap-4">*/}
                                    {/*          <Label>*/}
                                    {/*            التأجير كل ست ساعات*/}
                                    {/*          </Label>*/}
                                    {/*          <Checkbox*/}

                                    {/*              {...field}*/}



                                    {/*              checked={createFormData.six_hour_active}*/}
                                    {/*              onChange={(e) => {*/}
                                    {/*                field.onChange(e.target.checked);*/}
                                    {/*                setCreateFormData((prevState:any) => ({*/}
                                    {/*                  ...prevState,*/}
                                    {/*                  six_hour_active: e.target.checked ? 1 : 0*/}
                                    {/*                }));*/}
                                    {/*                console.log(createFormData.six_hour_active)*/}
                                    {/*              }}>*/}
                                    {/*            مفعل*/}
                                    {/*          </Checkbox>*/}

                                    {/*          {createFormData.six_hour_active && (*/}
                                    {/*              <div className="flex items-center gap-4">*/}
                                    {/*                <Label htmlFor="six_hour_price">*/}
                                    {/*                  السعر*/}
                                    {/*                </Label>*/}
                                    {/*                <Input {...register("six_hour_price", field.value ? {required: "هذا الحقل مطلوب"} : {})}*/}
                                    {/*                       className="mt-2 w-1/2" type="number" id="six_hour_price"*/}
                                    {/*                       name="six_hour_price"*/}
                                    {/*                       value={createFormData.six_hour_price}*/}

                                    {/*                       onChange={e => setCreateFormData(prevState => ({*/}
                                    {/*                         ...prevState,*/}
                                    {/*                         six_hour_price: parseInt(e.target.value)*/}
                                    {/*                       }))}*/}
                                    {/*                />*/}



                                    {/*                {errors.six_hour_price && <span className="text-red-500">*/}
                                    {/*                الحقل مطلوب*/}
                                    {/*              </span>}*/}
                                    {/*              </div>*/}
                                    {/*          )}*/}

                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*/>*/}


                                    {/*  {!!createFormData.six_hour_active && (*/}
                                    {/*      <div className="flex w-full items-center gap-4">*/}
                                    {/*        <div className="flex items-center w-full  gap-4">*/}
                                    {/*          <Label>*/}
                                    {/*            وقت الدخول*/}
                                    {/*          </Label>*/}
                                    {/*          <Controller*/}
                                    {/*              name="six_hour_checkin"*/}
                                    {/*              control={control}*/}
                                    {/*              defaultValue={createFormData.six_hour_checkin}*/}
                                    {/*              rules={{ required: true }}*/}
                                    {/*              render={({ field }) => (*/}
                                    {/*                  <TimePicker*/}
                                    {/*                      {...field}*/}
                                    {/*                      value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists*/}
                                    {/*                      onChange={(time, timeString:any) => {*/}
                                    {/*                        if (!timeString) {*/}
                                    {/*                          field.onChange(null);*/}
                                    {/*                        } else {*/}
                                    {/*                          setCreateFormData(prevState => ({*/}
                                    {/*                            ...prevState,*/}
                                    {/*                            six_hour_checkin: dayjs(timeString, 'HH:mm').format('HH:mm')*/}
                                    {/*                          }));*/}
                                    {/*                          field.onChange(timeString);*/}
                                    {/*                        }*/}
                                    {/*                      }}*/}
                                    {/*                      format="HH:mm"*/}
                                    {/*                      className="mx-2"*/}
                                    {/*                  />*/}
                                    {/*              )}*/}
                                    {/*          />*/}

                                    {/*          {errors.six_hour_checkin && <span className="text-red-500">This field is required</span>}*/}
                                    {/*        </div>*/}

                                    {/*        <div className="flex items-center gap-4">*/}
                                    {/*          <Label>*/}
                                    {/*            وقت الخروج*/}
                                    {/*          </Label>*/}
                                    {/*          <Controller*/}
                                    {/*              name="six_hour_checkout"*/}
                                    {/*              control={control}*/}
                                    {/*              defaultValue={createFormData.six_hour_checkout}*/}
                                    {/*              rules={{ required: true }}*/}
                                    {/*              render={({ field }) => (*/}
                                    {/*                  <TimePicker*/}
                                    {/*                      {...field}*/}
                                    {/*                      value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists*/}
                                    {/*                      onChange={(time, timeString:any) => {*/}
                                    {/*                        if (!timeString) {*/}
                                    {/*                          field.onChange(null);*/}
                                    {/*                        } else {*/}
                                    {/*                          setCreateFormData(prevState => ({*/}
                                    {/*                            ...prevState,*/}
                                    {/*                            six_hour_checkout: dayjs(timeString, 'HH:mm').format('HH:mm')*/}
                                    {/*                          }));*/}
                                    {/*                          field.onChange(timeString);*/}
                                    {/*                        }*/}
                                    {/*                      }}*/}
                                    {/*                      format="HH:mm"*/}
                                    {/*                      className="mx-2"*/}
                                    {/*                  />*/}
                                    {/*              )}*/}
                                    {/*          />*/}

                                    {/*          {errors.six_hour_checkout && <span className="text-red-500">This field is required</span>}*/}
                                    {/*        </div>*/}
                                    {/*      </div>*/}
                                    {/*  )}*/}


                                    <Controller
                                        name="six_hour_active"
                                        control={control}
                                        render={({field}) => (
                                            <div className="gap-4 flex items-center">
                                              <Label>
                                                التأجير كل 6 ساعات
                                              </Label>
                                              <Checkbox

                                                  {...field}



                                                  checked={createFormData.six_hour_active}
                                                  onChange={(e) => {
                                                    field.onChange(e.target.checked);
                                                    setCreateFormData((prevState:any) => ({
                                                      ...prevState,
                                                      six_hour_active: e.target.checked ? true : false
                                                    }));
                                                  }}>
                                                مفعل
                                              </Checkbox>

                                              <div className="flex items-center w-full  gap-4">
                                                <div className="flex gap-4 w-full  items-center">

                                                  {!!createFormData.six_hour_active && (

                                                      <div className="flex w-full items-center gap-4">
                                                        <Label htmlFor="twelve_hour_price">
                                                          السعر
                                                        </Label>
                                                        <Input {...register("six_hour_price", field.value ? {required: "هذا الحقل مطلوب"} : {})}
                                                               className="mt-2 w-1/2" type="number" id="six_hour_price"
                                                               name="six_hour_price"
                                                               value={createFormData.six_hour_price}
                                                               onChange={e => setCreateFormData(prevState => ({
                                                                 ...prevState,
                                                                 six_hour_price: parseInt(e.target.value)
                                                               }))}
                                                        />
                                                        <div className="flex items-center  gap-4">
                                                          <Label>
                                                            وقت الدخول
                                                          </Label>
                                                          <Controller
                                                              name="six_hour_checkin"
                                                              control={control}
                                                              defaultValue={createFormData.six_hour_checkin}
                                                              rules={{ required: true }}
                                                              render={({ field }) => (
                                                                  <TimePicker
                                                                      {...field}
                                                                      value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                      onChange={(time, timeString:any) => {
                                                                        if (!timeString) {
                                                                          field.onChange(null);
                                                                        } else {
                                                                          setCreateFormData(prevState => ({
                                                                            ...prevState,
                                                                            six_hour_checkin: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                          }));
                                                                          field.onChange(timeString);
                                                                        }
                                                                      }}
                                                                      format="HH:mm"
                                                                      className="mx-2"
                                                                  />
                                                              )}
                                                          />

                                                          {errors.six_hour_checkin && <span className="text-red-500">This field is required</span>}
                                                        </div>

                                                        <div className="flex items-center gap-4">
                                                          <Label>
                                                            وقت الخروج
                                                          </Label>
                                                          <Controller
                                                              name="six_hour_checkout"
                                                              control={control}
                                                              defaultValue={createFormData.six_hour_checkout}
                                                              rules={{ required: true }}
                                                              render={({ field }) => (
                                                                  <TimePicker
                                                                      {...field}
                                                                      value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                      onChange={(time, timeString:any) => {
                                                                        if (!timeString) {
                                                                          field.onChange(null);
                                                                        } else {
                                                                          setCreateFormData(prevState => ({
                                                                            ...prevState,
                                                                            six_hour_checkout: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                          }));
                                                                          field.onChange(timeString);
                                                                        }
                                                                      }}
                                                                      format="HH:mm"
                                                                      className="mx-2"
                                                                  />
                                                              )}
                                                          />


                                                          {errors.six_hour_checkout && <span className="text-red-500">This field is required</span>}
                                                        </div>
                                                      </div>
                                                  )}

                                                </div>

                                                {errors.six_hour_price && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                              </div>

                                            </div>
                                        )}
                                    />



                                    <Controller
                                        name="twelve_hour_active"
                                        control={control}
                                        render={({field}) => (
                                            <div className="gap-4 flex items-center">
                                              <Label>
                                                التأجير كل 12 ساعة
                                              </Label>
                                              <Checkbox

                                                  {...field}



                                                  checked={createFormData.twelve_hour_active}
                                                  onChange={(e) => {
                                                    field.onChange(e.target.checked);
                                                    setCreateFormData((prevState:any) => ({
                                                      ...prevState,
                                                      twelve_hour_active: e.target.checked ? true : false
                                                    }));
                                                  }}>
                                                مفعل
                                              </Checkbox>

                                                  <div className="flex items-center w-full  gap-4">
                                                    <div className="flex gap-4 w-full  items-center">

                                                      {!!createFormData.twelve_hour_active && (

                                                          <div className="flex w-full items-center gap-4">
                                                            <Label htmlFor="twelve_hour_price">
                                                              السعر
                                                            </Label>
                                                            <Input {...register("twelve_hour_price", field.value ? {required: false} : {})}
                                                                   className="mt-2 w-1/2" type="number" id="twelve_hour_price"
                                                                   name="twelve_hour_price"
                                                                   value={createFormData.twelve_hour_price}
                                                                   onChange={e => setCreateFormData(prevState => ({
                                                                     ...prevState,
                                                                     twelve_hour_price: parseInt(e.target.value)
                                                                   }))}
                                                            />
                                                            <div className="flex items-center  gap-4">
                                                              <Label>
                                                                وقت الدخول
                                                              </Label>
                                                              <Controller
                                                                  name="twelve_hour_checkin"
                                                                  control={control}
                                                                  defaultValue={createFormData.twelve_hour_checkin}
                                                                  rules={{ required: false }}
                                                                  render={({ field }) => (
                                                                      <TimePicker
                                                                          {...field}
                                                                          value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                          onChange={(time, timeString:any) => {
                                                                            if (!timeString) {
                                                                              field.onChange(null);
                                                                            } else {
                                                                              setCreateFormData(prevState => ({
                                                                                ...prevState,
                                                                                twelve_hour_checkin: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                              }));
                                                                              field.onChange(timeString);
                                                                            }
                                                                          }}
                                                                          format="HH:mm"
                                                                          className="mx-2"
                                                                      />
                                                                  )}
                                                              />

                                                              {errors.twelve_hour_checkin && <span className="text-red-500">This field is required</span>}
                                                            </div>

                                                            <div className="flex items-center gap-4">
                                                              <Label>
                                                                وقت الخروج
                                                              </Label>
                                                              <Controller
                                                                  name="twelve_hour_checkout"
                                                                  control={control}
                                                                  defaultValue={createFormData.twelve_hour_checkout}
                                                                  rules={{ required: false }}
                                                                  render={({ field }) => (
                                                                      <TimePicker
                                                                          {...field}
                                                                          value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                          onChange={(time, timeString:any) => {
                                                                            if (!timeString) {
                                                                              field.onChange(null);
                                                                            } else {
                                                                              setCreateFormData(prevState => ({
                                                                                ...prevState,
                                                                                twelve_hour_checkout: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                              }));
                                                                              field.onChange(timeString);
                                                                            }
                                                                          }}
                                                                          format="HH:mm"
                                                                          className="mx-2"
                                                                      />
                                                                  )}
                                                              />


                                                              {errors.twelve_hour_checkout && <span className="text-red-500">This field is required</span>}
                                                            </div>
                                                          </div>
                                                      )}

                                                    </div>

                                                    {errors.twelve_hour_price && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                                  </div>

                                            </div>
                                        )}
                                    />


                                  </div>

                                </CardContent>


                              </Card>


                        </TabsContent>

                      </Tabs>
                    </Card>

                  </div>
                </form>


              </Content>
            </>
        )}
      </>
  );
}
