import {Card, Layout, message, Upload, UploadProps} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {CardContent, CardFooter, CardHeader, CardTitle} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Icon } from "@iconify/react";

import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  BookSquare,
  Copyright,
  Edit,
  Eye,
  Flag,
  Messages1,
  Notification1, Personalcard, Receipt,
  SecuritySafe, Star, TransactionMinus,
  Trash,
  User, UserOctagon
} from "iconsax-react";
import {getByPageName, getFetcher, postFetcher, updateByPageName} from "src/lib/API";
import {setImage, setIsAuth, setName, setUserToken} from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {Label} from "../../../components/ui/label";
import {Input} from "../../../components/ui/input";

const { Content } = Layout;

export default function MyProfile() {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate();

  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const [account, setAccount]: any = React.useState([])
  const [isUploading, setIsUploading] = React.useState(false)
  const [fileList, setFileList]:any = React.useState([])

  async function fetchData() {
    try {
      const request = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}get-account`)
      setAccount(request)
      setLoading(false)
      dispatch(setImage(request.storage_image))
      dispatch(setName(request.first_name + " " + request.last_name))
      console.log(request)
    }
    catch (error) {
      console.error(error)
      dispatch(setIsAuth(false))
      dispatch(setUserToken(''))
      navigate('/auth/admin/login')
    }


  }
  useEffect(() => {
    window.document.title = "حسابي"

    fetchData()
  }, [])

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: process.env.REACT_APP_DEV_ADMIN_API_URL + 'upload/users',
    headers: {
      authorization: `Bearer ${token}`
    },
    beforeUpload(file) {

    }

    ,

    async onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setFileList(info.fileList)
      }
      if (info.file.status === 'done') {

        fetchData()

        message.success(`${info.file.name} file uploaded successfully`);

      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };





  return (
    <>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>

      ) : (
        <>
          <div><Toaster /></div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-row items-center">
                      <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                        <UserOctagon size="48" color="#2dacf4" variant="Bulk"/>
                      </div>

                      <h1 className="text-xl mr-2">
                        حسابي
                      </h1>
                    </div>
                    <div>
                      <Button onClick={async () => {
                        if (account.password !== account.password_confirmation) {
                            toast.error("كلمة المرور غير متطابقة")
                            return
                            }
                        if (account.current_password === "" || account.password === "" || account.password_confirmation === "") {
                            toast.error("يجب ادخال كلمة المرور الحالية وكلمة المرور الجديدة")
                            return
                        }

                        const request = await postFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}profile`, account)
                        const upload = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}profile`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                          },
                          body: JSON.stringify({
                            ...account,
                            image:fileList[0].response.data,


                          })
                        })
                        toast.success("تم تحديث البيانات بنجاح")
                        fetchData()
                      }}>
                        حفظ
                      </Button>


                    </div>


                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="mx-6 mt-6">
                <Card>
                  <CardContent>
                    <div className="gap-4 flex flex-col">
                      <h1>
                        الصورة الشخصية
                      </h1>
                      <div className="flex items-center gap-4">
                        <img src={account.storage_image} alt="" className="w-24 h-24 rounded-full"/>
                        <Upload {...props}>
                        <Button>
                          تغير الصورة الشخصية
                        </Button>
                        </Upload>
                      </div>

                    </div>


                    {/*<Upload*/}
                    {/*    name="avatar"*/}
                    {/*    listType="picture-circle"*/}
                    {/*    className="avatar-uploader"*/}
                    {/*    showUploadList={false}*/}
                    {/*    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"*/}
                    {/*    beforeUpload={beforeUpload}*/}
                    {/*    onChange={handleChange}*/}
                    {/*>*/}
                    {/*  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}*/}
                    {/*</Upload>*/}
                  </CardContent>
                  <CardHeader>
                    <CardTitle>المعلومات الشخصية</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Label>
                            الاسم الاول
                        </Label>
                        <Input value={account.first_name} onChange={(e) => setAccount({...account, first_name: e.target.value})} />
                      </div>
                        <div>
                            <Label>
                            الاسم الاخير
                            </Label>
                            <Input value={account.last_name} onChange={(e) => setAccount({...account, last_name: e.target.value})} />
                        </div>
                      <div>
                        <Label>
                          البريد الالكتروني
                        </Label>
                        <Input value={account.email} onChange={(e) => setAccount({...account, email: e.target.value})} />
                      </div>
                    </div>
                  </CardContent>
                  <CardHeader>
                    <CardTitle>
                        الامان
                    </CardTitle>

                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Label>
                          كلمة المرور الحالية
                        </Label>
                        <Input value={account.current_password} onChange={(e) => setAccount({...account, current_password: e.target.value})} />
                      </div>
                      <div>
                        <Label>
                          كلمة المرور
                        </Label>
                        <Input value={account.password} onChange={(e) => setAccount({...account, password: e.target.value})} />
                      </div>
                      <div>
                        <Label>
                          تأكيد كلمة المرور
                        </Label>
                        <Input value={account.password_confirmation} onChange={(e) => setAccount({...account, password_confirmation: e.target.value})} />
                      </div>
                    </div>
                  </CardContent>
                </Card>
            </div>




          </Content>
        </>
      )}


    </>
  )
}