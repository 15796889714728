import {useAppDispatch} from "./redux/hooks";
import {setIsAuth, setUserToken} from "./redux/slices/user_slice";
import CustomDispatch from "./redux/CustomDispatch";

export const devUrl = process.env.REACT_APP_DEV_URL
export const prodUrl = process.env.REACT_APP_PROD_URL
export const adminDevUrl = process.env.REACT_APP_DEV_ADMIN_API_URL
export const adminProdUrl = process.env.REACT_APP_PROD_ADMIN_API_URL












export async function getFetcher(token: any, url: any, isAdmin: boolean) {
    try {
        const apiUrl = isAdmin ? adminDevUrl : devUrl;
        const request = await fetch(`${apiUrl}${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        const response = await request.json();

        if (request.ok) {
            return Promise.resolve(response);
        } else if (request.status === 401) {
            return Promise.reject('Unauthorized');
        } else {
            return Promise.reject(`Error: ${request.status}`);
        }
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
}


export async function postFettcher(token: string, url: any, body: any) {
    try {
        const request = await fetch(`${prodUrl}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            body: JSON.stringify(body)
        })
        const response = await request.json()
        if (request.status === 401) {
            return Promise.reject('Unauthorized')
        }
        if (request.ok) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response.data)
        }

    } catch (error) {
        console.log(error)
        return Promise.reject(error)
    }

}

export async function putFettcher(token: string, url: any, body: any) {
    try {
        const request = await fetch(`${adminDevUrl}${url}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                "accept": "application/json",

            },
            body: JSON.stringify(body)
        })
        const response = await request.json()
        if (request.status === 401) {
            return Promise.reject('Unauthorized')
        }
        if (request.ok) {
            return Promise.resolve(response)
        } else {
            return response
        }
    } catch (error) {
        console.log(error)
        return error
    }

}
export async function deleteFettcher(token: string, url: any, id: any) {
    try {
        const request = await fetch(`${adminDevUrl}${url}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
        })
        const response = await request.json()
        if (request.status === 401) {
            return Promise.reject('Unauthorized')
        }
        if (request.ok) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response.data)
        }
    } catch (error) {
        console.log(error)
        return Promise.reject(error)
    }

}












