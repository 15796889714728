import {Card, Checkbox, DatePicker, DatePickerProps, Form, Input, Layout, Select, Table} from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  Bank,
  Bezier,
  Book,
  BookSquare,
  Category,
  Edit,
  House,
  Setting2,
  Trash,
  User,
  Location,
  Personalcard,
  Eye,
  Direct,
  MessageFavorite,
  TicketDiscount,
} from "iconsax-react";
import { Button } from "src/components/ui/button";
import * as XLSX from "xlsx";

import AdminLayout from "src/Layouts/Main";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import { Label } from "src/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import moment from "moment";
import CustomDatePicker from "../../components/ui/CustomDatePicker";
import {Dayjs} from "dayjs";
import {CalendarIcon} from "lucide-react";
import {format, isBefore} from "date-fns";
import {Calendar} from "../../components/ui/calendar";
import {getFetcher} from "../../lib/API";
import {setIsAuth, setUserToken} from "../../redux/slices/user_slice";

const { Content } = Layout;

export default function EditDiscountCode() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
    const [date, setDate] = React.useState<Date | undefined>(new Date());

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [form,setForm] = useState({
    code:"",
    percentage:"",
    expired:date,
    max_times:"",
      active:0,
      id:"",

  })



      const location = useLocation();
      const discountCode = location.state


  useEffect(() => {
    console.log(location)
    console.log(discountCode);
    setForm({
        code:discountCode.code,
        percentage:discountCode.percentage,
        max_times:discountCode.max_times,
        active:discountCode.is_active,
        id:discountCode.id,
        expired:discountCode.expiration,
    })
    console.log(form);

    setDate(new Date(discountCode.expiration));

    setLoading(false);


    }, []);



  const [isSending, setIsSending] = useState(false);








  useEffect(() => {
   window.document.title = "تعديل كود الخصم";


  }, []);







  async function editDiscountCode() {

    if (date && isBefore(date, new Date())) {
      toast.error("لا يمكنك انشاء كود ليوم سابق");
      return;

    }

    try {
      setIsSending(true);
        const request = await fetch(
        `${process.env.REACT_APP_DEV_ADMIN_API_URL}discounts/update-discount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
              "Accept":"application/json"
          },
          body: JSON.stringify({
            code: form.code,
            max_times: form.max_times,
              // @ts-ignore

            expired: format(date, "yyyy-MM-dd"),
            id: form.id,
            active:form.active == 1 ? "true" : "false",

            percentage: form.percentage,


          }),
        }
      );
      const data = await request.json();
      console.log(data);
      if (request.status == 200) {
        setIsSending(false);
        toast.success("تم تحديث الكود بنجاح");
        setTimeout(() => {
          navigate("/admin/marketing/discount-codes");
        }, 2000);

      } else {
        toast.error(data.message);
      }
    } catch (error) {
      setIsSending(false);
      toast.error("حدث خطأ أثناء تعديل الكود");
    }
  }



  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{ direction: "rtl" }}>
            <Form

                onFinish={editDiscountCode }
            >
            <div className="mx-6 mt-6">

              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      <div
                        style={{ background: "#f9f9f9" }}
                        className={" p-2 rounded-md"}
                      >
                        <TicketDiscount
                          size="48"
                          color="#2dacf4"
                          variant="Bulk"
                        />
                      </div>

                      <h1 className="text-xl mr-2">
                        تعديل كود الخصم

                      </h1>
                    </div>
                    {/*<Button*/}
                    {/*  onClick={() => {*/}
                    {/*    setOpen(true);*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  أنشاء عرض جديد*/}
                    {/*</Button>*/}
                    <Form.Item>
                      <Button
                          type="submit"



                      >
                        حفظ
                      </Button>
                    </Form.Item>
                  </div>
                </CardHeader>
              </Card>
            </div>


            <div className="mx-6 mt-6">
              <Card>


                <CardContent>




                  <Form.Item
                      label="الكود"
                      name="code"
                      initialValue={form.code}


                      rules={[
                        {
                          required: true,
                          message: "الرجاء ادخال الكود",
                        },
                      ]}
                  >
                    <Input
                        value={form.code}

                        onChange={(e) => setForm({...form,code:e.target.value})}
                    />
                  </Form.Item>

                  <Form.Item
                      label="النسبة"
                      name="percentage"
                      initialValue={form.percentage}
                      rules={[
                        {
                          required: true,
                          message: "الرجاء ادخال النسبة",
                          validator: (_, value) => {
                            if (value > 100) {
                              return Promise.reject(
                                  new Error("النسبة يجب ان تكون اقل من 100")
                              );
                            }
                            if (value < 1) {
                              return Promise.reject(
                                  new Error("النسبة يجب ان تكون اكبر من 0")
                              );
                            }
                            if (value < 0) {
                              return Promise.reject(
                                  new Error("النسبة لا يمكن أن تكون أقل من 0")
                              );
                            }
                            return Promise.resolve();
                          }
                        },
                      ]}
                  >
                    <Input
                        value={form.percentage}
                        type="number"
                        min={1}

                        onChange={(e) => setForm({...form,percentage:e.target.value})}
                    />
                  </Form.Item>

                  <Form.Item
                        label="عدد مرات الاستخدام"
                        name="max_times"
                        initialValue={form.max_times}

                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال عدد مرات الاستخدام",
                            validator: (_, value) => {
                              if (value < 1) {
                                return Promise.reject(
                                    new Error("عدد مرات الاستخدام يجب ان يكون اكبر من 0")
                                );
                              }
                              return Promise.resolve();
                            }
                          },
                        ]}
                    >
                      <Input
                          value={form.max_times}
                          type="number"
                            min={1}
                          onChange={(e) => setForm({...form,max_times:e.target.value})}
                      />
                    </Form.Item>

                    <Form.Item
                        label="تاريخ الانتهاء"
                        name="expired"
                        initialValue={form.expired}

                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال تاريخ الانتهاء",
                          },
                        ]}
                    >


                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                              variant={"outline"}
                              className="w-[280px] justify-start text-left font-normal"
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                              mode="single"
                              selected={date}
                              onSelect={setDate}
                              initialFocus
                              className="rounded-md border"
                          />
                        </PopoverContent>
                      </Popover>
                    </Form.Item>

                  <Form.Item
                      label={"حالة كود الخصم"}
                      name={"active"}
                      initialValue={form.active}

                  >
                    <Checkbox
                        checked={form.active == 1}
                        onChange={(e) => setForm({...form,active:e.target.checked ? 1 : 0})}
                    >
                      نشط
                    </Checkbox>
                  </Form.Item>



                </CardContent>


              </Card>

            </div>
            </Form>



          </Content>

        </>
      )}
    </>
  );
}
