import { Badge, Layout } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "./ui/alert-dialog";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "./ui/dropdown-menu";
import { AvatarFallback, AvatarImage, Avatar } from "./ui/avatar";
import { setIsAuth, setUserToken } from "src/redux/slices/user_slice";
import {Drop, LampOn, NotificationBing, NotificationStatus} from "iconsax-react";
import { getFetcher } from "src/lib/API";
import { Mail } from "lucide-react";
import { Notification } from "iconsax-react";

const { Header } = Layout;


export default function DashboardHeader() {
    const [isOpen, setIsOpen] = React.useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const user_name = useAppSelector((state) => state.user.name)
    const token = useAppSelector((state) => state.user.token)
    const [notifications, setNotifications]: any = React.useState([])
    const image = useAppSelector((state) => state.user.image_url)

    useEffect(() => {
        async function fetchData() {
            const request = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}notifications?page=1`)
            console.log(request.data.data)
            setNotifications(request.data.data)
            if (request.data.status === "error") {
                toast.error(request.data.message)
            }


        }
        fetchData()
    }, [])
    function Signout() {

        navigate('/auth/admin/login')
        dispatch(setIsAuth(false))
        dispatch(setUserToken(''))



    }
    return (
        <Header className=" flex items-center justify-between   bg-white w-screen">
            <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>هل أنت متاكد من تسجيل الخروج ؟ </AlertDialogTitle>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>الغاء</AlertDialogCancel>
                        <AlertDialogAction onClick={() => Signout()}>تأكيد</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <h1 className="text-md font-semibold mr-64">

            </h1>
            <div className="flex gap-2 items-center header-icon-container">
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <Notification size="32" color="#697689" variant="Bulk"/>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuLabel>الاشعارات</DropdownMenuLabel>
                        <DropdownMenuSeparator/>
                        {notifications.slice(0, 5).map((item: any) => (
                            <DropdownMenuItem className="p-2 bg-[#F4F7FD] my-2 rounded-lg" key={item.id}>
                                <NotificationStatus className="ml-2 h-4 w-4"/>
                                <span>{item.title}</span>
                            </DropdownMenuItem>
                        ))}
                        <DropdownMenuItem>
                            <span onClick={() => navigate('/admin/notifications')}>عرض المزيد</span>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>

                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <div className="flex items-center">
                            <Avatar>
                                <AvatarImage src={image} alt="@shadcn"/>
                                <AvatarFallback>CN</AvatarFallback>
                            </Avatar>
                            <h1 className="mx-2">{user_name}</h1>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem>
                            <span onClick={() => navigate('/admin/my-profile')}>حسابي</span>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => setIsOpen(true)} className="cursor-pointer">
                            تسجيل الخروج
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>


        </Header>
    );
}

