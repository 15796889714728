import {Layout} from "antd";
import {useEffect, useState} from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {

    Personalcard, SecurityUser,
} from "iconsax-react";

import {deleteFettcher, getFetcher} from "../../../API";
import {Button} from "../../../components/ui/button";
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from "../../../components/ui/pagination";
import {Checkbox} from "../../../components/ui/checkbox";
import {postFetcher} from "../../../lib/API";
import {Label} from "../../../components/ui/label";
import {Input} from "../../../components/ui/input";

const { Content } = Layout;

export default function AddNewRole() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [permissions, setPermissions]:any = useState([])
    const [selectedPermissions, setSelectedPermissions]:any = useState([])
    async function fetchData(){
        try {
            const request = await getFetcher(token,"permissions",true)
            if (request) setLoading(false)
            setPermissions(request.permissions)



            console.log(request)
        }catch (e) {

        }
    }

    const [form, setForm] = React.useState({
        name: "",
        permissions: selectedPermissions

    })


    useEffect(() => {
        window.document.title = "الاعدادات"

        fetchData()
    }, [])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const totalItems = permissions.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const permissionsOnCurrentPage = permissions.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber:any) => {
        setCurrentPage(pageNumber);
    };
    const handleCheckboxChange = (permissionId: any) => {
        console.log(selectedPermissions)
        const permissionIndex = selectedPermissions.findIndex(
            (permission: any) => permission.id === permissionId.id
        );
        if (permissionIndex === -1) {
            setSelectedPermissions([...selectedPermissions, permissionId]);
        } else {
            setSelectedPermissions([
                ...selectedPermissions.slice(0, permissionIndex),
                ...selectedPermissions.slice(permissionIndex + 1),
            ]);
        }
    };






    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <SecurityUser variant="Bulk"
                                                              size="48" color="#475467"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>
                                                الصلاحيات
                                            </h1>
                                        </div>

                                        <div>
                                            <Button onClick={async () => {
                                                const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}roles`, {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                        "Authorization": `Bearer ${token}`,
                                                        "Accept": "application/json"
                                                    },
                                                    body: JSON.stringify({
name: form.name,
                                                        permissions: selectedPermissions

                                                    })
                                                })
                                                const data = await request.json()
                                                if (request.status === 200) {
                                                    toast.success("تم اضافة الصلاحية بنجاح")
                                                    setTimeout(() => {
                                                        navigate("/admin/users/roles")
                                                    }, 1000)
                                                }
                                                console.log(data)

                                            }
                                            }>
                                                اضافة صلاحية
                                            </Button>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div className="mx-6 mt-6">
                                <div className="my-4">
                                    <Label>
                                        اسم الصلاحية
                                    </Label>
                                    <Input onChange={(event:any) => {
                                        setForm({...form, name: event.target.value})
                                    }} ></Input>
                                </div>


                            <div className="grid grid-cols-1 gap-4">
                                {permissionsOnCurrentPage.map((permissionGroup: any, index: number) => (
                                    <Card className="p-6 gap-4" key={index}>
                                        <div className="flex items-center my-1 justify-between">
                                            <div className="flex items-center">
                                                <div
                                                    style={{background: "#f9f9f9"}}
                                                    className={" p-2 rounded-md"}
                                                >
                                                    <SecurityUser variant="Bulk" size="48" color="#475467"/>
                                                </div>
                                                <h1 className={"text-xl mr-2"}>
                                                    {permissionGroup.key}
                                                </h1>
                                            </div>

                                        </div>
                                        <CardContent className="mt-4">
                                            <div>
                                                {permissionGroup.permissions.map((permission: any) => (
                                                    <div className="items-top flex gap-2 space-x-2">
                                                        <Checkbox
                                                            value={permission.key}
                                                            id={`permission_${permission.id}`}
                                                            onCheckedChange={(event) => handleCheckboxChange(permission)}

                                                        />
                                                        <div className="grid gap-2 leading-none">
                                                            <label htmlFor="terms1" className="text-lg">
                                                                {permission.name}
                                                            </label>


                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                            <div className="my-4">
                                <Pagination>
                                    <PaginationContent>
                                        <PaginationItem>
                                            <PaginationPrevious
                                                onClick={() => handlePageChange(currentPage - 1)}

                                            />
                                        </PaginationItem>
                                        {[...Array(totalPages)].map((_, index) => (
                                            <PaginationItem key={index}>
                                                <PaginationLink
                                                    href="#"
                                                    onClick={() => handlePageChange(index + 1)}
                                                    isActive={index + 1 === currentPage}
                                                >
                                                    {index + 1}
                                                </PaginationLink>
                                            </PaginationItem>
                                        ))}
                                        <PaginationItem>
                                            <PaginationNext
                                                onClick={() => handlePageChange(currentPage + 1)}

                                            />
                                        </PaginationItem>
                                    </PaginationContent>
                                </Pagination>

                            </div>
                        </div>

                    </Content>
                </>
            )}


        </>
    )
}