import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { Bank, Book, BookSquare, Setting2, User } from "iconsax-react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {putFettcher} from "../../../API";

const { Content } = Layout;

export default function SystemSettings() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data,setData] = useState<any>()



    useEffect(() => {
        window.document.title = "أعدادات النظام"
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}settings`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                })
                const data = await response.json();
                console.log(data)
                if (response.status == 200) {
                    setLoading(false)
                    setData(data.settings)
                }
            } catch (error) {
                console.error(error)
                dispatch(setIsAuth(false))
                dispatch(setUserToken(''))
                navigate('/auth/admin/login')
            }
        }
        fetchData()
    }, [])




    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <Setting2 size="48" color="#2dacf4" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>أعدادات النظام</h1>
                                        </div>


                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div className=" mx-4 mt-4">
                            <Tabs defaultValue="translation" className="w-full">
                                <TabsList className="grid w-full grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

                                <TabsTrigger value="translation">الترجمه</TabsTrigger>
                                    <TabsTrigger value="prices">التسعير</TabsTrigger>
                                    <TabsTrigger value="contact">معلومات التواصل</TabsTrigger>
                                    <TabsTrigger value="links">روابط تحميل التطبيق</TabsTrigger>
                                </TabsList>
                                <TabsContent value="translation">
                                    <Card>
                                        <CardHeader>
                                            <h1 className="text-xl">ترجمه اعلى التقيمات </h1>
                                            
                                          
                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-1/2  ">
                                                <Label  htmlFor="current">بالأنجليزي</Label>
                                                <Input onChange={(event) => {
                                                    setData({...data,topRated:{...data.topRated,translation:[{...data.topRated.translation[0],value:event.target.value},data.topRated.translation[1]]}})

                                                }} value={data.topRated.translation[0].value} id="current"  />

                                            </div>
                                            <div className=" mx-2 w-1/2">
                                                <Label htmlFor="new">بالعربي</Label>
                                                <Input
                                                onChange={(event) => {
                                                    setData({...data,topRated:{...data.topRated,translation:[data.topRated.translation[0],{...data.topRated.translation[1],value:event.target.value}]}})


                                                }}

                                                    value={data.topRated.translation[1].value} id="current"  />
                                            </div>


                                        </CardContent>
                                        <CardFooter>
                                            <Button onClick={async () => {
                                                const request = await putFettcher(token, "settings/top_rated", {
                                                    key: "top_rated",
                                                    mainValue: "true",
                                                    valueAr: data.topRated.translation[1].value,
                                                    valueEn: data.topRated.translation[0].value

                                                })
                                                console.log(request)
                                                if (request) {
                                                    toast.success("تم تحديث البيانات بنجاح")
                                                }
                                            }}>
                                                حفظ
                                            </Button>
                                        </CardFooter>
                                        <CardHeader>
                                            <h1 className="text-xl">ترجمه  اعلى المشاهدات </h1>

                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-1/2  ">
                                                <Label  htmlFor="current">بالأنجليزي</Label>
                                                <Input onChange={(event) => {
                                                    setData({...data,topViews:{...data.topViews,translation:[{...data.topViews.translation[0],value:event.target.value},data.topViews.translation[1]]}})


                                                }} value={data.topViews.translation[0].value} id="current"  />
                                              
                                            </div>
                                            <div className=" mx-2 w-1/2">
                                                <Label htmlFor="new">بالعربي</Label>
                                                <Input onChange={(event) => {
                                                    setData({...data,topViews:{...data.topViews,translation:[data.topViews.translation[0],{...data.topViews.translation[1],value:event.target.value}]}})



                                                }} value={data.topViews.translation[1]?.value} id="current"  />

                                            </div>
                                            
                                            
                                        </CardContent>
                                        <CardFooter>
                                            <Button onClick={async () => {
                                                const request = await putFettcher(token, "settings/top_views", {
                                                    key: "top_views",
                                                    mainValue: "true",
                                                    valueAr: data.topViews.translation[1].value,
                                                    valueEn: data.topViews.translation[0].value

                                                })
                                                console.log(request)
                                                if (request) {
                                                    toast.success("تم تحديث البيانات بنجاح")
                                                }
                                            }}>
                                                حفظ
                                            </Button>
                                        </CardFooter>
                                        <CardHeader>
                                            <h1 className="text-xl">ترجمه الحجز بالساعة </h1>
                                          
                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-1/2  ">
                                                <Label  htmlFor="current">بالأنجليزي</Label>
                                                <Input onChange={(event)=> {
                                                    setData({...data,hourlyRate:{...data.hourlyRate,translation:[{...data.hourlyRate.translation[0],value:event.target.value},data.hourlyRate.translation[1]]}})

                                                }} value={data.hourlyRate.translation[0].value} id="current"  />


                                            </div>
                                            <div className=" mx-2 w-1/2">
                                                <Label htmlFor="new">بالعربي</Label>
                                                <Input onChange={(event) => {
                                                    setData({...data,hourlyRate:{...data.hourlyRate,translation:[data.hourlyRate.translation[0],{...data.hourlyRate.translation[1],value:event.target.value}]}})

                                                }} value={data.hourlyRate.translation[1].value} id="current"  />

                                            </div>
                                            
                                            
                                        </CardContent>
                                        <CardFooter>
                                            <Button onClick={async () => {
                                                const request = await putFettcher(token, "settings/houry_rate", {
                                                    key: "houry_rate",
                                                    mainValue: "true",
                                                    valueAr: data.hourlyRate.translation[1].value,
                                                    valueEn: data.hourlyRate.translation[0].value

                                                })
                                                console.log(request)
                                                if (request) {
                                                    toast.success("تم تحديث البيانات بنجاح")
                                                }
                                            }}>
                                                حفظ
                                            </Button>
                                        </CardFooter>

                                    </Card>
                                    
                                </TabsContent>
                                <TabsContent value="prices">
                                <Card>
                                        <CardHeader>
                                            <h1 className="text-xl">التسعير</h1>
                                            
                                          
                                        </CardHeader>
                                    <CardContent
                                        className="space-y-2 gap-2 grid grid-cols-2 justify-between w-full items-baseline">
                                        <div >
                                            <Label htmlFor="current">الخدمة</Label>
                                            <Input onChange={(event) => {
                                                setData({
                                                    ...data,
                                                    serviceRate: {...data.serviceRate, value: event.target.value}
                                                })

                                            }} type="number" value={data.serviceRate.value} id="current"/>

                                        </div>
                                        <div>
                                            <Label htmlFor="new">الضريبة</Label>
                                            <Input onChange={(event) => {
                                                setData({
                                                    ...data,
                                                    taxRate: {...data.taxRate, value: event.target.value}
                                                })
                                            }} type="number" value={data.taxRate.value} id="new"/>
                                        </div>

                                        <div >
                                            <Label htmlFor="current">
                                                نسبة خصم رابط الاحالة
                                            </Label>
                                            <Input onChange={(event) => {
                                                setData({
                                                    ...data,
                                                    universialPercentage: {...data.universialPercentage, value: event.target.value}
                                                })

                                            }} type="number" value={data.universialPercentage.value} id="current"/>

                                        </div>
                                        <div>
                                            <Label htmlFor="new">
                                                نسبة خصم العمولة من الوحدات السكنية

                                            </Label>
                                            <Input onChange={(event) => {
                                                setData({
                                                    ...data,
                                                    commercialPercentage: {...data.commercialPercentage, value: event.target.value}
                                                })
                                            }} type="number" value={data.commercialPercentage.value} id="new"/>
                                        </div>


                                    </CardContent>
                                    <CardFooter>
                                        <Button onClick={async () => {
                                            if (data.serviceRate.value == "" || data.taxRate.value == "") {
                                                toast.error("الرجاء ملء جميع الحقول")
                                                return
                                            }

                                            const request = await putFettcher(token, "settings/service_rate", {
                                                key: "service_rate",
                                                mainValue: "true",
                                                valueAr: data.serviceRate.value,
                                                valueEn: data.serviceRate.value

                                            })
                                            console.log(request)
                                            if (request) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                            const request2 = await putFettcher(token, "settings/tax_rate", {
                                                key: "tax_rate",
                                                mainValue: "true",
                                                valueAr: data.taxRate.value,
                                                valueEn: data.taxRate.value

                                            })
                                            const request3 = await putFettcher(token, "settings/universal_percentage", {
                                                key: "universal_percentage",
                                                mainValue: "true",
                                                valueAr: data.universialPercentage.value,
                                                valueEn: data.universialPercentage.value

                                            })

                                            const request4 = await putFettcher(token, "settings/commercial_percentage", {
                                                key: "commercial_percentage",
                                                mainValue: "true",
                                                valueAr: data.commercialPercentage.value,
                                                valueEn: data.commercialPercentage.value

                                            })
                                            if (request2 && request3 && request4 && request) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                        }}>
                                            حفظ
                                        </Button>
                                    </CardFooter>


                                    </Card>
                                </TabsContent>
                                <TabsContent value="contact">
                                <Card>
                                        <CardHeader>
                                            <h1 className="text-xl">معلومات التواصل</h1>
                                            
                                          
                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-1/2  ">
                                                <Label  htmlFor="current">رقم الواتس اب</Label>
                                                <Input onChange={(event)=>{
                                                    setData({...data,contactWhats:{...data.contactWhats,value:event.target.value}})


                                                }} value={data.contactWhats.value} id="current"  />
                                              
                                            </div>
                                            <div className=" mx-2 w-1/2">
                                                <Label htmlFor="new">رقم الجوال</Label>
                                                <Input onChange={(event)=>{
                                                    setData({...data,contactPhone:{...data.contactPhone,value:event.target.value}})


                                                }} value={data.contactPhone.value} id="new"  />
                                            </div>
                                            
                                            
                                            
                                        </CardContent>
                                        
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-full  ">
                                                <Label  htmlFor="current">البريد الالكتروني</Label>
                                                <Input onChange={(event)=>{
                                                    setData({...data,contactEmail:{...data.contactEmail,value:event.target.value}})



                                                }} value={data.contactEmail.value} id="current" type="email" />
                                              
                                            </div>
                                           
                                            
                                            
                                            
                                        </CardContent>
                                    <CardFooter>
                                        <Button onClick={async () => {
                                            if (data.contactEmail.value == "" || data.contactPhone.value == "" || data.contactWhats.value == "") {
                                                toast.error("الرجاء ملء جميع الحقول")
                                                return
                                            }
                                            const request = await putFettcher(token, "settings/contact_email", {
                                                key: "contact_email",
                                                mainValue: "true",
                                                valueAr: data.contactEmail.value,
                                                valueEn: data.contactEmail.value

                                            })

                                            console.log(request)
                                            if (request) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                            const request2 = await putFettcher(token, "settings/contact_phone", {
                                                key: "contact_phone",
                                                mainValue: "true",
                                                valueAr: data.contactPhone.value,
                                                valueEn: data.contactPhone.value

                                            })
                                            if (request2) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                            const request3 = await putFettcher(token, "settings/contact_whats", {
                                                key: "contact_whats",
                                                mainValue: "true",
                                                valueAr: data.contactWhats.value,
                                                valueEn: data.contactWhats.value

                                            })
                                            if (request3) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                        }}>
                                            حفظ
                                        </Button>
                                    </CardFooter>
                                      
                                        
                                    </Card>
                                </TabsContent>
                                <TabsContent value="links">
                                <Card>
                                        <CardHeader>
                                            <h1 className="text-xl">الروابط</h1>
                                            
                                          
                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-1/2  ">
                                                <Label  htmlFor="current">رابط التحميل على الايفون</Label>
                                                <Input onChange={(event)=>{
                                                    setData({...data,iosUrl:{...data.iosUrl,value:event.target.value}})


                                                }} value={
                                                    data.iosUrl.value
                                                } id="current"  />
                                              
                                            </div>
                                            <div className=" mx-2 w-1/2">
                                                <Label htmlFor="new">رابط التحميل على الاندرويد</Label>
                                                <Input onChange={(event)=>{
                                                    setData({...data,androidUrl:{...data.androidUrl,value:event.target.value}})


                                                }} value={data.androidUrl.value} id="new"  />
                                            </div>
                                            
                                            
                                            
                                        </CardContent>
                                    <CardFooter>
                                        <Button onClick={async () => {
                                            if (data.iosUrl.value == "" || data.androidUrl.value == "") {
                                                toast.error("الرجاء ملء جميع الحقول")
                                                return
                                            }
                                            const request = await putFettcher(token, "settings/ios_url", {
                                                key: "ios_url",
                                                mainValue: "true",
                                                valueAr: data.iosUrl.value,
                                                valueEn: data.iosUrl.value

                                            })
                                            if (request) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                            const request2 = await putFettcher(token, "settings/android_url", {
                                                key: "android_url",
                                                mainValue: "true",
                                                valueAr: data.androidUrl.value,
                                                valueEn: data.androidUrl.value

                                            })
                                            if (request2) {
                                                toast.success("تم تحديث البيانات بنجاح")
                                            }
                                        }}>
                                            حفظ
                                        </Button>
                                    </CardFooter>




                                </Card>
                                </TabsContent>
                                
                            </Tabs>
                        </div>



                    </Content>
                </>
            )}




        </>
    )
}