import { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import About from "src/components/Home/About";
import Destination from "src/components/Home/Destination";
import HowItWork from "src/components/Home/HowItWork";
import Subscribe from "src/components/Home/Subscribe";
import 'aos/dist/aos.css';
import AOS from 'aos';
import Header from "src/components/Home/Header";
import Footer from "src/components/Home/Footer";
import {LOCALE_KEY} from "../index";
import useGetHomeData from "../hooks/home";


export default function Home() {
    const { i18n } = useTranslation();

    const [isEthereumAvailable, setIsEthereumAvailable] = useState(false);
    const { settings } = useGetHomeData(i18n.language);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsEthereumAvailable(true);
        }
    }, [typeof window]);

    useEffect(() => {
        if (isEthereumAvailable) {
            AOS.init({
                offset: 150,
            });
        }
    }, [isEthereumAvailable]);

    useEffect(() => {
        window.document.title =
            i18n.language === 'ar'
                ? 'أفضل منصة لحجز ارقي الشقق والشاليهات والفلل في المملكة العربية السعودية'
                : 'The best platform for booking the finest debts, chalets and villas in the Kingdom of Saudi Arabia';

    }



    , []);
    useEffect(() => {
        const defaultLocale = 'ar';
        const selectedLocale = localStorage.getItem(LOCALE_KEY);
        i18n.changeLanguage(defaultLocale);

    }, []);


    return (
        <div className={
            i18n.language === 'ar' ? 'style-ar' : 'style-en'
        } >



            <Header androidUrl={settings?.androidUrl?.value || '/'} iosUrl={settings?.iosUrl?.value || '/'} />

            <About />
            <Destination />
            <HowItWork />
            {/* <Testimonials testimonials={testimonials || []} />
      <TrustedCompanies trustedCompanies={trustedCompanies || []} /> */}
            <Subscribe />
            <Footer androidUrl={settings?.androidUrl?.value || '/'} iosUrl={settings?.iosUrl?.value || '/'} />
        </div>
    );
}