import {Card, Checkbox, DatePicker, DatePickerProps, Form, Input, Layout, Select, Table} from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
  Bank,
  Bezier,
  Book,
  BookSquare,
  Category,
  Edit,
  House,
  Setting2,
  Trash,
  User,
  Location,
  Personalcard,
  Eye,
  Direct,
  MessageFavorite,
  TicketDiscount,
} from "iconsax-react";
import { Button } from "src/components/ui/button";
import * as XLSX from "xlsx";

import AdminLayout from "src/Layouts/Main";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import { Label } from "src/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import moment from "moment";
import CustomDatePicker from "../../components/ui/CustomDatePicker";
import {Dayjs} from "dayjs";
import {CalendarIcon} from "lucide-react";
import {format, isBefore} from "date-fns";
import {Calendar} from "../../components/ui/calendar";
import {getFetcher} from "../../lib/API";
import {setIsAuth, setUserToken} from "../../redux/slices/user_slice";

const { Content } = Layout;

export default function CreatePromotion() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
    const [date, setDate] = React.useState<Date | undefined>(new Date());

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [form,setForm] = useState({
    code:"",
    percentage:"",
    expired:date,
    max_times:"",
      active:0,
      id:"",
    property_id:"",
    type:"",
    amount:""
  })

  // useEffect(() => {
  //
  //   async function fetchData() {
  //     setLoading(true);
  //     try {
  //       const propertiesResponse = await getFetcher(
  //           token,`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties`,
  //
  //       );
  //
  //       setProperties(propertiesResponse.properties);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       dispatch(setIsAuth(false));
  //       dispatch(setUserToken(''));
  //       navigate('/auth/admin/login');
  //     }
  //   }
  //
  //   fetchData();
  // }, []);



  const [isSending, setIsSending] = useState(false);








    async function fetchData(isRefresh = false) {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_DEV_ADMIN_API_URL}promotions/list-promotion`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
      );

      const propertiesResponse = await getFetcher(
          token,`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties`,

      );

      const data = await response.json();
      console.log(data);
      if (response.status == 200) {
        setData(data.promotions);
        setProperties(propertiesResponse.properties);

        setLoading(false);
      } else {
      }
    } catch (error) {
      // dispatch(setIsAuth(false))
      // dispatch(setUserToken(''))
      // navigate('/auth/admin/login')
    }
  }
  useEffect(() => {
    window.document.title = "العروض";

    fetchData();
  }, []);


  async function createDiscountCode() {
    try {

        if (date == new Date()) {
            toast.error("لا يمكنك انشاء كود لنفس اليوم");
            return;
        }

        if (date && isBefore(date, new Date())) {
            toast.error("لا يمكنك انشاء كود ليوم سابق");
            return;

        }

       setIsSending(true)
        const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}promotions/add-promotion`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Accept":"application/json"
            },
            body:JSON.stringify({
                code:form.code,
                max_times:form.max_times,
                // @ts-ignore

                expired:format(date, "yyyy-MM-dd"),
                property_id:form.property_id,
                type:form.type,
                amount:form.amount,
              active:form.active == 1 ? "true" : "false",

            })
        })

        const data = await request.json();
        console.log(data)
        if (request.status == 200) {
          setIsSending(false)
          setOpen(false);
            toast.success("تم انشاء الكود بنجاح");
            setTimeout(() => {
              navigate("/admin/marketing/promotions");

            }, 2000);

        } else {
          setOpen(false);
          toast.error(data.message);
        }
    } catch (error) {
        
    }
  }




  async function editDiscountCode() {
    try {
      setIsSending(true);
        const request = await fetch(
        `${process.env.REACT_APP_DEV_ADMIN_API_URL}promotions/update-promotion`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
              "Accept":"application/json"
          },
          body: JSON.stringify({
            code: form.code,
            percentage: form.percentage,
            max_times: form.max_times,
              // @ts-ignore

            expired: format(date, "yyyy-MM-dd"),
            id: form.id,
            active:form.active == 1 ? "true" : "false",
            property_id: form.property_id,
            type: form.type,
            amount: form.amount,


          }),
        }
      );
      const data = await request.json();
      console.log(data);
      if (request.status == 200) {
        setIsSending(false);
        setOpen(false);
        toast.success("تم تعديل الكود بنجاح");
        await fetchData(true);
      } else {
        setOpen(false);
        toast.error("حدث خطأ أثناء تعديل الكود");
      }
    } catch (error) {
      setIsSending(false);
      toast.error("حدث خطأ أثناء تعديل الكود");
    }
  }



  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{ direction: "rtl" }}>
            <Form

                onFinish={isEditing ? editDiscountCode : createDiscountCode}
            >
            <div className="mx-6 mt-6">

              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      <div
                        style={{ background: "#f9f9f9" }}
                        className={" p-2 rounded-md"}
                      >
                        <TicketDiscount
                          size="48"
                          color="#2dacf4"
                          variant="Bulk"
                        />
                      </div>

                      <h1 className="text-xl mr-2">
                        انشاء عرض جديد
                      </h1>
                    </div>
                    {/*<Button*/}
                    {/*  onClick={() => {*/}
                    {/*    setOpen(true);*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  أنشاء عرض جديد*/}
                    {/*</Button>*/}
                    <Form.Item>
                      <Button
                          type="submit"



                      >
                        {isEditing ? "تعديل" : "انشاء"}
                      </Button>
                    </Form.Item>
                  </div>
                </CardHeader>
              </Card>
            </div>


            <div className="mx-6 mt-6">
              <Card>


                <CardContent>


                    <Form.Item
                        label="النوع"
                        name="type"
                        initialValue={form.type}
                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال النوع",
                          },
                        ]}
                    >
                      <Select
                          value={form.type}
                          onChange={(e) => setForm({...form,type:e})}

                          showSearch={true}


                      >
                        <Select.Option value="fixed_amount">
                          مبلغ ثابت
                        </Select.Option>
                        <Select.Option value="percentage">
                          نسبة
                        </Select.Option>
                      </Select>

                    </Form.Item>

                    <Form.Item
                        label="الوحدة السكنية"
                        name="property_id"
                        initialValue={form.property_id}
                        rules={[
                          {
                            required: true,
                            message: "الرجاء أختيار الوحدة السكنية",
                          },
                        ]}
                    >
                      <Select
                          value={form.property_id}
                          onChange={(e) => setForm({...form,property_id:e})}



                            showSearch={true}
                          onSearch={(e) => {
                            if (e.length > 0) {
                              setProperties(
                                  properties.filter((property: any) =>
                                      property.title.toLowerCase().includes(e.toLowerCase())
                                  )
                              );
                            } else {
                              setProperties(properties);
                            }}}
                          virtual={false}
                          filterOption={false}
                          optionFilterProp={"label"}

                      >
                        {properties.map((property: any) => (
                            <Select.Option value={property.id}>
                              {property.title}
                            </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                        label="الكود"
                        name="code"
                        initialValue={form.code}


                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال الكود",
                          },
                        ]}
                    >
                      <Input
                          value={form.code}

                          onChange={(e) => setForm({...form,code:e.target.value})}
                      />
                    </Form.Item>

                    {form.type == "fixed_amount" ? (
                        <Form.Item
                            label="المبلغ"
                            name="amount"
                            initialValue={form.amount}
                            rules={[
                              {
                                required: true,
                                message: "الرجاء ادخال المبلغ",
                                validator: (_, value) => {
                                    if (value < 1) {
                                        return Promise.reject(
                                            new Error("المبلغ يجب ان يكون اكبر من 0")
                                        );
                                    }


                                    return Promise.resolve();

                                  if (value < 0) {
                                    return Promise.reject(
                                        new Error("النسبة لا يمكن أن تكون أقل من 0")
                                    );
                                  }
                                    }

                              },


                            ]}

                        >
                          <Input
                              value={form.amount}
                              type="number"
                              min={1}
                              onChange={(e) => setForm({...form,amount:e.target.value})}
                          />
                        </Form.Item>
                    ) :  <Form.Item
                        label="النسبة"
                        name="percentage"
                        initialValue={form.amount}
                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال النسبة",
                            validator: (_, value) => {
                              if (value > 100) {
                                return Promise.reject(
                                    new Error("النسبة يجب ان تكون اقل من 100")
                                );
                              }
                              if (value < 1) {
                                return Promise.reject(
                                    new Error("النسبة يجب ان تكون اكبر من 0")
                                );
                              }
                              if (value < 0) {
                                return Promise.reject(
                                    new Error("النسبة لا يمكن أن تكون أقل من 0")
                                );
                              }
                              return Promise.resolve();
                            }
                          },
                        ]}
                    >
                      <Input
                          value={form.amount}
                          type="number"
                            min={1}
                          onChange={(e) => setForm({...form,amount:e.target.value})}
                      />
                    </Form.Item>

                    }

                    <Form.Item
                        label="عدد مرات الاستخدام"
                        name="max_times"
                        initialValue={form.max_times}
                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال عدد مرات الاستخدام",
                            validator: (_, value) => {
                              if (value < 1) {
                                return Promise.reject(
                                    new Error("عدد مرات الاستخدام يجب ان يكون اكبر من 0")
                                );
                              }
                              return Promise.resolve();
                            }
                          },
                        ]}
                    >
                      <Input
                          value={form.max_times}
                          type="number"
                          min={1}
                          onChange={(e) => setForm({...form,max_times:e.target.value})}
                      />
                    </Form.Item>

                    <Form.Item
                        label="تاريخ الانتهاء"
                        name="expired"
                        initialValue={form.expired}
                        rules={[
                          {
                            required: true,
                            message: "الرجاء ادخال تاريخ الانتهاء",
                          },
                        ]}
                    >


                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                              variant={"outline"}
                              className="w-[280px] justify-start text-left font-normal"
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                              mode="single"
                              selected={date}
                              onSelect={setDate}
                              initialFocus

                              className="rounded-md border"
                          />
                        </PopoverContent>
                      </Popover>
                    </Form.Item>
                  <Form.Item
                      label={"حالة كود الخصم"}
                      name={"active"}
                      initialValue={form.active}

                  >
                    <Checkbox
                        checked={form.active == 1}
                        onChange={(e) => setForm({...form,active:e.target.checked ? 1 : 0})}
                    >
                      نشط
                    </Checkbox>
                  </Form.Item>





                </CardContent>


              </Card>

            </div>
            </Form>



          </Content>

        </>
      )}
    </>
  );
}
