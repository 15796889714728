import {Card, Checkbox, DatePicker, DatePickerProps, Form, Input, Layout, Select, Table} from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
  Bank,
  Bezier,
  Book,
  BookSquare,
  Category,
  Edit,
  House,
  Setting2,
  Trash,
  User,
  Location,
  Personalcard,
  Eye,
  Direct,
  MessageFavorite,
  TicketDiscount, ArrowRight2, ArrowLeft2,
} from "iconsax-react";
import { Button } from "src/components/ui/button";
import * as XLSX from "xlsx";

import AdminLayout from "src/Layouts/Main";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import { Label } from "src/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import moment from "moment";
import CustomDatePicker from "../../components/ui/CustomDatePicker";
import {Dayjs} from "dayjs";
import {CalendarIcon} from "lucide-react";
import {format} from "date-fns";
import {Calendar} from "../../components/ui/calendar";
import {getFetcher} from "../../lib/API";
import {setIsAuth, setUserToken} from "../../redux/slices/user_slice";

const { Content } = Layout;

export default function Promotions() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
    const [date, setDate] = React.useState<Date | undefined>(new Date());

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [form,setForm] = useState({
    code:"",
    percentage:"",
    expired:date,
    max_times:"",
      active:0,
      id:"",
    property_id:"",
    type:"",
    amount:""
  })

  // useEffect(() => {
  //
  //   async function fetchData() {
  //     setLoading(true);
  //     try {
  //       const propertiesResponse = await getFetcher(
  //           token,`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties`,
  //
  //       );
  //
  //       setProperties(propertiesResponse.properties);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       dispatch(setIsAuth(false));
  //       dispatch(setUserToken(''));
  //       navigate('/auth/admin/login');
  //     }
  //   }
  //
  //   fetchData();
  // }, []);



  const [isSending, setIsSending] = useState(false);


  function exportToExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Subscription" + ".xlsx");
  }


  const columns = [
    {
        title: "الرقم",
        dataIndex: "id",
        key: "id",
    },
    {
      title: "الكود",
      dataIndex: "code",
      key: "code",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "النوع",
      dataIndex: "type",
      key: "type",
      render: (text: any) => <>
          {text == "fixed_amount" ? "مبلغ ثابت" : "نسبة"}
          </>,
    },

    {
      title: "المبلغ او النسبة",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "الوحدة السكنية",
      dataIndex: ["property", "title"],
        key: "property",
    },
    {
      title:"رقم الوحدة السكنية",
        dataIndex:["property","id"],
        key:"property_id"
    },
    {
      title: "عدد مرات الاستخدام",
      dataIndex: "usage_limit",
      key: "usage_limit",
    },
    {
      title: "تاريخ الانتهاء",
      dataIndex: "expiration",
      key: "expiration",
    },
    {
      title: "الحالة",
      dataIndex: "is_active",
      key: "is_active",
      render:(text:any,record:any) => (
          <div>
            {record.is_active == 1 ? "نشط" : "غير نشط"}
          </div>
      )
    },
    {
      title: "العمليات",
      key: "action",
      render: (text: any, record: any) => (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              navigate(`/admin/marketing/promotion/edit/${record.id}`, {
                state: {
                  id: record.id,
                    code: record.code,
                    percentage: record.percentage,
                    expired: record.expiration,
                    max_times: record.usage_limit,
                    active: record.is_active,
                    property_id: record?.property?.id,
                    type: record.type,
                    amount: record.amount,


                },
                });
            }
            }
          >
            <Edit  size={18} />
          </Button>
          <Button
              className={"bg-red-500"}
            onClick={() => {
                setForm({
                    code:record.code,
                    percentage:record.percentage,
                    expired:record.expiration,
                    max_times:record.max_times,
                    id:record.id,
                    active:record.is_active,
                    property_id: record?.property?.id,
                    type:record.type,
                    amount:record.amount
                });

              setIsDeleting(true);
            }}
          >
            <Trash variant={"Bold"} color={"white"} size={18} />
          </Button>
        </div>
      ),
    },


  ]

  const itemRender = (_: any, type: string, originalElement: any) => {
    console.log(type, "a");
    if (type === "prev") {
      return <ArrowRight2 size="32" color="#333" />;
    }
    if (type === "next") {
      return <ArrowLeft2 size="32" color="#333" />;
    }
    return originalElement;
  };




  async function fetchData(isRefresh = false) {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_DEV_ADMIN_API_URL}promotions/list-promotion`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
      );


      const data = await response.json();
      console.log(data);
      if (response.status == 200) {
        setData(data.promotions);

        setLoading(false);
      } else {
      }
    } catch (error) {
      // dispatch(setIsAuth(false))
      // dispatch(setUserToken(''))
      // navigate('/auth/admin/login')
    }
  }
  useEffect(() => {
    window.document.title = "العروض";

    fetchData();
  }, []);





  async function deleteDiscountCode(id: string) {
    try {
      setIsDeleting(true);
      const request = await fetch(
        `${process.env.REACT_APP_DEV_ADMIN_API_URL}promotions/delete-promotion`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
            body: JSON.stringify({ id: id }),
        }
      );
      const data = await request.json();
      console.log(data);
      if (request.status == 200) {
        setIsDeleting(false);
        toast.success("تم حذف الكود بنجاح");
        await fetchData(true);
      } else {
        setIsDeleting(false);
        toast.error("حدث خطأ أثناء حذف الكود");
      }
    } catch (error) {
      setIsDeleting(false);
      toast.error("حدث خطأ أثناء حذف الكود");
    }
  }





  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{ direction: "rtl" }}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      <div
                        style={{ background: "#f9f9f9" }}
                        className={" p-2 rounded-md"}
                      >
                        <TicketDiscount
                          size="48"
                          color="#2dacf4"
                          variant="Bulk"
                        />
                      </div>

                      <h1 className="text-xl mr-2">
                        العروض والتخفيضات
                      </h1>
                    </div>
                    <Button
                      onClick={() => {
                        navigate("/admin/marketing/promotion/create")
                      }}
                    >
                      أنشاء عرض جديد
                    </Button>
                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="mx-6 mt-6">
              <Table
                  columns={columns}
                  dataSource={data}
                  showHeader={true}
                  bordered={true}
                  sortDirections={["ascend", "descend"]}
                  showSorterTooltip={true}


                  loading={loading}
                  pagination={{
                    showQuickJumper: true,
                    showTotal: (total) => `Total ${total} items`,

                    position: ["bottomCenter"],
                    size: "default",
                    itemRender: itemRender
                  }}

              />
            </div>


            <AlertDialog open={isDeleting}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>هل أنت متأكد من الحذف؟</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={() => setIsDeleting(false)}>
                    الغاء
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={() => deleteDiscountCode(form.id)}>تأكيد الحذف</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </Content>

        </>
      )}
    </>
  );
}
