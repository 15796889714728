import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  androidUrl: string;
  iosUrl: string;
};

export default function Footer({ androidUrl, iosUrl }: Props) {
  const { t: translate, i18n } = useTranslation();

  return (
    <div data-aos="fade-up" className="relative">
      <div className="m-home mt-4 px-0 pb-8 lg:mt-16 lg:px-20">
        <div className="block justify-around lg:flex">
          <div className="flex items-center">
            <img className="w-60" src="/assets/imgs/logo-white-2.svg" alt="logo-white" />
          </div>
          {/*  */}
          <div className="mt-4">
            <ul>
              <li className="inline-block">
                <Link to="/">
                  <img src="/assets/icons/facebook.png" />
                </Link>
              </li>
              <li className="mx-2 inline-block">
                <Link to="/">
                  <img src="/assets/icons/instagram.png" />
                </Link>
              </li>
              <li className="inline-block">
                <Link to="/">
                  <img src="/assets/icons/twitter.png" />
                </Link>
              </li>
            </ul>
            <div className="mx-0 mt-4 lg:mx-5">
              <p className="text-gray-500">{translate('common:home.discover-app')}</p>
              <div className="mt-3 flex items-center justify-start">
                <Button
                  href={androidUrl}
                  className="mx-1 flex h-10 w-full items-center justify-start rounded-3xl !border-black bg-black px-6 !text-white">
                  <img
                    className={`${i18n.language === 'ar' ? 'ml-1' : 'mr-1'} w-4`}
                    src="/assets/icons/android.svg"
                    alt="android-icon"
                  />
                  <div className={`mx-2 -mt-[10px] ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <span className="text-[10px] text-gray-300">{translate('common:home.get-it-on')}</span>
                    <p className="text-xs leading-3">{translate('common:home.google-play')}</p>
                  </div>
                </Button>
                <Button
                  href={iosUrl}
                  className="mx-1 flex h-10 w-full items-center justify-start rounded-3xl !border-black bg-black px-6 !text-white">
                  <img
                    className={`${i18n.language === 'ar' ? 'ml-1' : 'mr-1'} w-4`}
                    src="/assets/icons/apple.svg"
                    alt="apple-icon"
                  />
                  <div className={`mx-2 -mt-[10px] ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <span className="text-[10px] text-gray-300">{translate('common:home.available-on')}</span>
                    <p className="text-xs leading-3">{translate('common:home.app-store')}</p>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-16 text-center text-sm text-[#5E6282]">{translate('common:home.copy-rights')}</p>
      </div>
      <div className="absolute bottom-0 left-0">
        <img className="w-[28rem]" src="/assets/imgs/bg-footer.png" alt="subscribe" />
      </div>
    </div>
  );
}
