import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";
export default function About() {
  const { t: translate } = useTranslation();
  console.log(`${i18n.language}`, translate('common:price'));

  return (
    <div id="about-us" data-aos="fade-up" className="m-home mx-5 lg:mx-20">
      <div className="text-center">
        <h2 className=" font-semibold text-gray-700">{translate('common:home.about-us')}</h2>
        <p className="mx-3 my-4 max-w-none text-xl font-extrabold md:mx-auto lg:max-w-[45%] lg:text-4xl">
          {translate('common:home.about-us-desc')}
        </p>
      </div>
      <div className="mt-16 grid grid-cols-10 gap-0 sm:gap-10">
        {/*  */}
        <div className="col-span-12 lg:col-span-2">
          <div className="destination-card mb-8 rounded-3xl lg:mb-0">
            <img className="min-h-[180px] rounded-t-3xl" src="/assets/imgs/Apartment.jpg" alt="Apartment.jpg" />
            <div className="px-5 py-3 text-center">
              <h2 className="font-bold text-gray-600">{translate('common:home.categories.apartments')}</h2>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="col-span-12 lg:col-span-2">
          <div className="destination-card mb-8 rounded-3xl lg:mb-0">
            <img className="min-h-[180px] rounded-t-3xl" src="/assets/imgs/Villa.jpg" alt="Villa.jpg" />
            <div className="px-5 py-3 text-center">
              <h2 className="font-bold text-gray-600">{translate('common:home.categories.villas')}</h2>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="col-span-12 lg:col-span-2">
          <div className="destination-card mb-8 rounded-3xl lg:mb-0">
            <img className="min-h-[180px] rounded-t-3xl" src="/assets/imgs/Chalet.jpg" alt="Chalet.jpg" />
            <div className="px-5 py-3 text-center">
              <h2 className="font-bold text-gray-600">{translate('common:home.categories.chalets')}</h2>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="col-span-12 lg:col-span-2">
          <div className="destination-card mb-8 rounded-3xl lg:mb-0">
            <img className="min-h-[180px] rounded-t-3xl" src="/assets/imgs/Camp.jpg" alt="Camp.jpg" />
            <div className="px-5 py-3 text-center">
              <h2 className="font-bold text-gray-600">{translate('common:home.categories.camps')}</h2>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="col-span-12 lg:col-span-2">
          <div className="destination-card mb-8 rounded-3xl lg:mb-0">
            <img className="min-h-[180px] rounded-t-3xl" src="/assets/imgs/Farm.jpg" alt="Farm.jpg" />
            <div className="px-5 py-3 text-center">
              <h2 className="font-bold text-gray-600">{translate('common:home.categories.farms')}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
