import { Card, Layout } from "antd";
import { useEffect } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { Book, Building3, MoneyRecive, PathTool, Setting2 } from "iconsax-react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../components/ui/alert-dialog";
import { Editor } from "@tinymce/tinymce-react";

const { Content } = Layout;

export default function Settings() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()







    useEffect(() => {
        window.document.title = "الاعدادات"
        function Deley() {
            setTimeout(() => {
                setLoading(false)
            }, 500)
        }
        Deley()
    }, [])
    // @ts-ignore

   


    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <Setting2 size="48" color="#2dacf4" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>الاعدادات</h1>
                                        </div>


                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div>
                            <h1 className={"text-xl mt-4 p-6"}>الاعدادات الاساسية</h1>
                            <div className="grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">

                                <Card className={"mx-4"}>
                                    <CardHeader>
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <Setting2 variant="Bulk"
                                                    size="32" color="#475467" />
                                            </div>

                                            <Link className={"text-black text-xl"} to={'/admin/system/settings'}>
                                                أعدادات النظام
                                            </Link>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        يمكنك تعديل بيانات الحساب الخاصة بك
                                    </CardContent>
                                </Card>
                                <Card className={"mx-4"}>
                                    <CardHeader>
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <Book variant="Bulk"
                                                    size="32" color="#475467" />
                                            </div>

                                            <Link className={"text-black text-xl"} to={'/admin/pages'}>
                                                الصفحات
                                            </Link>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        يمكنك تعديل بيانات الحساب الخاصة بك
                                    </CardContent>
                                </Card>
                                <Card className={"mx-4"}>
                                    <CardHeader>
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <Building3 variant="Bulk"
                                                    size="32" color="#475467" />
                                            </div>

                                            <Link className={"text-black text-xl"} to={'/admin/system/properties'}>
                                                أعدادات الوحدات السكنية
                                            </Link>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        يمكنك تعديل بيانات الحساب الخاصة بك
                                    </CardContent>
                                </Card>
                                <Card className={"mx-4"}>
                                    <CardHeader>
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <PathTool variant="Bulk"
                                                           size="32" color="#475467" />
                                            </div>

                                            <Link className={"text-black text-xl"} to={'/admin/distance-units'}>
                                               وحدة المسافة
                                            </Link>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        يمكنك تعديل بيانات الحساب الخاصة بك
                                    </CardContent>
                                </Card>
                                <Card className={"mx-4"}>
                                    <CardHeader>
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <MoneyRecive variant="Bulk"
                                                          size="32" color="#475467" />
                                            </div>

                                            <Link className={"text-black text-xl"} to={'/admin/currencies'}>
                                               العملات
                                            </Link>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        يمكنك تعديل بيانات الحساب الخاصة بك
                                    </CardContent>
                                </Card>

                            </div>
                        </div>


                    </Content>
                </>
            )}




        </>
    )
}