import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import {SubscriptionType} from "../../types/subscription";
import client from "../../helpers/client";
import {CustomErrorType} from "../../types/error";
export default function useStoreSubscription() {
  const queryClient = useQueryClient();
  const { t: translate } = useTranslation();
  const storeSubscription = (newSubscription: SubscriptionType) =>
    client.post(`/api/admin/subscriptions`, { ...newSubscription });

  const mutation = useMutation({
    mutationFn: storeSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
    },
    onError: (error: AxiosError<CustomErrorType>) => {
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error(String(translate('common:messages.something-wrong')));
      }
    },
  });

  return {
    mutation,
  };
}
