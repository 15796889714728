import {Card, Checkbox, DatePicker, DatePickerProps, Form, Input, Layout, Table} from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
  Bank,
  Bezier,
  Book,
  BookSquare,
  Category,
  Edit,
  House,
  Setting2,
  Trash,
  User,
  Location,
  Personalcard,
  Eye,
  Direct,
  MessageFavorite,
  TicketDiscount, ArrowRight2, ArrowLeft2,
} from "iconsax-react";
import { Button } from "src/components/ui/button";
import * as XLSX from "xlsx";

import AdminLayout from "src/Layouts/Main";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import { Label } from "src/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import moment from "moment";
import CustomDatePicker from "../../components/ui/CustomDatePicker";
import {Dayjs} from "dayjs";
import {CalendarIcon} from "lucide-react";
import {format, isBefore} from "date-fns";
import {Calendar} from "../../components/ui/calendar";
import {saveAs} from "file-saver";

const { Content } = Layout;

export default function DiscountCodes() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
    const [date, setDate] = React.useState<Date | undefined>(new Date());

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [form,setForm] = useState({
    code:"",
    percentage:"",
    expired:date,
    max_times:"",
      active:0,
      id:""
  })

  const formRef = React.useRef<any>();



  const [isSending, setIsSending] = useState(false);


  function exportToExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Subscription" + ".xlsx");
  }


  const columns = [
    {
        title: "الرقم",
        dataIndex: "id",
        key: "id",
    },
    {
      title: "الكود",
      dataIndex: "code",
      key: "code",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "النسبة",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "عدد مرات الاستخدام",
      dataIndex: "max_times",
      key: "max_times",
    },
    {
      title: "تاريخ الانتهاء",
      dataIndex: "expiration",
      key: "expiration",
    },

    {
      title: "الحالة",
      dataIndex: "is_active",
      key: "is_active",
      render:(text:any,record:any) => (
          <div>
              {record.is_active == 1 ? "نشط" : "غير نشط"}
          </div>
      )
    },

    {
      title: "العمليات",
      key: "action",
      render: (text: any, record: any) => (
        <div className="flex gap-2">
          <Button
            onClick={() => {
             navigate(`/admin/marketing/discount-codes/edit/${record.id}`,{
                    state:record
             });

            }}
          >
            <Edit  size={18} />
          </Button>
          <Button
              className={"bg-red-500"}
            onClick={() => {
                setForm({
                    code:record.code,
                    percentage:record.percentage,
                    expired:record.expiration,
                    max_times:record.max_times,
                    id:record.id,
                    active:record.is_active
                });
              setDate(new Date(record.expiration));

              setIsDeleting(true);
            }}
          >
            <Trash variant={"Bold"} color={"white"} size={18} />
          </Button>
        </div>
      ),
    },


  ]



    async function fetchData() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_DEV_ADMIN_API_URL}discounts/list-discount`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
      );
      const data = await response.json();
      console.log(data);
      if (response.status == 200) {
        setData(data.discounts);
        setLoading(false);
      } else {
      }
    } catch (error) {
      // dispatch(setIsAuth(false))
      // dispatch(setUserToken(''))
      // navigate('/auth/admin/login')
    }
  }
  useEffect(() => {
    window.document.title = "أكواد الخصم";

    fetchData();
  }, []);


  async function createDiscountCode() {
    formRef.current.submit();
    if (date && isBefore(date, new Date())) {
      toast.error("لا يمكنك انشاء كود ليوم سابق");
      return;

    }

    try {
       setIsSending(true)
        const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}discounts/add-discount`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Accept":"application/json"
            },
            body:JSON.stringify({
                code:form.code,
                percentage:form.percentage,
                max_times:form.max_times,
                // @ts-ignore

                expired:format(date, "yyyy-MM-dd")

            })
        })

        const data = await request.json();
        console.log(data)
        if (request.status == 200) {
          setIsSending(false)
          setOpen(false);
          toast.success("تم اضافة الكود بنجاح");
          fetchData();
        } else {
          setOpen(false);
          toast.error("حدث خطأ أثناء اضافة الكود");
        }
    } catch (error) {
        
    }
  }


  async function deleteDiscountCode(id: string) {
    try {
      setIsDeleting(true);
      const request = await fetch(
        `${process.env.REACT_APP_DEV_ADMIN_API_URL}discounts/delete-discount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
            body: JSON.stringify({ id: id }),
        }
      );
      const data = await request.json();
      console.log(data);
      if (request.status == 200) {
        setIsDeleting(false);
        toast.success("تم حذف الكود بنجاح");
        fetchData();
      } else {
        setIsDeleting(false);
        toast.error("حدث خطأ أثناء حذف الكود");
      }
    } catch (error) {
      setIsDeleting(false);
      toast.error("حدث خطأ أثناء حذف الكود");
    }
  }

  async function editDiscountCode() {
    formRef.current.submit();
    if (date && isBefore(date, new Date())) {
      toast.error("لا يمكنك انشاء كود ليوم سابق");
      return;

    }

    try {
      setIsSending(true);
        const request = await fetch(
        `${process.env.REACT_APP_DEV_ADMIN_API_URL}discounts/update-discount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
              "Accept":"application/json"
          },
          body: JSON.stringify({
            code: form.code,
            percentage: form.percentage,
            max_times: form.max_times,
              // @ts-ignore

            expired: format(date, "yyyy-MM-dd"),
            id: form.id,
            active:form.active == 1 ? "true" : "false"
          }),
        }
      );
      const data = await request.json();
      console.log(data);
      if (request.status == 200) {
        setIsSending(false);
        setOpen(false);
        toast.success("تم تعديل الكود بنجاح");
        fetchData();
      } else {
        setOpen(false);
        toast.error(data.message);
      }
    } catch (error) {
      setIsSending(false);
      toast.error("حدث خطأ أثناء تعديل الكود");
    }
  }

  const itemRender = (_: any, type: string, originalElement: any) => {
    console.log(type, "a");
    if (type === "prev") {
      return <ArrowRight2 size="32" color="#333" />;
    }
    if (type === "next") {
      return <ArrowLeft2 size="32" color="#333" />;
    }
    return originalElement;
  };



  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{ direction: "rtl" }}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      <div
                        style={{ background: "#f9f9f9" }}
                        className={" p-2 rounded-md"}
                      >
                        <TicketDiscount
                          size="48"
                          color="#2dacf4"
                          variant="Bulk"
                        />
                      </div>

                      <h1 className="text-xl mr-2">أكواد الخصم</h1>
                    </div>
                    <Button
                      onClick={() => {
                        navigate("/admin/marketing/discount-codes/create");
                      }}
                    >
                      أنشاء كود خصم جديد
                    </Button>
                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="mx-6 mt-6">
              <Table
                  columns={columns}
                  dataSource={data}
                  showHeader={true}
                  bordered={true}
                  sortDirections={["ascend", "descend"]}
                  showSorterTooltip={true}
                  pagination={{
                    showQuickJumper: true,
                    showTotal: (total) => `Total ${total} items`,

                    position: ["bottomCenter"],
                    size: "default",
                    itemRender: itemRender
                  }}

              />
            </div>
            <Form
                validateTrigger={["onFinish", "onSubmit"]}
                ref={formRef}
                onFinish={isEditing ? editDiscountCode : createDiscountCode}
            >
            <AlertDialog open={open}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {isEditing ? "تعديل  كود الخصم " : "إضافة كود خصم جديد"}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    <div className="flex flex-col gap-4">

                        <Form.Item
                          label="الكود"
                          name="code"
                            initialValue={form.code}


                          rules={[
                            {
                              required: true,
                              message: "الرجاء ادخال الكود",
                            },
                          ]}
                        >
                            <Input
                                value={form.code}

                                onChange={(e) => setForm({...form,code:e.target.value})}
                            />
                        </Form.Item>
                        <Form.Item
                          label="النسبة"
                          name="percentage"
                          initialValue={form.percentage}

                          rules={[
                            {
                              required: true,
                              message: "الرجاء ادخال النسبة",
                              validator: (_, value) => {
                                if (value > 100) {
                                  return Promise.reject(
                                    new Error("النسبة يجب ان تكون اقل من 100")
                                  );
                                }
                                return Promise.resolve();
                              }

                            },
                          ]}
                        >
                            <Input
                                value={form.percentage}
                                type="number"
                                min={1}
                                onChange={(e) => setForm({...form,percentage:e.target.value})}
                            />
                        </Form.Item>
                        <Form.Item
                          label="عدد مرات الاستخدام"
                          name="max_times"
                            initialValue={form.max_times}
                          rules={[
                            {
                              required: true,
                              message: "الرجاء ادخال عدد مرات الاستخدام",
                              validator: (_, value) => {
                                if (value < 1) {
                                  return Promise.reject(
                                      new Error("عدد مرات الاستخدام يجب ان يكون اكبر من 0")
                                  );
                                }
                                return Promise.resolve();
                              }
                            },
                          ]}
                        >
                            <Input
                                value={form.max_times}
                                type="number"
                                min={1}
                                onChange={(e) => setForm({...form,max_times:e.target.value})}
                            />
                        </Form.Item>

                        <Form.Item
                            label="تاريخ الانتهاء"
                            name="expired"
                            initialValue={form.expired}
                            rules={[
                              {
                                required: true,
                                message: "الرجاء ادخال تاريخ الانتهاء",
                              },
                            ]}
                        >


                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                  variant={"outline"}
                                  className="w-[280px] justify-start text-left font-normal"
                              >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date ? format(date, "PPP") : <span>Pick a date</span>}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                              <Calendar
                                  mode="single"
                                  selected={date}
                                  onSelect={setDate}
                                  initialFocus
                                  className="rounded-md border"
                              />
                            </PopoverContent>
                          </Popover>
                        </Form.Item>
                        <Form.Item
                            label={"حالة كود الخصم"}
                            name={"active"}
                            initialValue={form.active}

                        >
                          <Checkbox
                              checked={form.active == 1}
                              onChange={(e) => setForm({...form,active:e.target.checked ? 1 : 0})}
                          >
                            نشط
                          </Checkbox>
                        </Form.Item>


                    </div>
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={() => {
                    setOpen(false);
                    setIsEditing(false);
                    setForm({
                      code:"",
                      percentage:"",
                      expired:date,
                      max_times:"",
                      active:0,
                      id:""
                    })

                    }}>
                    الغاء
                  </AlertDialogCancel>
                  <AlertDialogAction
                      type="submit"
                      disabled={isSending} onClick={() =>
                     isEditing ? editDiscountCode() : createDiscountCode()
                  }
                    >
                        {isSending ? (
                        <PropagateLoader size={10} color="#fff" />
                        ) : (
                        "حفظ"
                        )}
                    </AlertDialogAction>


                </AlertDialogFooter>
              </AlertDialogContent>

            </AlertDialog>
            </Form>
            <AlertDialog open={isDeleting}>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>هل أنت متأكد من الحذف؟</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={() => setIsDeleting(false)}>
                    الغاء
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={() => deleteDiscountCode(form.id)}>تأكيد الحذف</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </Content>
        </>
      )}
    </>
  );
}
