import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { setIsAuth, setUserToken } from "src/redux/slices/user_slice";
import AdminLayout from "src/Layouts/Main";
import { ArchiveTick, Danger, LampOn, Message, MessageQuestion, Setting2 } from "iconsax-react";
import { Button } from "src/components/ui/button";
import { PieChart } from "@mui/x-charts";

const { Content } = Layout;

export default function CustomerServiceDashboard() {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);



    useEffect(() => {

    }, []);

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>
            ) : (
                <>
                    <div>
                        <Toaster />
                    </div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>
                        <div className="grid grid-cols-2 gap-2 mx-6 mt-6 ">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">

                                        <div className={"flex items-center justify-between"}>
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <Message size="48" color="#2dacf4" variant="Bulk" />
                                            </div>
                                            <h1 className={"text-xl mr-2"}>أجمالي التذاكر</h1>

                                        </div>

                                        <h1 className={"text-xl"}>
                                            50
                                        </h1>

                                    </div>


                                </CardHeader>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">

                                        <div className={"flex items-center justify-between"}>
                                            <div className={" p-2 rounded-md bg-[#12b76a0c]"}>
                                                <ArchiveTick size="48" color="#12B76A" variant="Bulk" />
                                            </div>
                                            <h1 className={"text-xl mr-2"}>عدد التذاكر التي تم حلها</h1>

                                        </div>

                                        <h1 className={"text-xl"}>
                                            50
                                        </h1>

                                    </div>


                                </CardHeader>
                            </Card>


                        </div>
                        <div className="grid grid-cols-2 gap-2 mx-6 mt-6 ">
                            <Card>
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <h1 className="text-sm font-medium text-black">
                                        عدد التذاكر حسب الحالة
                                    </h1>
                                    <h1 className="text-lg font-bold text-black">
                                        5 تذاكر
                                    </h1>
                                </CardHeader>
                                <CardContent className="flex flex-col gap-4">
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "rgba(240, 68, 56, 0.1)" }} className={" p-2 rounded-md"}>
                                                <Danger size="48" color="#F04438" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>شكاوى</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>5</h1>
                                        </div>


                                    </div>
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "rgba(122, 90, 248, 0.1)" }} className={" p-2 rounded-md"}>
                                                <MessageQuestion size="48" color="#7A5AF8" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>استفسار</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>5</h1>
                                        </div>


                                    </div>
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "rgba(253, 176, 34, 0.1)" }} className={" p-2 rounded-md"}>
                                                <LampOn size="48" color="#FDB022" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>اقتراح</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>5</h1>
                                        </div>


                                    </div>



                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <h1 className="text-sm font-medium text-black">
                                        أكثر الوحدات شكاوى
                                    </h1>
                                    <h1 className="text-lg font-bold text-black">
                                        5 تذاكر
                                    </h1>
                                </CardHeader>
                                <CardContent className="flex flex-col gap-4">
                                    <div className="flex items-center my-2 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <img style={{ width: "48px", height: "48px" }} src="/images/Golden.png" alt="" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>Goldun Tulip</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>5</h1>
                                        </div>


                                    </div>
                                    <div className="flex items-center my-2 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <img style={{ width: "48px", height: "48px" }} src="/images/Golden.png" alt="" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>Goldun Tulip</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>5</h1>
                                        </div>


                                    </div>

                                    <div className="flex items-center my-2 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <img style={{ width: "48px", height: "48px" }} src="/images/Golden.png" alt="" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>Goldun Tulip</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>5</h1>
                                        </div>


                                    </div>




                                </CardContent>
                            </Card>



                        </div>



                    </Content>
                </>
            )}
        </>
    );
}
