const adminUrlDev = process.env.REACT_APP_DEV_ADMIN_API_URL
const adminUrlProd = process.env.REACT_APP_PROD_ADMIN_API_URL
const apiUrlDev = process.env.REACT_APP_DEV_API_URL
const apiUrlProd = process.env.REACT_APP_PROD_API_URL


export async function getByPageName(slug:any,token:any) {
    try {
        const response = await fetch(`${apiUrlDev}get-page?slug=${slug}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
            "Accept": "application/json",
          }
        })
        const data = await response.json();
        if (response.status == 200) {
          return data.data
        }
      }
      catch (error) {
        console.error(error)
      }
}

export async function updateByPageName(slug:any,token:any,body:any) {
    try {
        const response = await fetch(`https://dev.urrnt.com/api/get-page?slug=${slug}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
            "Accept": "application/json",
          },
          body: JSON.stringify(body)
        })
        const data = await response.json();
        if (response.status == 200) {
          return data.data.description
        }
      }
      catch (error) {
        console.error(error)
      }
}


export async function getFetcher(token:any,url:any) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },

    })
    const data = await response.json();
    if (response.status == 200) {
      return data
    }
    
  } catch (error) {
    console.error(error)
    return error
    
  }
}
export async function postFetcher(token: any, url: any, body: any) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        "Authorization": "Bearer " + token,
        Accept: 'application/json',
      },
      body: JSON.stringify(body)
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(`Failed to post data: ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
