import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from "../../i18n";
import {LOCALE_KEY} from "../../index";

type Props = {
  androidUrl: string;
  iosUrl: string;
};

export default function Header({ androidUrl, iosUrl }: Props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isEthereumAvailable, setIsEthereumAvailable] = useState(false);
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsEthereumAvailable(true);
    }
  }, [typeof window]);

  const changeLocale = (e: any, locale: string) => {
    e.preventDefault();
    localStorage.setItem(LOCALE_KEY, locale);
    i18n.changeLanguage(locale);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a href="/" onClick={(e) => changeLocale(e, 'en')} rel="noopener noreferrer">
          English
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a href="/" onClick={(e) => changeLocale(e, 'ar')} rel="noopener noreferrer">
          العربية
        </a>
      ),
    },
  ];

  const localesDropdown = () =>
    isEthereumAvailable && (
      <Dropdown menu={{ items }} trigger={['click']}>
        <div className="inline">
          <span className="uppercase">{i18n.language === 'ar' ? 'AR' : 'EN'}</span>
          <DownOutlined className="mx-2" />
        </div>
      </Dropdown>
    );

  return (
     (
      <div className="relative">
        <div className={`absolute ${i18n.language === 'ar' ? 'mirror left-0 rotate-180' : 'right-0'} top-0 -z-10`}>
          <img src="/assets/imgs/bg-head.png" alt="bg-head-img" />
        </div>
        <div className="m-home mx-5 lg:mx-20">
          <div className=" flex items-center justify-between py-5">
            <img className="w-20 lg:w-48" src="/assets/imgs/logo-white-2.svg" alt="logo-white" />

            {isMobile ? (
              <MenuOutlined className="text-2xl text-white" onClick={() => setNavDrawerOpen(true)} />
            ) : (
              <ul className="list-none text-lg font-medium text-white">
                <li className="mx-8 inline-block hover:drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
                  <Link to="#about-us">{t('common:home.about-us')}</Link>
                </li>
                <li className="mx-8 inline-block hover:drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
                  <Link to="#destinations">{t('common:home.destinations')}</Link>
                </li>
                <li className="mx-8 inline-block hover:drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
                  <Link to="#how-it-work">{t('common:home.how-it-work')}</Link>
                </li>
                {/* <li className="mx-8 inline-block hover:drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
                  <Link href="#testimonials">{t('common:home.testimonials')}</Link>
                </li> */}
                <li className="mx-8 inline-block cursor-pointer text-lg hover:drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
                  {localesDropdown()}
                </li>
              </ul>
            )}
          </div>

          <div className="my-8 grid grid-cols-12">
            <div className="col-span-12 mt-10 lg:col-span-6">
              <h2 className="text-xl font-extrabold uppercase">{t('common:home.best-destinations')}</h2>
              {isMobile ? (
                <p className="my-10 text-2xl font-bold leading-6 md:leading-[85px] lg:text-[85px]">
                  {t('common:home.travel')}, {t('common:home.enjoy')}{' '}
                  {t('common:home.full-life')}
                </p>
              ) : (
                <p className="my-10 text-2xl font-bold leading-6 md:leading-[85px] lg:text-[85px]">
                  {t('common:home.travel')},{' '}
                  <span className={`relative ${i18n.language === 'ar' ? 'pl-24' : 'pr-24'}`}>
                    {t('common:home.enjoy')}
                    <img
                      className="w-100 absolute bottom-3 right-0 -z-10"
                      src="/assets/imgs/head-text-bg.png"
                      alt="head-text-bg"
                    />
                  </span>{' '}
                  {t('common:home.full-life')}
                </p>
              )}
              <div className="block items-center justify-start lg:flex">
                <Button
                  href={iosUrl}
                  className="flex h-14 w-48 items-center justify-start !border-black bg-black !text-white">
                  <img
                    className={`${i18n.language === 'ar' ? 'ml-5' : 'mr-5'} w-7`}
                    src="/assets/icons/apple.svg"
                    alt="apple-icon"
                  />
                  <div className={`-mt-[10px] ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <span className="text-xs text-gray-300">{t('common:home.download-on')}</span>
                    <p className="text-lg leading-5">{t('common:home.app-store')}</p>
                  </div>
                </Button>
                <Button
                  href={androidUrl}
                  className="mx-0 mt-5 flex h-14 w-48 items-center justify-start !border-black bg-black !text-white md:mx-3 md:mt-0">
                  <img
                    className={`${i18n.language === 'ar' ? 'ml-5' : 'mr-5'} w-7`}
                    src="/assets/icons/android.svg"
                    alt="android-icon"
                  />
                  <div className={`-mt-[10px] ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <span className="text-xs text-gray-300">{t('common:home.get-it-on')}</span>
                    <p className="text-lg leading-5">{t('common:home.google-play')}</p>
                  </div>
                </Button>
              </div>
            </div>
            <div className="col-span-12 mt-5 lg:col-span-6 lg:mt-0">
              <img src="/assets/imgs/phone-head.png" alt="bg-head-img" />
            </div>
          </div>
        </div>
        <Drawer width={250} placement="right" onClose={() => setNavDrawerOpen(false)} open={navDrawerOpen}>
          <ul>
            <li className="my-5 font-bold text-[#0A8ED9]">
              <Link to="/">{t('common:home.about-us')}</Link>
            </li>
            <li className="my-5 font-bold text-[#0A8ED9]">
              <Link to="/">{t('common:home.destinations')}</Link>
            </li>
            <li className="my-5 font-bold text-[#0A8ED9]">
              <Link to="/">{t('common:home.how-it-work')}</Link>
            </li>
            {/* <li className="my-5 font-bold text-[#0A8ED9]">
              <Link href="/">{t('common:home.testimonials')}</Link>
            </li> */}
            <li className="my-5 font-bold text-[#0A8ED9]">{localesDropdown()}</li>
          </ul>
        </Drawer>
      </div>
    )
  );
}
