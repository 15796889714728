import {useEffect, useState} from "react";

import { jwtDecode } from "jwt-decode";

import {Layout} from "antd";
import {useNavigate} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setIsAuth, setUserToken } from "src/redux/slices/user_slice";
import DashboardHeader from "src/components/Header";
import {Toaster} from "../components/ui/toaster";
import "../dashboard.css";
import SideBar from "../components/Sidebar";
const { Header, Content, Sider } = Layout;


export default  function AdminLayout(){
    const is_auth = useAppSelector((state) => state.user.is_auth)
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const userRole = useAppSelector((state) => state.user.role)
    const AuthorizedPages = [{
        role: 'CustomerService',
        authPages: [
           {
               path: '/customer-service/dashboard',

           },
           {
               path: '/contact/requests',
           }
        ]

    }]
    const navigate = useNavigate();
    if (!is_auth) {
        navigate('/auth/admin/login')
    }
    useEffect(()=>{

        checkUserRoles()
        // checkToken()
    }, [userRole])

    function checkUserRoles() {
        switch (userRole) {
          case 'CustomerService':
            AuthorizedPages.forEach((page) => {
              if (page.role === userRole) {
                const hasAccess = page.authPages.some((authPage) => {
                  return authPage.path === window.location.pathname;
                });
      
                if (!hasAccess) {
                  navigate('/404');
                }
              }
            });
            break;
      
      
          default:
            break;
        }
      }
      



    







    return (
        <Layout style={{direction:"rtl"}}>
            <DashboardHeader></DashboardHeader>
            <SideBar></SideBar>
            <Toaster />
           
            </Layout>
    )
}