import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import userSlice from './slices/user_slice'
// @ts-ignore
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
    key: 'root',
    storage,
}

const userReducer = persistReducer(persistConfig, userSlice)


export const store = configureStore({
    reducer: {
        user: userReducer,
    },
   middleware:[logger]

})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
