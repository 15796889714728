import {Card, DatePicker, Layout, Select} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  CalendarAdd,
  CalendarRemove,
  Clock,
  CardReceive,
  TransactionMinus, Star, User, Personalcard, House, Eye, CardSlash, Buliding,
} from "iconsax-react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";
import useGetAllCategories from "src/hooks/categories/list";
import {BookingType} from "../../../types/booking";
import {useGetAllUsers} from "../../../hooks/users";

const { Content } = Layout;

export default function FinanceDetails() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const [data, setData]:any = useState([]);
  const currentDate = new Date();

  const [selected, setSelected] = useState<any>("Hosting");
  const [properties, setProperties] = useState<any[]>([]);
  const state = useLocation().state
  console.log(state)
  useEffect(() => {
    window.document.title = "المعلومات الماليه";
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}finance/${state}?type=${selected}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        })
        const data = await response.json();
        console.log(data.provider)
        if (response.status == 200) {
          setProperties(data.provider.properties)
          setData(data.provider)

          setLoading(false)
        }
      } catch (error) {
        dispatch(setIsAuth(false))
        dispatch(setUserToken(''))
        navigate('/auth/admin/login')
      }
    }
    fetchData()
  }, [])


  const exportToExcel = (data:any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "data.xlsx");
  };


  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                        <TransactionMinus size="48" color="#2dacf4" variant="Bulk" />

                      </div>
                      <h1 className="text-xl">
                        المعلومات الماليه  للتاجر {data.name}
                      </h1>

                    </div>



                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
              {properties && properties.length > 0 && properties.map((item: any) => (



                      <Card className="w-full">
                        <div className="flex items-center my-1 justify-between">
                          <div className="flex items-center">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                <Buliding size="48" color="#2dacf4" variant="Bulk"/>
                              </div>
                            </div>

                            <h1 className={"text-xl mr-2"}>{item.title}</h1>



                          </div>

                          <div className="flex items-center gap-4">
                            <h1 className={"text-xl"}>
                              {item.sum_of_reservations_total.toFixed(2)} ر.س
                            </h1>
                            <Button onClick={() => {
                              navigate("/admin/finance/reservations", {state: item.reservations})

                            }}>
                              <Eye
                                  color="white"
                                  variant="Bulk"
                                  className="ml-2 h-8 w-8"
                          />
                         عرض الحجوزات

                        </Button>
                      </div>

                    </div>
                        <div className="items-center justify-between grid  gap-4 mt-6 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-5  ">
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <TransactionMinus size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              عدد الحجوزات
                            </h4>
                            <h4>
                              {item.reservations.length}
                            </h4>
                          </div>

                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>

                              الأجمالي
                            </h4>
                            <h4>
                              {item.sum_of_reservations_total} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#7A5AF8" variant="Bulk"/>
                            </div>
                            <h4>
                              المجموع الفرعي
                            </h4>
                            <h4>
                              {item.sum_of_reservations_sub_total} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع الضريبة
                            </h4>
                            <h4>
                              {item.sum_of_reservations_tax} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              مجموع الخدمة
                            </h4>
                            <h4>
                              {item.sum_of_reservations_service} ر.س
                            </h4>
                          </div>



                        </div>
                      </Card>
                  ))
              }

            </div>


          </Content>
        </>
      )}
    </>
  );
}
