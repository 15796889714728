import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { setIsAuth, setUserToken } from "src/redux/slices/user_slice";
import AdminLayout from "src/Layouts/Main";

const { Content } = Layout;

export default function FinanceDashboard() {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    

    useEffect(() => {
        
    }, []);

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>
            ) : (
                <>
                    <div>
                        <Toaster />
                    </div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>
                       


                    </Content>
                </>
            )}
        </>
    );
}
