import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
    Bank,
    Bezier,
    Book,
    BookSquare,
    Category,
    Edit,
    House,
    Setting2,
    Trash,
    User,
    Location,
    Personalcard,
    Eye,
    Direct,
} from "iconsax-react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Switch } from "src/components/ui/switch";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Icon } from "@iconify/react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { setIsAuth, setUserToken } from "src/redux/slices/user_slice";
import AdminLayout from "src/Layouts/Main";
import { getFetcher } from "src/lib/API";

const { Content } = Layout;

export default function ContactRequests() {
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    const status = [
        {
            arabic: "مفعل",
            english: "PUBLISHED",
        },
        {
            arabic: "قيد المراجعة",
            english: "PENDING",
        },
        {
            arabic: "غير مفعل",
            english: "UNPUBLISHED",
        }
    ]

    useEffect(() => {
        window.document.title = "طلبات التواصل";
        async function fetchData() {
            try {
                const request = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}contacts`)
                setData(request.contacts)
                setLoading(false)
            } catch (error) {
                console.error(error)
            }

        }
        fetchData()
    }, []);

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>
            ) : (
                <>
                    <div>
                        <Toaster />
                    </div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center ">
                                        <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                            <Direct size="48" color="#2dacf4" variant="Bulk" />
                                        </div>

                                        <h1 className="text-xl mr-2">طلبات التواصل</h1>


                                    </div>
                                </CardHeader>
                            </Card>
                        </div>
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mx-6 mt-6">
                            {
                                data.map((item: any) => (
                                    <Card>
                                        <CardHeader>


                                            <div className="flex items-center justify-between flex-row">
                                                <div className="flex items-center">
                                                    <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                        <Personalcard variant="Bulk"
                                                            size="32" color="#475467" />

                                                    </div>

                                                    <h1 className="text-lg font-bold">
                                                        {item.name}
                                                    </h1>
                                                    <div className="text-sm mr-2">
                                                        {item.created_at.split('T')[0]}
                                                    </div>


                                                </div>


                                            </div>

                                        </CardHeader>
                                        <CardContent>
                                            <div className="mr-6">
                                                <h1>
                                                    {item.message}

                                                </h1>
                                            </div>

                                        </CardContent>
                                        <CardFooter>
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h1 className="text-sm">
                                                        {item.email}
                                                    </h1>
                                                </div>

                                            </div>
                                        </CardFooter>

                                    </Card>
                                ))
                            }
                            {/* <Card>
                <div className="flex items-center my-1 justify-between">
                  <div className="flex items-center">
                    <div
                      style={{ background: "#f9f9f9" }}
                      className={" p-2 rounded-md"}
                    >
                      <img
                        width={64}
                        height={64}
                        src="https://tulip-inn-riyadh.goldentulip.com/static/images/TI/logo-new-him.svg"
                        alt=""
                      />
                    </div>

                    <h1 className={"text-xl mr-2"}>Tulip Inn</h1>
                    <h1 className={"text-black mr-2 success-text"}>( مفعل )</h1>
                  </div>
                  <div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Icon
                          width={24}
                          height={24}
                          className="cursor-pointer"
                          icon="pepicons-pop:dots-y"
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                        <DropdownMenuItem>
                            <Eye
                              color="#475467"
                              variant="Bulk"
                              className="ml-2 h-8 w-8"
                            />
                            <span>عرض</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => navigate("/admin/property/edit")}>
                            <Edit
                              color="#475467"
                              variant="Bulk"
                              className="ml-2 h-8 w-8"
                            />
                            <span>تعديل</span>
                          </DropdownMenuItem>
                          
                          <DropdownMenuItem>
                            <Trash
                              color="#F04438"
                              variant="Bulk"
                              className="ml-2 h-8 w-8"
                            />
                            <span>حذف</span>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              </Card> */}
                        </div>


                    </Content>
                </>
            )}
        </>
    );
}
