import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
  Bank,
  Bezier,
  Book,
  BookSquare,
  Category,
  Edit,
  House,
  Setting2,
  Trash,
  User,
  Location,
  Personalcard,
  Eye,
} from "iconsax-react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Switch } from "../../../components/ui/switch";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Icon } from "@iconify/react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
const { Content } = Layout;

export default function CalendarPage() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const [bookings, setBookings] = useState([]);
// @ts-ignore
  const tileContent = ({date, view}):any => {
    // @ts-ignore
    if (view=== 'month') {
      const bookingsForDate = bookings.filter(booking => {
        // @ts-ignore
        const bookingDate = new Date(booking.date);
        // @ts-ignore
        return bookingDate.toDateString() === date.toDateString();
      });

      return <p>{bookingsForDate.length} bookings</p>;
    }

    return null;
  };
  useEffect(() => {
    function Deley() {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    Deley();
  }, []);
  // @ts-ignore

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <h1 className="text-xl">التقويم للحجوزات</h1>
                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="mx-6">
              <Card>
              <Calendar
        tileContent={tileContent}
      />
              </Card>
            </div>
          </Content>
        </>
      )}
    </>
  );
}
