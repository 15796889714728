import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreSubscription } from 'src/hooks/subscriptions';
import {toast} from "react-toastify";

export default function Subscribe() {
  const { t: translate, i18n } = useTranslation();
  const [subscriptionFormRef] = Form.useForm();
    const { mutation } = useStoreSubscription();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast(String(translate('common:home.success-subscriptions')), {
        type: 'success',
        rtl: i18n.language === 'ar',
      });
      subscriptionFormRef.resetFields();
    }
  }, [mutation.isSuccess]);

  return (
    <div
      data-aos="fade-up"
      className="relative m-home mx-5 mb-16 rounded-[100px_20px_20px_20px] bg-[rgba(164,222,255,0.2)] py-16 lg:mx-36">
      <h2 className="mx-auto w-11/12 text-center text-base font-bold capitalize leading-[45px] lg:w-8/12 lg:text-3xl">
        {translate('common:home.subscribe-desc')}
      </h2>
      <div className="absolute right-0 top-0">
        <img className="w-56" src="/assets/imgs/right-circle.png" alt="right-circle" />
      </div>
      <div className="absolute bottom-0 left-10">
        <img className="w-96" src="/assets/imgs/left-circle.png" alt="left-circle" />
      </div>
      <div className="absolute -right-2 -top-2">
        <img className="w-12" src="/assets/icons/subscribe.png" alt="subscribe" />
      </div>

      <div className="mx-5 mt-16 flex justify-center lg:mx-0">
        <div className="block items-center lg:flex">
          <Form
            form={subscriptionFormRef}
            layout="vertical"
            onFinish={(values) => {
              // mutation.mutate({ email: values.email });
            }}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: translate('common:home.required-email'),
                },
                {
                  type: 'email',
                  message: translate('common:home.email-field'),
                },
              ]}>
              <Input
                placeholder={translate('common:home.your-email')}
                className="h-12 min-w-[300px] border-none  bg-[20px_15px] bg-no-repeat px-12  placeholder:text-[#545B67]"
              />
            </Form.Item>
          </Form>
          <Button
            onClick={() => subscriptionFormRef.submit()}
            className="mx-auto mt-6 block h-12 bg-[#0A8ED9] px-10 text-white hover:!text-white lg:mx-4 lg:-mt-5 lg:inline">
            {translate('common:home.subscribe')}
          </Button>
        </div>
      </div>
    </div>
  );
}
