import {Card, Checkbox, Layout, message, Upload, UploadProps} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {  CardHeader } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { Bank, Bezier, Book, BookSquare, Category, Edit, House, Setting2, Trash, User, Location, Building3, Buliding, Buildings2 } from "iconsax-react";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { Icon } from "@iconify/react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "../../../components/ui/alert-dialog";
import {Label} from "../../../components/ui/label";
import {Input} from "../../../components/ui/input";
import {postFetcher} from "../../../lib/API";
import {deleteFettcher, putFettcher} from "../../../API";

const { Content } = Layout;

export default function PropertySettings() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [currentSelected, setCurrentSelected] = useState("categories")
    const [data, setData] = useState([])
    const [isAdding, setIsAdding] = useState(false)
    const translations: { [key: string]: string }[] = [{
        "categories": "الفئات",
        "facilities": "المميزات",
        "nearest-facilities": "المميزات القريبة",
    }]
    const [iconUrl, setIconUrl] = useState("")
    const [form, setForm]:any = useState({
        titleAr: "",
        titleEn: "",
        is_active: 0,
        icon_url: "",
        id: 0

    })
    const [isEditing, setIsEditing] = useState(false)
    const [fileList, setFileList] = useState<any[]>([])
    const [search, setSearch] = useState("")


    const props: UploadProps = {
        name: 'file',
        action: process.env.REACT_APP_DEV_ADMIN_API_URL + "upload/categories",
        headers: {
            "Authorization": "Bearer " + token
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setIconUrl(info.file.response.data)
                setForm({...form, icon_url: info.file.response.data})
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    async function fetchData() {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}${currentSelected}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data[currentSelected] || data.nearestFacilities)
            } else {
                dispatch(setIsAuth(false))
                dispatch(setUserToken(''))
                navigate('/auth/admin/login')
            }
        } catch (error) {
        }
    }

    useEffect(() => {

        fetchData()
    }, [currentSelected])
    // @ts-ignore




    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">

                                        <h1 className="text-xl">الفئات ( {translations.find((item) => item[currentSelected])?.[currentSelected]} ) </h1>
                                        <div className="flex items-center gap-4">
                                            <Button onClick={() => {
                                                if (currentSelected == "facilities") {
                                                    navigate("/admin/add-new-facility")
                                                }
                                                setIsAdding(true)

                                            }}>
                                                اضافة فئة جديدة
                                            </Button>
                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Icon width={24} height={24} className="cursor-pointer" icon="pepicons-pop:dots-y" />

                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent className="w-56">
                                                    <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                    <DropdownMenuSeparator />
                                                    <DropdownMenuGroup>
                                                        <DropdownMenuItem onSelect={() => setCurrentSelected("categories")}>
                                                            <Category
                                                                color="#475467"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>الفئات</span>
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem onSelect={() => setCurrentSelected("facilities")}>
                                                            <Bezier
                                                                color="#475467"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>مرافق</span>
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem onSelect={() => setCurrentSelected("nearest-facilities")}>
                                                            <Location
                                                                color="#475467"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>أقرب المرافق</span>
                                                        </DropdownMenuItem>




                                                    </DropdownMenuGroup>

                                                </DropdownMenuContent>
                                            </DropdownMenu>

                                        </div>
                                    </div>

                                </CardHeader>
                            </Card>
                        </div>
                        <div className="flex items-center gap-4 mx-6 mt-6">
                            <Label>
                                البحث
                            </Label>
                            <Input value={
                                search
                            }
                                      onChange={(event)=>{
                                        setSearch(event.target.value)
                                      }}
                            ></Input>
                        </div>
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mx-6 mt-6">
                            {data.
filter((item: any) => item.translation[0].value.includes(search) || item.translation[1].value.includes(search))
.

                            map((item: any) => (

                                <Card>
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <img width={64} height={64} src={`https://dev.urnt.com.sa/storage/${item.icon_url}`} alt=""/>
                                            </div>
                                            {/*{*/}
                                                <h1 className="text-black text-xl mr-2">{item.title}</h1>

                                            {/*}*/}
                                            <h1 className={`text-black mr-2 ${item.is_active == 1 ? "success" : "error"}-text`}>( {item.is_active == 1 ? "مفعلة" : "غير مفعلة"} )</h1>

                                        </div>
                                        <div>

                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Icon width={24} height={24} className="cursor-pointer" icon="pepicons-pop:dots-y" />

                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent className="w-56">
                                                    <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                    <DropdownMenuSeparator />
                                                    <DropdownMenuGroup>
                                                        <DropdownMenuItem onClick={() => {
                                                            console.log(item)
                                                            setForm({
                                                                titleAr: item.translation.find((t:any) => t.key === "title" && t.language_id === 1)?.value,
                                                                titleEn: item.translation.find((t:any) => t.key === "title" && t.language_id === 2)?.value,
                                                                is_active: item.is_active ? 1 : 0,
                                                                icon_url: item.icon_url,
                                                                id: item.id
                                                            })
                                                            setIsEditing(true)
                                                            setFileList([{
                                                                uid: '-1',
                                                                name: item.icon_url,
                                                                status: 'done',
                                                                url: `https://dev.urnt.com.sa/storage/${item.icon_url}`
                                                            }])

                                                        }}>
                                                            <Edit
                                                                color="#475467"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>تعديل</span>
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem onClick={async () => {
                                                            if (window.confirm("هل انت متأكد من حذف هذه الفئة")) {
                                                                const request = await deleteFettcher(token, `${currentSelected}`, item.id)
                                                                if (request) {
                                                                    toast.success("تم الحذف بنجاح")
                                                                    fetchData()

                                                                } else {
                                                                    toast.error("حدث خطأ ما")
                                                                }
                                                            }
                                                        }}>
                                                            <Trash
                                                                color="#F04438"
                                                                variant="Bulk"
                                                                className="ml-2 h-8 w-8"
                                                            />
                                                            <span>حذف</span>
                                                        </DropdownMenuItem>



                                                    </DropdownMenuGroup>

                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </div>


                                    </div>

                                </Card>
                            ))}
                            
                        </div>
                        <AlertDialog open={isAdding}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>اضافة فئة جديدة</AlertDialogTitle>
                                </AlertDialogHeader>
                                <AlertDialogDescription>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <Label>
                                                العنوان بالعربي
                                            </Label>
                                            <Input onChange={(event)=>{
                                                setForm({...form, titleAr: event.target.value})
                                            }}></Input>
                                        </div>
                                        <div>
                                            <Label>
                                                العنوان بالانقليزي
                                            </Label>
                                            <Input onChange={(event)=>{
                                                setForm({...form, titleEn: event.target.value})

                                            }}></Input>
                                        </div>
                                        <div>
                                            <Upload {...props}>
                                                <Button >
                                                    رفع الايقونة
                                                </Button>
                                            </Upload>
                                        </div>
                                        <div>
                                            <Checkbox
                                                defaultChecked={false}
                                                onChange={(event)=>{
                                                    setForm({...form, is_active: event.target.checked ? 1 : 0})
                                                }}
                                            >
                                                مفعل
                                            </Checkbox>
                                        </div>
                                    </div>
                                </AlertDialogDescription>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => {
                                        setIsAdding(false)

                                    }}>الغاء</AlertDialogCancel>
                                    <AlertDialogAction onClick={async () => {
                                        if (form.icon_url == "") {
                                            toast.error("يجب اختيار ايقونة")
                                        } else {
                                            const request = await postFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}${currentSelected}`, form)
                                            if (request) {
                                                toast.success("تمت الاضافة بنجاح")
                                                setIsAdding(false)
                                                fetchData()
                                            } else {
                                                toast.error("حدث خطأ ما")
                                            }
                                            }

                                    }}>
                                        اضافة
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                        <AlertDialog open={isEditing}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>
                                        تعديل الفئة
                                    </AlertDialogTitle>
                                </AlertDialogHeader>
                                <AlertDialogDescription>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <Label>
                                                العنوان بالعربي
                                            </Label>
                                            <Input

                                                value={form.titleAr}
                                                onChange={(event)=>{
                                                setForm({...form, titleAr: event.target.value})
                                            }}></Input>
                                        </div>
                                        <div>
                                            <Label>
                                                العنوان بالانقليزي
                                            </Label>
                                            <Input
                                                value={form.titleEn}
                                                onChange={(event)=>{
                                                setForm({...form, titleEn: event.target.value})

                                            }}></Input>
                                        </div>
                                        <div>
                                            <Upload
                                                {...props}
                                                fileList={fileList}
                                                onChange={(info) => {
                                                    setFileList(info.fileList)
                                                    if (info.file.status === 'done') {
                                                        setForm({...form, icon_url: info.file.response.data})
                                                    }
                                                }}
                                                onRemove={(file) => {
                                                    setFileList([]);
                                                    if (file.url === form.icon_url) {
                                                        setForm({...form, icon_url: ""});
                                                    }
                                                }}

                                                multiple={false}

                                            >
                                                <Button>
                                                    رفع الايقونة
                                                </Button>
                                            </Upload>

                                        </div>
                                        <div>
                                            <Checkbox
                                                defaultChecked={form.is_active == 1 ? true : false}
                                                value={form.is_active == 1 ? true : false}
                                                onChange={(event)=>{
                                                    setForm({...form, is_active: event.target.checked ? 1 : 0})
                                                }}
                                            >
                                                مفعل
                                            </Checkbox>
                                        </div>
                                    </div>
                                </AlertDialogDescription>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => {
                                        setIsEditing(false)

                                    }}>الغاء</AlertDialogCancel>
                                    <AlertDialogAction onClick={async () => {
                                        if (form.icon_url == "") {
                                           toast.error("يجب اختيار ايقونة")
                                        } else {
                                            const request = await putFettcher(token, `${currentSelected}/${form.id}`, form)
                                            if (request) {
                                                toast.success("تمت الاضافة بنجاح")
                                                setIsEditing(false)
                                                fetchData()
                                            } else {
                                                toast.error("حدث خطأ ما")
                                            }
                                        }


                                    }}>
                                        تعديل
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>




                    </Content>
                </>
            )}




        </>
    )
}