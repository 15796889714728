import { Card, Layout, } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { AreaChart, BarChart, DonutChart, Subtitle, Title } from "@tremor/react";
import {
    Building3,
    Buildings2,
    Buliding,
    Danger,
    House,
    House2,
    LampOn,
    MessageQuestion,
    MoneyRecive, People,
    Setting2,
    TransactionMinus,
    UserSquare,
    WalletMoney
} from "iconsax-react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import { SelectContent, SelectItem, SelectTrigger, SelectValue, SelectShadncn } from "src/components/ui/select";
import { getFetcher } from "src/lib/API";
import {CalendarIcon} from "lucide-react";
import {Popover, PopoverContent, PopoverTrigger} from "../../../components/ui/popover";
import {Button} from "../../../components/ui/button";
import {Calendar} from "../../../components/ui/calendar";
import {addDays, format, parse} from "date-fns";
import {cn} from "../../../lib/utils";
import {DateRange} from "react-day-picker";

const { Content } = Layout;

export default function AdminDashboard() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const [data, setData] = useState({
        sales: 0,
        profit: 0,
        customers: 0,
        total_reservations: 0,
        propertiesCount: 0,
        Villa: 0,
        Apartment: 0,
        Chalet: 0,
        FarmsAndCamps: 0,
        hostingCount: 0,
        totalGuest: 0

    });

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState("allTime")

    const chartdataa = [
        {
            date: "Jan 1",
            "المبيعات": data.sales.toFixed(3),
            "الأرباح": data.profit.toFixed(3),
        },


    ];



    const chartdata = [
        {
            name: "العقيق",
            "عدد الحجوزات في هذاالحي": 2488,
        },
        {
            name: "المعذر",
            "عدد الحجوزات في هذا الحي": 3034,
        },
        {
            name: "العقيق",
            "عدد الحجوزات في هذاالحي": 2390,
        }

    ];

    const currentDate = new Date();
    const [date, setDate] = useState<DateRange | undefined>({
        from: currentDate,
        to: addDays(currentDate, 20), // Set the 'to' date 20 days from now
    });


    useEffect(() => {
        window.document.title = "لوحة التحكم"
        async function fetchData() {
            const fromDate = `from=${format(date?.from || new Date(), "yyyy-MM-dd")}`
            const toDate = `to=${format(date?.to || new Date(), "yyyy-MM-dd")}`
            try {
                const request = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}dashboard${currentSelectedPeriod === "allTime" ? "" : currentSelectedPeriod !== "selectedDate" ? currentSelectedPeriod : `?${fromDate}&${toDate}`}`);
                setData({
                    sales: request.income,
                    profit: request.net,
                    customers: request.customerCount,
                    total_reservations: request.reservationsCount,
                    propertiesCount: request.propertiesCount,
                    Villa: request.categoriesStats[0].properties_count,
                    Apartment: request.categoriesStats[1].properties_count,
                    Chalet: request.categoriesStats[2].properties_count,
                    FarmsAndCamps: request.categoriesStats[3].properties_count,
                    hostingCount: request.hostingCount,
                    totalGuest: request.totalGuest
                })
                setLoading(false)

            } catch (error) {
                // console.error(error)
                // console.log('error')
                // dispatch(setIsAuth(false))
                // dispatch(setUserToken(''))
                // navigate('/auth/admin/login')
            }


        }
        fetchData();
    }, [currentSelectedPeriod,date])






    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#2dacf4" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>
                        <div className="grid gap-4 md:grid-cols-5 lg:grid-cols-5 mx-6 mt-6">
                            <SelectShadncn value={currentSelectedPeriod}
                                           onValueChange={(value) => setCurrentSelectedPeriod(value)}>
                                <SelectTrigger>
                                    <SelectValue placeholder="الفترة الزمنية"/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="?day=1">
                                        اليوم
                                    </SelectItem>
                                    <SelectItem value="?week=1">
                                        هذا الاسبوع
                                    </SelectItem>
                                    <SelectItem value="?month=1">
                                        هذا الشهر
                                    </SelectItem>
                                    <SelectItem value="selectedDate">
                                        تحديد الفتره
                                    </SelectItem>
                                    <SelectItem value="allTime">
                                        كل الوقت
                                    </SelectItem>
                                </SelectContent>
                            </SelectShadncn>
                            {currentSelectedPeriod === "selectedDate" ? (
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            id="date"
                                            variant={"outline"}
                                            className={cn(
                                                "w-[300px] justify-start text-left font-normal",
                                                !date && "text-muted-foreground"
                                            )}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4"/>
                                            {date?.from ? (
                                                date.to ? (
                                                    <>
                                                        {format(date.from, "LLL dd, y")} -{" "}
                                                        {format(date.to, "LLL dd, y")}
                                                    </>
                                                ) : (
                                                    format(date.from, "LLL dd, y")
                                                )
                                            ) : (
                                                <span>اختار الفتره</span>
                                            )}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0" align="start">
                                        <Calendar
                                            initialFocus
                                            mode="range"
                                            defaultMonth={date?.from}
                                            selected={date}
                                            onSelect={setDate}
                                            numberOfMonths={2}
                                        />
                                    </PopoverContent>
                                </Popover>
                            ) : null}
                        </div>

                        <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3  3xl:grid-cols-3 2xl:grid-cols-3">
                                <Card>

                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <WalletMoney size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl  mr-2"}>مبيعات</h1>
                                        </div>
                                        <div>
                                            <div
                                                className="text-lg whitespace-nowrap font-bold text-black">{data.sales.toFixed(1)} ريال
                                            </div>
                                        </div>


                                    </div>

                                </Card>
                                <Card>

                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <MoneyRecive size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>ارباح</h1>
                                        </div>
                                        <div>
                                            <div
                                                className="text-lg font-bold text-black">{data.profit.toFixed(3)} ريال
                                            </div>
                                        </div>


                                    </div>

                                </Card>
                                <Card>

                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <UserSquare size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>اجمالي العملاء</h1>
                                        </div>
                                        <div>
                                            <div className="text-lg font-bold text-black">{data.customers} </div>
                                        </div>


                                    </div>

                                </Card>
                                <Card>

                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <People size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>اجمالي الزوار</h1>
                                        </div>
                                        <div>
                                            <div className="text-lg font-bold text-black">{data.totalGuest} </div>
                                        </div>


                                    </div>

                                </Card>
                                <Card>

                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <House2 size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>اجمالي
                                                المضيفين
                                            </h1>
                                        </div>
                                        <div>
                                            <div className="text-lg font-bold text-black">{data.hostingCount} </div>
                                        </div>


                                    </div>

                                </Card>
                                <Card>

                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <TransactionMinus size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>اجمالي الحجوزات</h1>
                                        </div>
                                        <div>
                                            <div
                                                className="text-lg font-bold text-black">{data.total_reservations} </div>
                                        </div>


                                    </div>

                                </Card>


                            </div>
                            <Card>
                            <AreaChart
                                    className="h-72 mt-4"
                                    data={chartdataa}
                                    index="date"
                                    showAnimation

                                    yAxisWidth={65}
                                    categories={["المبيعات", "الأرباح"]}
                                    colors={["indigo", "cyan"]}
                                />
                            </Card>


                        </div>
                        <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 my-6 ">
                            <Card>
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <h1 className="text-sm font-medium text-black">
                                        عدد الوحدات السكنية
                                    </h1>
                                    <h1 className="text-lg font-bold text-black">
                                        {data.propertiesCount} وحدة
                                    </h1>
                                </CardHeader>
                                <CardContent className="flex flex-col gap-4">
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <House size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>فيلا</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>{data.Villa}</h1>
                                        </div>


                                    </div>
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <Building3 size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>شاليه</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>{data.Chalet}</h1>
                                        </div>


                                    </div>
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <Buliding size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>شقة</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>{data.Apartment}</h1>
                                        </div>


                                    </div>
                                    <div className="flex items-center my-1 justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <Buildings2 size="48" color="#2dacf4" variant="Bulk"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>مزارع ومخيمات</h1>
                                        </div>
                                        <div>
                                            <h1 className={"text-xl"}>{data.FarmsAndCamps}</h1>
                                        </div>


                                    </div>

                                </CardContent>
                            </Card>
                            {/*<Card>*/}
                            {/*    <Title>عدد الحجوزات حسب الاحياء</Title>*/}

                            {/*    <BarChart*/}
                            {/*        className="mt-6"*/}
                            {/*        data={chartdata}*/}
                            {/*        showAnimation*/}
                            {/*        index="name"*/}
                            {/*        categories={["عدد الحجوزات في هذا الحي"]}*/}
                            {/*        colors={["blue"]}*/}
                            {/*        // valueFormatter={valueFormatter}*/}
                            {/*        yAxisWidth={48}*/}
                            {/*    />*/}
                            {/*</Card>*/}

                        </div>
                        {/*<div className="grid grid-cols-2 gap-2 mx-6 mt-6 ">*/}
                        {/*    <Card>*/}
                        {/*        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">*/}
                        {/*            <h1 className="text-sm font-medium text-black">*/}
                        {/*                عدد المبيعات حسب الوحدة السكنية*/}
                        {/*            </h1>*/}
                        {/*            <h1 className="text-lg font-bold text-black">*/}
                        {/*                2000 ريال*/}
                        {/*            </h1>*/}
                        {/*        </CardHeader>*/}
                        {/*        <CardContent className="flex flex-col gap-4">*/}
                        {/*            <div className="flex items-center my-1 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <House size="48" color="#2dacf4" variant="Bulk" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>فيلا</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>500 ريال</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}

                        {/*            <div className="flex items-center my-1 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <Building3 size="48" color="#2dacf4" variant="Bulk" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>شاليه</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>500 ريال</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}
                        {/*            <div className="flex items-center my-1 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <Buliding size="48" color="#2dacf4" variant="Bulk" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>شقة</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>500 ريال</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}
                        {/*            <div className="flex items-center my-1 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <Buildings2 size="48" color="#2dacf4" variant="Bulk" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>مزارع ومخيمات</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>500 ريال</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}


                        {/*        </CardContent>*/}
                        {/*    </Card>*/}
                        {/*    <Card>*/}
                        {/*        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">*/}
                        {/*            <h1 className="text-sm font-medium text-black">*/}
                        {/*                عدد المبيعات حسب   التاجر*/}

                        {/*            </h1>*/}
                        {/*            <h1 className="text-lg font-bold text-black">*/}
                        {/*                2500 ريال*/}
                        {/*            </h1>*/}
                        {/*        </CardHeader>*/}
                        {/*        <CardContent className="flex flex-col gap-4">*/}
                        {/*            <div className="flex items-center my-2 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <img style={{ width: "48px", height: "48px" }} src="/images/Golden.png" alt="" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>Goldun Tulip</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>500</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}
                        {/*            <div className="flex items-center my-2 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <img style={{ width: "48px", height: "48px" }} src="/images/Golden.png" alt="" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>Goldun Tulip</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>5</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}

                        {/*            <div className="flex items-center my-2 justify-between">*/}
                        {/*                <div className="flex items-center">*/}
                        {/*                    <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>*/}
                        {/*                        <img style={{ width: "48px", height: "48px" }} src="/images/Golden.png" alt="" />*/}
                        {/*                    </div>*/}

                        {/*                    <h1 className={"text-xl mr-2"}>Goldun Tulip</h1>*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <h1 className={"text-xl"}>5</h1>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}


                        {/*        </CardContent>*/}
                        {/*    </Card>*/}


                        {/*</div>*/}


                    </Content>
                </>
            )}


        </>
    )
}