import { Layout, Menu, Switch, Typography } from 'antd';
import { Icon } from "@iconify/react";
import {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import {
    Archive,
    Bag2,
    BookSquare,
    Calendar,
    CallCalling, CloseCircle,
    Dash,
    DirectNotification, DiscountShape,
    Discover, House,
    MessageNotif,
    Messages2,
    MoneyArchive, MoneyTime, Profile2User,
    Receipt1, SecurityUser,
    Setting2, TicketDiscount, Timer,
    Timer1, TransmitSqaure2
} from 'iconsax-react';
import { useAppSelector } from "../redux/hooks";
const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;
export default function SideBar() {
    const [collapsed, setCollapsed] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const currentPath = window.location.pathname;
    const breakpoints = {
        xs: 480,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280,
        mdXl: 1366,
        xxl: 1800,
    };
    const getSliderWidth = () => {
        const screenWidth = window.innerWidth;
        switch (true) {
            case screenWidth < breakpoints.sm:

                return 80;
            case screenWidth < breakpoints.md:
                return 80;
            case screenWidth < 1400:
                return 80
            case screenWidth < breakpoints.lg:
                return 120;
            case screenWidth < breakpoints.xl:
                return 120;
            case screenWidth < 1700:
                return 220;
            case screenWidth < breakpoints.mdXl:
                return 120;
            case screenWidth < 1095:
                return 60
            case screenWidth < 1050:
                return 60


            case screenWidth < breakpoints.xxl:
                return 280;

            default:
                return 280;
        }
    };

    const [sliderWidth, setSliderWidth] = useState(getSliderWidth());

    useEffect(() => {
        const handleResize = () => {
            setSliderWidth(getSliderWidth());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const role = useAppSelector((state) => state.user.role);
    function getItemsByRole(role: string) {
        switch (role) {
            case 'Super Admin':
                return (
                    <>
                        <Menu.Item icon={<Dash size="24" color="#2dacf4" variant="Bulk" />} key="/admin/dashboard">
                            <Link className='text-base' to={"/admin/dashboard"}>
                                لوحة التحكم
                            </Link>
                        </Menu.Item>
                        <Menu.Item icon={<MoneyArchive size="24" color="#2dacf4" variant="Bulk" />} key="/admin/properties">
                            <Link className='text-base' to={"/admin/properties"}>
                                الوحدات السكنية
                            </Link>
                        </Menu.Item>
                        <Menu.Item icon={<MoneyTime size="24" color="#2dacf4" variant="Bulk" />} key="/admin/transactions">
                            <Link className='text-base' to={"/admin/transactions"}>
                                العمليات
                            </Link>
                        </Menu.Item>
                        <Menu.SubMenu icon={<Discover size="24" color="#2dacf4" variant="Bulk" />} title={<h1 className="text-base"> المستخدمين</h1>}>
                            <Menu.Item icon={<MessageNotif size="24" color="#2dacf4" variant="Bulk" />} key="/admin/users">
                                <Link className='text-base' to={"/admin/users"}>
                                    جميع المستخدمين
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<House size="24" color="#2dacf4" variant="Bulk" />} key="/admin/users/host">
                                <Link className='text-base' to={"/admin/users/host"}>
                                   أصحاب الوحدات السكنية
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<Profile2User size="24" color="#2dacf4" variant="Bulk" />} key="/admin/users/customer">
                                <Link className='text-base' to={"/admin/users/customer"}>
                                    العملاء
                                </Link>
                            </Menu.Item>

                            {/*<Menu.Item icon={<SecurityUser size="24" color="#2dacf4" variant="Bulk" />} key="/admin/users/roles">*/}
                            {/*    <Link className='text-base' to={"/admin/users/roles"}>*/}
                            {/*        الصلاحيات*/}
                            {/*    </Link>*/}
                            {/*</Menu.Item>*/}
                        </Menu.SubMenu>
                        <Menu.SubMenu icon={<CallCalling size="24" color="#2dacf4" variant="Bulk" />} title={<h1 className="text-base"> التواصل</h1>}>
                            <Menu.Item icon={<MessageNotif size="24" color="#2dacf4" variant="Bulk" />} key="/contact/requests">
                                <Link className='text-base' to={"/contact/requests"}>
                                    طلبات التواصل
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<DirectNotification size="24" color="#2dacf4" variant="Bulk" />} key="/admin/marketing/Subscriptions">
                                <Link className='text-base' to={"/admin/marketing/Subscriptions"}>
                                    الاشتراكات
                                </Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu title={<h1 className="text-base"> الحجوزات</h1>} icon={<Archive size="24" color="#2dacf4" variant="Bulk" />} >
                            <Menu.Item icon={<Timer size="24" color="#2dacf4" variant="Bulk" />}  key="/admin/reservation/status?=active">
                                <Link className='text-base' to={"/admin/reservation/status?=active"} state={"active"}>
                                    الحالية
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<Timer1 size="24" color="#2dacf4" variant="Bulk" />}  key="/admin/reservation/status?=past">
                                <Link className='text-base' to={"/admin/reservation/status?=past"} state={"past"}>
                                    السابقة
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<Timer1 size="24" color="#2dacf4" variant="Bulk" />}  key="/admin/reservations/status?=paid">
                                <Link className='text-base' to={"/admin/reservations/status?=paid"}   state={"paid"}>
                                    المدفوعة
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<CloseCircle size="24" color="#2dacf4" variant="Bulk" />}   key="/admin/reservations/status?=unpaid">
                                <Link state={"unpaid"} className='text-base' to={`/admin/reservations/status?=unpaid`}>
                                   الغير مدفوعة
                                </Link>
                            </Menu.Item>
                            
                        </Menu.SubMenu>
                        <Menu.SubMenu title={<h1 className="text-base"> التسويق</h1>} icon={<Bag2 size="24" color="#2dacf4" variant="Bulk" />} >
                            <Menu.Item icon={<TicketDiscount size="24" color="#2dacf4" variant="Bulk" />}  key="/admin/marketing/discount-codes">
                                <Link className='text-base' to={"/admin/marketing/discount-codes"}>
                                    أكواد الخصم
                                </Link>
                            </Menu.Item>
                            <Menu.Item icon={<DiscountShape size="24" color="#2dacf4" variant="Bulk" />}  key="/admin/marketing/promotions">
                                <Link className='text-base' to={"/admin/marketing/promotions"}>
                                    العروض
                                </Link>
                            </Menu.Item>
                            
                            
                        </Menu.SubMenu>
                        

                        <Menu.Item icon={<Messages2 size="24" color="#2dacf4" variant="Bulk" />} key="/admin/reviews">
                            <Link className='text-base' to={"/admin/reviews"}>
                                التقيمات
                            </Link>
                        </Menu.Item>
                        <Menu.SubMenu icon={<Receipt1 size="24" color="#2dacf4" variant="Bulk" />} title={<h1 className="text-base">المالية</h1>}>

                        <Menu.Item icon={<Receipt1 size="24" color="#2dacf4" variant="Bulk" />} key="/admin/payouts">
                            <Link className='text-base' to={"/admin/payouts"}>
                                المدفوعات
                            </Link>

                        </Menu.Item>
                            <Menu.Item icon={<TransmitSqaure2 size="24" color="#2dacf4" variant="Bulk" />} key="/admin/finance">
                                <Link className='text-base' to={"/admin/finance"}>
                                  الماليه
                                </Link>

                            </Menu.Item>
                    </Menu.SubMenu>
                        <Menu.Item icon={<Setting2 size="24" color="#2dacf4" variant="Bulk" />} key="/admin/settings">
                            <Link className='text-base' to={"/admin/settings"}>
                                الاعدادات
                            </Link>
                        </Menu.Item>
                    </>
                );
            case 'CustomerService':
                return (
                    <>
                        <Menu.Item icon={<Dash size="24" color="#2dacf4" variant="Bulk" />} key="/customer-service/dashboard">
                            <Link className='text-base' to={"/customer-service/dashboard"}>
                                نبذه عامه
                            </Link>
                        </Menu.Item>
                        <Menu.Item icon={<MessageNotif size="24" color="#2dacf4" variant="Bulk" />} key="/contact/requests">
                            <Link className='text-base' to={"/contact/requests"}>
                                التذاكر
                            </Link>
                        </Menu.Item>
                    </>
                );
        }
    }

    return (
        <div className="h-screen fixed ">

            <Sider
                className="h-screen "
                collapsible
                collapsed={collapsed}
                onCollapse={setCollapsed}
                style={{ background: darkMode ? '#001529' : '#fff' }}
                width={sliderWidth}

            >
                <div className="my-8" style={{ height: '84px' }}>
                    <img className="mx-auto" style={{ height: '84px' }} src="/assets/imgs/Logo.svg" alt="Logo" />
                </div>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[currentPath]}
                    defaultOpenKeys={['sub6']}
                    style={{ height: '100%', borderRight: 0 }}
                >
                    {getItemsByRole(role)}
                </Menu>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '16px' }}>
                    <Switch checked={darkMode} onChange={setDarkMode} />
                </div>
            </Sider>


        </div>

    );
}
