import { useQuery } from '@tanstack/react-query';
import {HomeType} from "../../types/home";
import client from "../../helpers/client";

const useGetHomeData = (lang: string) => {
  const fetchHomeData = async (): Promise<HomeType> =>
    client
      .get(`api/admin/home`, {
        headers: {
          lang,
        },
      })
      .then((response) => response.data);
  const { data, error, isLoading } = useQuery<HomeType>({
    queryKey: ['home', lang],
    queryFn: fetchHomeData,
  });

  return {
    testimonials: data?.testimonials,
    trustedCompanies: data?.trustedCompanies,
    settings: data?.settings,
    isLoading,
    isError: error,
  };
};
export default useGetHomeData;
