import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

export default function Destination() {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isEthereumAvailable, setIsEthereumAvailable] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsEthereumAvailable(true);
    }
  }, [typeof window]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    centerMode: true,
    speed: 500,
    slidesToShow: isEthereumAvailable && isMobile ? 1 : 3,
    swipeToSlide: true,
    arrows: false,
  };

  return (
      <div id="destinations" data-aos="fade-up" className="m-home  mx-0 py-10 sm:py-36 lg:mx-20">
        <h2 className="text-center text-xl font-extrabold lg:text-4xl">{translate('common:home.top-destinations')}</h2>

        <Slider  {...settings}>
          <div>
            <article className="relative isolate mx-1 mt-24 flex max-w-sm flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-40 md:mx-auto">
              <img
                  src="/assets/imgs/Riyadh.jpg"
                  alt="/assets/imgs/Riyadh.jpg"
                  className="absolute inset-0 h-full w-full object-cover hover:shadow-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <h3 className="z-10 mt-3 text-3xl font-bold text-white">{translate('common:home.destins.riyadh')}</h3>
              {/* <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">City of love</div> */}
            </article>
          </div>

          <div>
            <article className=" relative isolate mx-1 mt-24 flex max-w-sm flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-40 md:mx-auto">
              <img
                  src="/assets/imgs/Jeddah.jpg"
                  alt="/assets/imgs/Jeddah.jpg"
                  className="absolute inset-0 h-full w-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <h3 className="z-10 mt-3 text-3xl font-bold text-white">{translate('common:home.destins.jeddah')}</h3>
              {/* <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">City of love</div> */}
            </article>
          </div>

          <div>
            <article className=" relative isolate mx-1 mt-24 flex max-w-sm flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-40 md:mx-auto">
              <img
                  src="/assets/imgs/Abha.jpg"
                  alt="/assets/imgs/Abha.jpg"
                  className="absolute inset-0 h-full w-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <h3 className="z-10 mt-3 text-3xl font-bold text-white">{translate('common:home.destins.abha')}</h3>
              {/* <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">City of love</div> */}
            </article>
          </div>

          <div>
            <article className=" relative isolate mx-1 mt-24 flex max-w-sm flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-40 md:mx-auto">
              <img
                  src="/assets/imgs/Damam.webp"
                  alt="/assets/imgs/Damam.webp"
                  className="absolute inset-0 h-full w-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <h3 className="z-10 mt-3 text-3xl font-bold text-white">{translate('common:home.destins.damam')}</h3>
              {/* <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">City of love</div> */}
            </article>
          </div>
        </Slider>
      </div>
  );
}