import {Card, Checkbox, Form, Layout, message, Select, TimePicker, Upload, UploadProps} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";

import { Button } from "src/components/ui/button";
import { Label } from "src/components/ui/label";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { Textarea } from "src/components/ui/textarea";
import GoogleMapReact from "google-map-react";
import { LocationAdd } from "iconsax-react";
import { getFetcher, postFetcher } from "src/lib/API";
import {useToast} from "../../components/ui/use-toast";
import {setIsAuth, setUserToken} from "../../redux/slices/user_slice";
import {useNavigate} from "react-router-dom";
import {Input} from "../../components/ui/input";
import {Controller, useForm} from "react-hook-form";
import {InboxIcon} from "lucide-react";
import {putFettcher} from "../../API";
import dayjs from "dayjs";
import moment from "moment";

const { Content } = Layout;

export default function PropertyCreate() {
  const [loading, setLoading] = React.useState(true);
  const { toast } = useToast()

  const token = useAppSelector((state) => state.user.token);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [currentMarker, setCurrentMarker] = useState({ lat: 24.6584748, lng: 46.6264939 })
  const [categories, setCategories] = useState([]);
  const [nearestFacilities, setNearestFacilities] = useState([]);
  const [fileList, setFileList] = useState<any[]>([])
  const defaultProps = {
    center: {
      lat: 24.6584748,
      lng: 46.6264939
    },
    zoom: 11
  };
  const [currentTap, setCurrentTap] = useState("account");
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const status = [
    {
      arabic: "مفعل",
      english: "PUBLISHED",
    },
    {
      arabic: "قيد المراجعة",
      english: "PENDING",
    },
    {
      arabic: "مرفوض",
      english: "REJECTED",
    },
    {
      arabic: "مسودة",
      english: "DRAFT",
    }
  ]



  const { trigger,control,register, handleSubmit, formState: { errors } } = useForm();
  const [createdProperty, setCreatedProperty] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const onSubmit = async (data: any) => {
    const fullData = {...data, latitude: createFormData.latitude, longitude: createFormData.longitude,price_per_month: false}
    console.log(createdProperty);
    if (createdProperty === null) {
      const postRequest = await postFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}properties`, fullData);
      console.log(postRequest);
      setCreatedProperty(postRequest.property);
      setFileList(postRequest.property.images)

      toast({
        title: ' 👏تم انشاء الوحدة السكنية بنجاح',
        description: 'تم انشاء الوحدة السكنية بنجاح',

      })
      setCurrentTap("images")
    } else {
      console.log("put")
      if (fileList.length < 0) {
        toast({
          title: ' 🚨 الرجاء اختيار صور للوحدة السكنية',
          description: 'الرجاء اختيار صور للوحدة السكنية',
        })
        return
      }
      const valid = await trigger(["facilities"])
      console.log(valid)
      // @ts-ignore
      putFormData.id = createdProperty.id;
      if (fileList.length > 0) {





        const putRequest = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties/${putFormData.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
              "Accept": "application/json",
            },
            body: JSON.stringify({
              ...putFormData,
                ...fullData,


            })
        })
        if (putRequest.status === 200) {
          toast({
            title: ' 👏تم تعديل الوحدة السكنية بنجاح',
            description: 'تم تعديل الوحدة السكنية بنجاح',

          })
          setCurrentStep(3)
          setCurrentTap("finiance")
          if (currentTap === "finiance") {
            navigate(`/admin/properties`)
          }


        } else {
            toast({
                title: ' 🚨 حدث خطأ اثناء تعديل الوحدة السكنية',
                description: 'حدث خطأ اثناء تعديل الوحدة السكنية',

            })
        }

      } else {
        toast({
            title: ' 🚨 الرجاء اختيار صور للوحدة السكنية',
            description: 'الرجاء اختيار صور للوحدة السكنية',
        })
      }

    }







  };


  const [createFormData, setCreateFormData] = useState({
    titleEn: "",
    titleAr: "",
    descriptionAr: "",
    descriptionEn: "",
    area: null,
    num_of_guests: null,
    num_of_bedrooms: null,
    num_of_single_beds: null,
    num_of_double_beds: null,
    num_of_bathrooms: null,
    city_id: 0,
    country_id: 0,
    category_id: 0,
    status: "",
    latitude: 0,
    longitude: 0,

  });
  const [putFormData, setPutFormData] = useState({
    cancelation_days: 0,
    images: [],
    price_per_hour: false,
    six_hour_active: false,
    twelve_hour_active: false,
    hour_price: 1,
    system_cancelation: false,
    refund_insurance: 0,
    refund_insurance_flag: false,
    check_in_time: "12:00",
    check_out_time: "12:00",
    six_hour_checkin: "12:00",
    six_hour_checkout: "12:00",
    twelve_hour_checkin: "12:00",
    twelve_hour_checkout: "12:00",
    facilityOptions: [],
    mainFacilities: [],
    price_rest_days: 0,
    price_saturday: 0,
    price_friday: 0,
    price_thursday: 0,
    twelve_hour_price: 0,
    six_hour_price: 0,
    monthly_price: 0,
    price: 0,
    id: 0,
    ...createFormData,
  });
  useEffect(() => {
    setCreateFormData(prevState => ({
      ...prevState,
      latitude: currentMarker.lat,
      longitude: currentMarker.lng,
    }));
  }, [currentMarker]);





  const MarkerComponent = ({ lat, lng, text }: any) => (
    <div><LocationAdd size="32" color="#2ccce4" variant="Bulk" /></div>
  );
  const handleMapClick = (location: { lat: number; lng: number }) => {
    setCurrentMarker({
        lat: location.lat,
        lng: location.lng

    })
    setCreateFormData((prevState: any) => ({
        ...prevState,
        latitude: location.lat,
        longitude: location.lng
    }))

  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        const citesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}cities`);
        const countriesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}countries`);
        const facilitiesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}facilities`);
        const categoriesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}categories`);
        const nearestFacilitiesRequest = await getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}nearest-facilities`);
        setCities(citesRequest.cities)
        setCountries(countriesRequest.countries)
        setFacilities(facilitiesRequest.facilities)
        setCategories(categoriesRequest.categories)
        // @ts-ignore
        facilities.push(nearestFacilitiesRequest.nearest_facilities)


        setLoading(false)

      } catch (error) {
        dispatch(setIsAuth(false))
        dispatch(setUserToken(''))
        navigate('/auth/admin/login')

      }
    }


    fetchAllData()

  }, [])


  const handleMainFacilitiesChange = (value:any) => {
    setPutFormData(prevState => ({
      ...prevState,
      mainFacilities: value,
      facilityOptions: [],
    }));
  };

  // Determine if the selected facility has options
  const selectedFacilityWithOptions = facilities.find(facility =>
      // @ts-ignore
      putFormData.mainFacilities.includes(facility.id) && facility.options.length > 0
  );





  // @ts-ignore
  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
              <form id="create-property-from" onSubmit={handleSubmit(onSubmit)}  className="space-y-8">

                <div className="mx-6 mt-6">
                  <Card>
                    <CardHeader>
                      <div className="flex items-center justify-between">
                        <h1 className="text-xl">أعدادات الوحدة السكنية</h1>
                        <Button type="submit" >
                          التالي
                        </Button>

                      </div>

                    </CardHeader>
                  </Card>
                </div>
                <div className=" mx-6 mt-6">

                  <Card style={{direction: "rtl"}}>
                    <Tabs onValueChange={async (value) => {
                      console.log(value)
                      if (value === "password") {
                       const falidation = await trigger(["titleEn", "titleAr", "descriptionEn", "descriptionAr","status","category_id" ])
                        if (falidation) {
                          setCurrentTap("password")
                        }
                      }
                      if (value == "account") {
                        setCurrentTap("account")
                      }
                      if (value == "property_info") {
                        const falidation = await trigger(["city_id", "country_id", "category_id"])
                        if (falidation) {
                          setCurrentTap("property_info")
                        }
                      }
                      if(value == "images" && createdProperty){
                        setCurrentTap("images")

                      }
                      if (value == "finiance" && createdProperty && currentStep === 3) {
                        setCurrentTap("finiance")
                      }



                    }} value={currentTap} style={{direction: "rtl"}} defaultValue="account" className="w-full">
                      <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger value="account">
                          المعلومات الاساسية
                        </TabsTrigger>
                        {/*<TabsTrigger value="password">معلومات الموقع</TabsTrigger>*/}
                        {/*<TabsTrigger value="property_info">معلومات الوحدة السكنية</TabsTrigger>*/}
                        <TabsTrigger value="images">صور الوحدة السكنية</TabsTrigger>

                        {/*{createdProperty && (*/}
                        {/*    <>*/}

                        {/*    </>*/}

                        {/*)}*/}

                        {/*{createdProperty && currentStep === 3 && (*/}
                        {/*    */}
                        {/*)}*/}
                        <TabsTrigger value="finiance">
                          المعلومات الماليه
                        </TabsTrigger>


                      </TabsList>

                      <TabsContent value="account">
                        <Card style={{direction: "rtl"}} className="mt-4">
                          <CardHeader>
                            <CardTitle>اسم الوحدة السكنية</CardTitle>
                            <CardDescription></CardDescription>
                          </CardHeader>
                          <CardContent style={{direction: "rtl"}}
                                       className="space-y-2 flex flex-row justify-between w-full items-baseline">
                            <div className="w-1/2">
                              <Label htmlFor="titleEn">بالأنجليزي</Label>
                              <Input
                                  {...register("titleEn", { required: true })}
                                  className="mt-2" id="titleEn" value={createFormData.titleEn}
                                     onChange={e => setCreateFormData(prevState => ({
                                       ...prevState,
                                       titleEn: e.target.value
                                     }))} name="titleEn"/>
                              {errors.titleEn && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                            <div className="mx-2 w-1/2">
                              <Label htmlFor="titleAr">بالعربي</Label>
                              <Input
                                  {...register("titleAr", { required: true })}
                                  className="mt-2" id="titleAr" value={createFormData.titleAr}
                                     onChange={e => setCreateFormData(prevState => ({
                                       ...prevState,
                                       titleAr: e.target.value
                                     }))} name="titleAr"/>
                              {errors.titleAr && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                          </CardContent>
                          <CardHeader className="mt-4">
                            <CardTitle className="mb-4">وصف الوحدة السكنية</CardTitle>
                            <CardDescription>
                              <div className="grid grid-cols-2">
                                <div className="mx-2">
                                  <Label className="my-2" htmlFor="descriptionEn">
                                    بالأنجليزي
                                  </Label>
                                  <Textarea
                                      {...register("descriptionEn", { required: true })}
                                      className="mt-2" id="descriptionEn" value={createFormData.descriptionEn}
                                            onChange={e => setCreateFormData(prevState => ({
                                              ...prevState,
                                              descriptionEn: e.target.value
                                            }))} name="descriptionEn"/>
                                    {errors.descriptionEn && <span className="text-red-500">هذا الحقل مطلوب</span>}
                                </div>
                                <div>
                                  <Label htmlFor="descriptionAr">
                                    بالعربي
                                  </Label>
                                  <Textarea
                                      {...register("descriptionAr", { required: true })}
                                      className="mt-2" id="descriptionAr" value={createFormData.descriptionAr}
                                            onChange={e => setCreateFormData(prevState => ({
                                              ...prevState,
                                              descriptionAr: e.target.value
                                            }))} name="descriptionAr"/>
                                  {errors.descriptionAr && <span className="text-red-500">هذا الحقل مطلوب</span>}
                                </div>
                              </div>
                            </CardDescription>
                          </CardHeader>
                          <CardHeader className="mt-4">
                            <CardTitle className="mb-4">معلومات الوحدة السكنية</CardTitle>
                            <CardDescription>
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <label htmlFor="category_id">فئة الوحدة السكنية</label>
                                  <Controller
                                      name="category_id"
                                      control={control}
                                      rules={{required: true}}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                onChange={(value) => field.onChange(value)}
                                                className="mt-2 w-full "
                                                placeholder="فئة الوحدة السكنية"
                                                options={categories.map((category: any) => ({
                                                    label: category.title,
                                                    value: category.id
                                                }))
                                                    }
                                            >

                                            </Select>
                                        )}
                                  />
                                  {errors.category_id && <span className="text-red-500 mt-12">هذا الحقل مطلوب</span>}
                                </div>

                                <div>
                                  <label htmlFor="status">حالة الوحدة السكنية</label>
                                  <Controller
                                        name="status"

                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                onChange={(value) => field.onChange(value)}
                                                className="mt-2 w-full "
                                                placeholder="حالة الوحدة السكنية"
                                                options={status.map((status: any) => ({
                                                    label: status.arabic,
                                                    value: status.english
                                                }))
                                                }
                                            >

                                            </Select>
                                        )}
                                  />
                                  {errors.status && <span className="text-red-500">هذا الحقل مطلوب</span>}
                                </div>
                              </div>
                            </CardDescription>
                          </CardHeader>

                        </Card>
                        <Card style={{direction: "rtl"}}>
                          <CardHeader>
                            <CardTitle>معلومات موقع الوحدة السكنية</CardTitle>
                          </CardHeader>
                          <CardContent className="space-y-2 grid grid-cols-2 gap-4 justify-between w-full items-baseline">
                            <div className="w-full">
                              <Label htmlFor="current">الدولة</Label>
                              <Controller
                                  name="country_id"

                                  control={control}
                                  rules={{required: true}}
                                  render={({field}) => (
                                      <Select
                                          {...field}
                                          onChange={(value) => field.onChange(value)}
                                          className="mt-2 w-full"
                                          placeholder="الدولة"
                                          options={countries.map((country: any) => ({
                                            label: country.title,
                                            value: country.id
                                          }))
                                          }
                                      >

                                      </Select>
                                  )}



                              />
                              {errors.country_id && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                            <div className="w-full">
                              <Label htmlFor="new">المدينة</Label>
                              <Controller
                                  name="city_id"
                                  control={control}
                                  rules={{required: true}}
                                  render={({field}) => (
                                      <Select
                                          {...field}
                                          onChange={(value) => field.onChange(value)}
                                          className="mt-2 w-full "
                                          placeholder="المدينة"
                                          options={cities.map((city: any) => ({
                                            label: city.title,
                                            value: city.id
                                          }))
                                          }
                                      >

                                      </Select>
                                  )}
                              />
                              {errors.city_id && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                            <div className="w-full">
                              <Label htmlFor="current">longtuide</Label>
                              <Input  value={currentMarker.lng} placeholder="longtuide" id="current"/>
                            </div>
                            <div className="w-full">
                              <Label htmlFor="new">latituide</Label>
                              <Input value={currentMarker.lat} placeholder="latituide" id="new"/>
                            </div>
                          </CardContent>
                          <div className="rounded-lg" style={{height: '400px', width: '100%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                onClick={(location) => handleMapClick(location)}
                            >
                              <MarkerComponent
                                  lat={currentMarker.lat}
                                  lng={currentMarker.lng}
                                  text="My Marker"
                              />
                            </GoogleMapReact>
                          </div>
                        </Card>
                        <Card style={{direction: "rtl"}}>
                          <CardHeader>
                            <CardTitle>معلومات الوحدة السكنية</CardTitle>
                          </CardHeader>
                          <CardContent className="space-y-2 flex gap-4 flex-row justify-between w-full items-baseline">
                            <div className="w-1/2">
                              <Label htmlFor="current">المساحة</Label>
                              <Input
                                  {...register("area", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}


                                  className="mt-2" id="current"
                                    // @ts-ignore
                                  value={createFormData.area}
                                  onChange={e => setCreateFormData((prevState:any) => ({
                                    ...prevState,
                                    area: e.target.value ? Number(e.target.value) : 0
                                  }))}


                                  name="area"/>
                              {errors.area && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>

                            <div className="w-1/2">
                              <Label htmlFor="new">عدد الضيوف</Label>
                              <Input
                                  {...register("num_of_guests", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                  className="mt-2"
                                  // @ts-ignore
                                  value={createFormData.num_of_guests}
                                  onChange={e => setCreateFormData((prevState:any) => ({
                                    ...prevState,
                                    num_of_guests: e.target.value ? Number(e.target.value) : 0
                                  }))} id="new"/>
                              {errors.num_of_guests && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                          </CardContent>
                          <CardContent className="space-y-2 flex gap-4 flex-row justify-between w-full items-baseline">
                            <div className="w-1/2">
                              <Label htmlFor="current">عدد الغرف</Label>
                              <Input
                                  {...register("num_of_bedrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                  className="mt-2" id="current"
                                  // @ts-ignore
                                  value={createFormData.num_of_bedrooms}
                                  onChange={e => setCreateFormData((prevState:any) => ({
                                    ...prevState,
                                    num_of_bedrooms: e.target.value ? Number(e.target.value) : 0
                                  }))} name="num_of_bedrooms"/>
                              {errors.num_of_bedrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}

                            </div>
                            <div className="mx-2 w-1/2">
                              <Label htmlFor="new">عدد الأسرة </Label>
                              <Input
                                  {...register("num_of_single_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                  className="mt-2" id="new"
                                    // @ts-ignore
                                  value={createFormData.num_of_single_beds}
                                  onChange={e => setCreateFormData((prevState:any) => ({
                                    ...prevState,
                                    num_of_single_beds: e.target.value ? Number(e.target.value) : 0
                                  }))} name="num_of_single_beds"
                                  defaultValue={""}
                              />
                              {errors.num_of_single_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>

                          </CardContent>
                          <CardContent className="space-y-2 flex gap-4 flex-row justify-between w-full items-baseline">
                            <div className="w-1/2">
                              <Label htmlFor="current">عدد دورات المياه</Label>
                              <Input
                                  {...register("num_of_bathrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                  className="mt-2" id="current"
                                    // @ts-ignore
                                  value={createFormData.num_of_bathrooms}
                                  onChange={e => setCreateFormData((prevState:any) => ({
                                    ...prevState,
                                    num_of_bathrooms: e.target.value ? Number(e.target.value) : 0
                                  }))} name="num_of_bathrooms"/>
                              {errors.num_of_bathrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                            <div className="mx-2 w-1/2">
                              <Label htmlFor="new">عدد الأسرة المزودجه</Label>
                              <Input
                                  {...register("num_of_double_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}
                                  className="mt-2" id="new"
                                  // @ts-ignore
                                  value={createFormData.num_of_double_beds}
                                  onChange={e => setCreateFormData((prevState:any) => ({
                                    ...prevState,
                                    num_of_double_beds: e.target.value ? Number(e.target.value) : 0
                                  }))} name="num_of_double_beds"/>
                              {errors.num_of_double_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}
                            </div>
                          </CardContent>
                        </Card>
                      </TabsContent>


                      {/*<TabsContent value="password">*/}
                      {/*  <Card style={{direction: "rtl"}}>*/}
                      {/*    <CardHeader>*/}
                      {/*      <CardTitle>معلومات موقع الوحدة السكنية</CardTitle>*/}
                      {/*    </CardHeader>*/}
                      {/*    <CardContent className="space-y-2 grid grid-cols-2 gap-4 justify-between w-full items-baseline">*/}
                      {/*      <div className="w-full">*/}
                      {/*        <Label htmlFor="current">الدولة</Label>*/}
                      {/*        <Controller*/}
                      {/*              name="country_id"*/}

                      {/*              control={control}*/}
                      {/*              rules={{required: true}}*/}
                      {/*              render={({field}) => (*/}
                      {/*                  <Select*/}
                      {/*                      {...field}*/}
                      {/*                      onChange={(value) => field.onChange(value)}*/}
                      {/*                      className="mt-2 w-full"*/}
                      {/*                      placeholder="الدولة"*/}
                      {/*                      options={countries.map((country: any) => ({*/}
                      {/*                          label: country.title,*/}
                      {/*                          value: country.id*/}
                      {/*                      }))*/}
                      {/*                      }*/}
                      {/*                  >*/}

                      {/*                  </Select>*/}
                      {/*              )}*/}



                      {/*        />*/}
                      {/*        {errors.country_id && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}
                      {/*      <div className="w-full">*/}
                      {/*          <Label htmlFor="new">المدينة</Label>*/}
                      {/*          <Controller*/}
                      {/*                  name="city_id"*/}
                      {/*                  control={control}*/}
                      {/*                  rules={{required: true}}*/}
                      {/*                  render={({field}) => (*/}
                      {/*                      <Select*/}
                      {/*                          {...field}*/}
                      {/*                          onChange={(value) => field.onChange(value)}*/}
                      {/*                          className="mt-2 w-full "*/}
                      {/*                          placeholder="المدينة"*/}
                      {/*                          options={cities.map((city: any) => ({*/}
                      {/*                              label: city.title,*/}
                      {/*                              value: city.id*/}
                      {/*                          }))*/}
                      {/*                          }*/}
                      {/*                      >*/}

                      {/*                      </Select>*/}
                      {/*                  )}*/}
                      {/*          />*/}
                      {/*          {errors.city_id && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}
                      {/*      <div className="w-full">*/}
                      {/*          <Label htmlFor="current">longtuide</Label>*/}
                      {/*          <Input  value={currentMarker.lng} placeholder="longtuide" id="current"/>*/}
                      {/*      </div>*/}
                      {/*      <div className="w-full">*/}
                      {/*        <Label htmlFor="new">latituide</Label>*/}
                      {/*        <Input value={currentMarker.lat} placeholder="latituide" id="new"/>*/}
                      {/*      </div>*/}
                      {/*    </CardContent>*/}
                      {/*    <div className="rounded-lg" style={{height: '400px', width: '100%'}}>*/}
                      {/*      <GoogleMapReact*/}
                      {/*          bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}}*/}
                      {/*          defaultCenter={defaultProps.center}*/}
                      {/*          defaultZoom={defaultProps.zoom}*/}
                      {/*          onClick={(location) => handleMapClick(location)}*/}
                      {/*      >*/}
                      {/*        <MarkerComponent*/}
                      {/*            lat={currentMarker.lat}*/}
                      {/*            lng={currentMarker.lng}*/}
                      {/*            text="My Marker"*/}
                      {/*        />*/}
                      {/*      </GoogleMapReact>*/}
                      {/*    </div>*/}
                      {/*  </Card>*/}
                      {/*</TabsContent>*/}

                      {/*<TabsContent value="property_info">*/}
                      {/*  <Card style={{direction: "rtl"}}>*/}
                      {/*    <CardHeader>*/}
                      {/*      <CardTitle>معلومات الوحدة السكنية</CardTitle>*/}
                      {/*    </CardHeader>*/}
                      {/*    <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">*/}
                      {/*      <div className="w-1/2">*/}
                      {/*        <Label htmlFor="current">المساحة</Label>*/}
                      {/*        <Input*/}
                      {/*            {...register("area", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}


                      {/*            className="mt-2" id="current" value={createFormData.area}*/}
                      {/*              onChange={e => setCreateFormData(prevState => ({*/}
                      {/*                  ...prevState,*/}
                      {/*                  area: e.target.value ? Number(e.target.value) : 0*/}
                      {/*              }))}*/}


                      {/*            name="area"/>*/}
                      {/*        {errors.area && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}

                      {/*      <div className="w-1/2">*/}
                      {/*        <Label htmlFor="new">عدد الضيوف</Label>*/}
                      {/*          <Input*/}
                      {/*              {...register("num_of_guests", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                      {/*              className="mt-2" value={createFormData.num_of_guests}*/}
                      {/*              onChange={e => setCreateFormData(prevState => ({*/}
                      {/*                  ...prevState,*/}
                      {/*                  num_of_guests: e.target.value ? Number(e.target.value) : 0*/}
                      {/*              }))} id="new"/>*/}
                      {/*          {errors.num_of_guests && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}
                      {/*    </CardContent>*/}
                      {/*    <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">*/}
                      {/*     <div className="w-1/2">*/}
                      {/*      <Label htmlFor="current">عدد الغرف</Label>*/}
                      {/*      <Input*/}
                      {/*          {...register("num_of_bedrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                      {/*          className="mt-2" id="current" value={createFormData.num_of_bedrooms}*/}
                      {/*          onChange={e => setCreateFormData(prevState => ({*/}
                      {/*              ...prevState,*/}
                      {/*              num_of_bedrooms: e.target.value ? Number(e.target.value) : 0*/}
                      {/*          }))} name="num_of_bedrooms"/>*/}
                      {/*      {errors.num_of_bedrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}

                      {/*      </div>*/}
                      {/*      <div className="mx-2 w-1/2">*/}
                      {/*        <Label htmlFor="new">عدد الأسرة </Label>*/}
                      {/*          <Input*/}
                      {/*              {...register("num_of_single_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                      {/*              className="mt-2" id="new" value={createFormData.num_of_single_beds}*/}
                      {/*              onChange={e => setCreateFormData(prevState => ({*/}
                      {/*                  ...prevState,*/}
                      {/*                  num_of_single_beds: e.target.value ? Number(e.target.value) : 0*/}
                      {/*              }))} name="num_of_single_beds"/>*/}
                      {/*          {errors.num_of_single_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}

                      {/*    </CardContent>*/}
                      {/*    <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">*/}
                      {/*      <div className="w-1/2">*/}
                      {/*        <Label htmlFor="current">عدد دورات المياه</Label>*/}
                      {/*        <Input*/}
                      {/*            {...register("num_of_bathrooms", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                      {/*            className="mt-2" id="current" value={createFormData.num_of_bathrooms}*/}
                      {/*            onChange={e => setCreateFormData(prevState => ({*/}
                      {/*              ...prevState,*/}
                      {/*              num_of_bathrooms: e.target.value ? Number(e.target.value) : 0*/}
                      {/*            }))} name="num_of_bathrooms"/>*/}
                      {/*        {errors.num_of_bathrooms && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}
                      {/*      <div className="mx-2 w-1/2">*/}
                      {/*        <Label htmlFor="new">عدد الأسرة المزودجه</Label>*/}
                      {/*        <Input*/}
                      {/*            {...register("num_of_double_beds", { required: true, validate: value => value !== null && value !== undefined && !isNaN(value) })}*/}
                      {/*            className="mt-2" id="new" value={createFormData.num_of_double_beds}*/}
                      {/*            onChange={e => setCreateFormData(prevState => ({*/}
                      {/*              ...prevState,*/}
                      {/*              num_of_double_beds: e.target.value ? Number(e.target.value) : 0*/}
                      {/*            }))} name="num_of_double_beds"/>*/}
                      {/*        {errors.num_of_double_beds && <span className="text-red-500">هذا الحقل مطلوب</span>}*/}
                      {/*      </div>*/}
                      {/*    </CardContent>*/}
                      {/*  </Card>*/}
                      {/*</TabsContent>*/}

                      <TabsContent value="images">
                        <Card style={{direction: "rtl"}}>
                          <CardHeader>
                            <CardTitle>صور الوحدة السكنية</CardTitle>
                          </CardHeader>
                          <CardContent>
                            <Upload
                                multiple={true}
                                name="file"
                                action={`${process.env.REACT_APP_DEV_ADMIN_API_URL}upload/properties`}
                                listType="picture"
                                headers={{
                                  Authorization: `Bearer ${token}`,
                                }}
                                fileList={fileList}
                                onChange={(info) => {
                                  const { status } = info.file;
                                  if (status !== 'uploading') {
                                    // Do something while uploading
                                  }
                                  if (status === 'done') {
                                    message.success(`${info.file.name} file uploaded successfully.`);
                                    const uploadedImageUrl = info.file.response.data;

                                    setPutFormData((prevState:any) => ({
                                      ...prevState,
                                      images: [
                                        ...prevState.images,
                                        {
                                          url: uploadedImageUrl
                                        }
                                      ]
                                    }));
                                  } else if (status === 'error') {
                                    message.error(`${info.file.name} file upload failed.`);
                                  }
                                  setFileList(info.fileList);
                                }}
                                onRemove={(file) => {
                                  setFileList((prevFileList) => {
                                    const index = prevFileList.indexOf(file);
                                    const newFileList = prevFileList.slice();
                                    newFileList.splice(index, 1);
                                    return newFileList;
                                  });
                                  setPutFormData((prevState:any) => ({
                                    ...prevState,
                                    images: prevState.images.filter((image: { url: string | undefined; }) => image.url !== file.url)
                                  }));
                                }}
                            >
                              <Button type="button">Click to Upload</Button>
                            </Upload>

                            {errors.images && <span className="text-red-500">هذا الحقل مطلوب</span>}
                          </CardContent>
                          <CardContent>
                            <div className="w-full">
                              <Label htmlFor="current">المرافق</Label>
                              <Controller
                                  name="facilities"
                                  control={control}
                                  rules={{ required: 'هذا الحقل مطلوب', validate: value => value.length > 0 }}
                                  render={({ field }) => (
                                      <Select
                                          {...field}
                                          mode="multiple"
                                          defaultValue={putFormData.mainFacilities}
                                          onChange={(value) => {
                                            field.onChange(value);
                                            handleMainFacilitiesChange(value);
                                          }}
                                          showSearch
                                          optionFilterProp={"label"}

                                          className="mt-2 w-full"
                                          placeholder="المرافق"
                                          virtual={facilities.length > 100}
                                          options={facilities.map((facility: any) => ({
                                            label: facility.title,
                                            value: facility.id
                                          }))}
                                      />
                                  )}
                              />
                              {errors.facilities && <span className="text-red-500">
                                الرجاء اختيار مرفق واحد على الأقل
                              </span>}
                              {selectedFacilityWithOptions && (
                                  <Controller
                                      name="facilityOptions"
                                      control={control}
                                      rules={{ required: 'هذا الحقل مطلوب' }}
                                      render={({ field }) => (
                                          <Select
                                              {...field}
                                              mode="multiple"
                                              defaultValue={putFormData.facilityOptions}
                                              onChange={(value) => {
                                                field.onChange(value);
                                                setPutFormData(prevState => ({
                                                  ...prevState,
                                                  facilityOptions: value,
                                                }));
                                              }}
                                              className="mt-2 w-full"
                                              placeholder="خيارات المرافق"
                                              virtual={facilities.length > 100}
                                              // @ts-ignore
                                              options={selectedFacilityWithOptions.options.map((option: any) => ({
                                                label: option.title,
                                                value: option.id
                                              }))}
                                          />
                                      )}
                                  />
                              )}
                              {errors.facilityOptions && <span className="text-red-500">
                                الرجاء اختيار خيار واحد على الأقل
                              </span>}
                              {/*<div className="mt-12">*/}
                              {/*  <Label htmlFor="current">*/}
                              {/*    المرافق القريبة*/}
                              {/*  </Label>*/}
                              {/*  <Controller*/}
                              {/*      name="nearFacilities"*/}
                              {/*      control={control}*/}
                              {/*      rules={{ required: 'هذا الحقل مطلوب', validate: value => value.length > 0 }}*/}
                              {/*      render={({ field }) => (*/}
                              {/*          <Select*/}
                              {/*              {...field}*/}
                              {/*              mode="multiple"*/}
                              {/*              defaultValue={putFormData.mainFacilities}*/}
                              {/*              onChange={(value) => {*/}
                              {/*                field.onChange(value);*/}
                              {/*                setPutFormData(prevState => ({*/}
                              {/*                  ...prevState,*/}
                              {/*                  mainFacilities: value,*/}
                              {/*                }));*/}
                              {/*              }}*/}
                              {/*              className="mt-2 w-full"*/}
                              {/*              placeholder="المرافق"*/}
                              {/*              virtual={nearestFacilities.length > 100}*/}
                              {/*              options={nearestFacilities.map((nearestFacilities: any) => ({*/}
                              {/*                label: nearestFacilities.title,*/}
                              {/*                value: nearestFacilities.id*/}
                              {/*              }))}*/}
                              {/*          />*/}
                              {/*      )}*/}
                              {/*  />*/}

                              {/*</div>*/}


                            </div>
                          </CardContent>
                        </Card>
                      </TabsContent>

                      <TabsContent value="finiance">
                        {currentStep === 3 && (
                            <Card style={{ direction: "rtl" }} className="mt-4">
                              <CardHeader>
                                <CardTitle>أسعار الوحدة السكنية</CardTitle>
                              </CardHeader>
                              <CardContent className="space-y-2 gap-4 grid-cols-2 justify-between w-full ">
                                {/* Price Thursday */}
                                <div className="grid-cols-2 gap-4 grid">
                                  <div className="w-full">
                                    <Label htmlFor="price_thursday">سعر الوحدة السكنية يوم الخميس</Label>
                                    <Input
                                        {...register("price_thursday", {
                                          required: "هذا الحقل مطلوب",
                                          validate: value => !isNaN(value) && value.trim() !== ''
                                        })}
                                        className="mt-2"
                                        type="number"
                                        id="price_thursday"
                                        name="price_thursday"/>
                                    {errors.price_thursday && <span className="text-red-500">
                                 الحقل مطلوب
                              </span>}
                                  </div>

                                  {/* Price Friday */}
                                  <div className="w-full">
                                    <Label htmlFor="price_friday">سعر الوحدة السكنية يوم الجمعة</Label>
                                    <Input
                                        {...register("price_friday", {
                                          required: "هذا الحقل مطلوب",
                                          validate: value => !isNaN(value) && value.trim() !== ''
                                        })}
                                        className="mt-2"
                                        type="number"
                                        id="price_friday"
                                        name="price_friday"/>
                                    {errors.price_friday && <span className="text-red-500">
                                الحقل مطلوب
                              </span>}
                                  </div>
                                </div>

                                {/* Price Saturday */}
                                <div className="grid-cols-2 gap-4 grid">
                                  <div className="w-full">
                                    <Label htmlFor="price_saturday">سعر الوحدة السكنية يوم السبت</Label>
                                    <Input
                                        {...register("price_saturday", {
                                          required: "هذا الحقل مطلوب",
                                          validate: value => !isNaN(value) && value.trim() !== ''
                                        })}
                                        className="mt-2"
                                        type="number"
                                        id="price_saturday"
                                        name="price_saturday"/>
                                    {errors.price_saturday && <span className="text-red-500">
                                 الحقل مطلوب
                              </span>}
                                  </div>

                                  {/* Price Rest Days */}
                                  <div className="w-full">
                                    <Label htmlFor="price_rest_days">سعر الوحدة السكنية في الأيام العادية</Label>
                                    <Input
                                        {...register("price_rest_days", {
                                          required: "هذا الحقل مطلوب",
                                          validate: value => !isNaN(value) && value.trim() !== ''
                                        })}
                                        className="mt-2"
                                        type="number"
                                        id="price_rest_days"
                                        name="price_rest_days"/>
                                    {errors.price_rest_days && <span className="text-red-500">
                                 الحقل مطلوب
                              </span>}

                                  </div>
                                </div>


                                <div className="grid grid-cols-1 gap-4 mt-12">
                                  <div className="flex items-center">
                                    <Label>وقت الدخول</Label>
                                    <Controller
                                        name="check_in_time"
                                        control={control}
                                        defaultValue={"00:00"}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TimePicker
                                                {...field}
                                                value={field.value ? dayjs(field.value, 'HH:mm') : null}
                                                onChange={(time, timeString:any) => {
                                                  if (!timeString) {
                                                    field.onChange(null);
                                                  } else {
                                                    setPutFormData(prevState => ({
                                                      ...prevState,
                                                      check_in_time: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                    }));
                                                    field.onChange(timeString);
                                                  }
                                                }}
                                                format="HH:mm"
                                                className="mx-2"
                                            />
                                        )}
                                    />
                                    {errors.check_in_time &&
                                        <span className="text-red-500 mr-4">
                                          الرجاء اختيار وقت الدخول
                                        </span>}
                                  </div>

                                  <div className="flex items-center">
                                    <Label>وقت الخروج</Label>
                                    <Controller
                                        name="check_out_time"
                                        control={control}
                                        defaultValue={"00:00"}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TimePicker
                                                {...field}
                                                value={field.value ? dayjs(field.value, 'HH:mm') : null}
                                                onChange={(time, timeString:any) => {
                                                  if (!timeString) {
                                                    field.onChange(null);
                                                  } else {
                                                    setPutFormData(prevState => ({
                                                      ...prevState,
                                                      check_in_time: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                    }));
                                                    field.onChange(timeString);
                                                  }
                                                }}
                                                format="HH:mm"
                                                className="mx-2"
                                            />
                                        )}
                                    />
                                    {errors.check_out_time &&
                                        <span className="text-red-500 mr-4">
                                            الرجاء اختيار وقت الخروج
                                        </span>}
                                  </div>


                                  <Controller
                                      name="freeCancellation"
                                      control={control}
                                      render={({field}) => (
                                          <div className="flex items-center gap-4">
                                            <Label>الغاء مجاني للحجز</Label>
                                            <Checkbox {...field} checked={field.value}
                                                      onChange={(e) => {
                                                        field.onChange(e.target.checked);
                                                        setPutFormData((prevState:any) => ({
                                                          ...prevState,
                                                          system_cancelation: e.target.checked ? 1 : 0
                                                        }));
                                                      }}>
                                              مفعل
                                            </Checkbox>
                                          </div>
                                      )}
                                  />











                                  <Controller
                                      name="insuranceRefunded"
                                      control={control}
                                      render={({field}) => (
                                          <div className="flex items-center gap-4">
                                            <Label>التأمين مسترد</Label>
                                            <Checkbox

                                                {...field}



                                                checked={field.value}
                                                      onChange={(e) => {
                                                        field.onChange(e.target.checked);
                                                        setPutFormData((prevState:any) => ({
                                                          ...prevState,
                                                          refund_insurance_flag: e.target.checked ? 1 : 0
                                                        }));
                                                      }}>
                                              مفعل
                                            </Checkbox>
                                            {field.value && (
                                                <div className="flex items-center gap-4">
                                                  <Label htmlFor="refund_insurance">سعر التأمين</Label>
                                                  <Input {...register("refund_insurance", field.value ? {required: "هذا الحقل مطلوب"} : {})}
                                                         className="mt-2" type="number" id="refund_insurance"
                                                         name="refund_insurance"
                                                        onChange={e => setPutFormData(prevState => ({
                                                            ...prevState,
                                                            refund_insurance: parseInt(e.target.value)
                                                        }))}
                                                  />
                                                  {errors.refund_insurance && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                                </div>
                                            )}
                                          </div>
                                      )}
                                  />
                                  <Controller
                                      name="price_per_month"
                                      control={control}
                                      render={({field}) => (
                                          <div className="flex items-center gap-4">
                                            <Label>
                                              التأجير بالشهر
                                            </Label>
                                            <Checkbox

                                                {...field}



                                                checked={field.value}
                                                onChange={(e) => {
                                                  field.onChange(e.target.checked);
                                                  setPutFormData((prevState:any) => ({
                                                    ...prevState,
                                                    price_per_month: e.target.checked ? 1 : 0
                                                  }));
                                                }}>
                                              مفعل
                                            </Checkbox>
                                            {field.value && (
                                                <div className="flex items-center gap-4">
                                                  <Label htmlFor="monthly_price">
                                                    السعر
                                                  </Label>
                                                  <Input {...register("monthly_price", field.value ? {required: "هذا الحقل مطلوب"} : {})}
                                                         className="mt-2" type="number" id="monthly_price"
                                                         name="monthly_price"
                                                         onChange={e => setPutFormData(prevState => ({
                                                           ...prevState,
                                                           monthly_price: parseInt(e.target.value)
                                                         }))}
                                                  />
                                                  {errors.monthly_price && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                                </div>
                                            )}
                                          </div>
                                      )}
                                  />

                                  <Controller
                                      name="six_hour_active"
                                      control={control}
                                      render={({field}) => (
                                          <div className="gap-4 flex items-center">
                                            <Label>
                                              التأجير كل 6 ساعات
                                            </Label>
                                            <Checkbox

                                                {...field}



                                                checked={putFormData.six_hour_active}
                                                onChange={(e) => {
                                                  field.onChange(e.target.checked);
                                                  setPutFormData((prevState:any) => ({
                                                    ...prevState,
                                                    six_hour_active: e.target.checked ? true : false
                                                  }));
                                                }}>
                                              مفعل
                                            </Checkbox>

                                            <div className="flex items-center w-full  gap-4">
                                              <div className="flex gap-4 w-full  items-center">

                                                {!!putFormData.six_hour_active && (

                                                    <div className="flex w-full items-center gap-4">
                                                      <Label htmlFor="twelve_hour_price">
                                                        السعر
                                                      </Label>
                                                      <Input {...register("six_hour_price", field.value ? {required: "هذا الحقل مطلوب"} : {})}
                                                             className="mt-2 w-1/2" type="number" id="six_hour_price"
                                                             name="six_hour_price"
                                                             value={putFormData.six_hour_price}
                                                             onChange={e => setPutFormData(prevState => ({
                                                               ...prevState,
                                                               six_hour_price: parseInt(e.target.value)
                                                             }))}
                                                      />
                                                      <div className="flex items-center  gap-4">
                                                        <Label>
                                                          وقت الدخول
                                                        </Label>
                                                        <Controller
                                                            name="six_hour_checkin"
                                                            control={control}
                                                            defaultValue={putFormData.six_hour_checkin}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <TimePicker
                                                                    {...field}
                                                                    value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                    onChange={(time, timeString:any) => {
                                                                      if (!timeString) {
                                                                        field.onChange(null);
                                                                      } else {
                                                                        setPutFormData(prevState => ({
                                                                          ...prevState,
                                                                          six_hour_checkin: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                        }));
                                                                        field.onChange(timeString);
                                                                      }
                                                                    }}
                                                                    format="HH:mm"
                                                                    className="mx-2"
                                                                />
                                                            )}
                                                        />

                                                        {errors.six_hour_checkin && <span className="text-red-500">This field is required</span>}
                                                      </div>

                                                      <div className="flex items-center gap-4">
                                                        <Label>
                                                          وقت الخروج
                                                        </Label>
                                                        <Controller
                                                            name="six_hour_checkout"
                                                            control={control}
                                                            defaultValue={putFormData.six_hour_checkout}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <TimePicker
                                                                    {...field}
                                                                    value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                    onChange={(time, timeString:any) => {
                                                                      if (!timeString) {
                                                                        field.onChange(null);
                                                                      } else {
                                                                        setPutFormData(prevState => ({
                                                                          ...prevState,
                                                                          six_hour_checkout: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                        }));
                                                                        field.onChange(timeString);
                                                                      }
                                                                    }}
                                                                    format="HH:mm"
                                                                    className="mx-2"
                                                                />
                                                            )}
                                                        />


                                                        {errors.six_hour_checkout && <span className="text-red-500">This field is required</span>}
                                                      </div>
                                                    </div>
                                                )}

                                              </div>

                                              {errors.six_hour_price && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                            </div>

                                          </div>
                                      )}
                                  />



                                  <Controller
                                      name="twelve_hour_active"
                                      control={control}
                                      render={({field}) => (
                                          <div className="gap-4 flex items-center">
                                            <Label>
                                              التأجير كل 12 ساعة
                                            </Label>
                                            <Checkbox

                                                {...field}



                                                checked={putFormData.twelve_hour_active}
                                                onChange={(e) => {
                                                  field.onChange(e.target.checked);
                                                  setPutFormData((prevState:any) => ({
                                                    ...prevState,
                                                    twelve_hour_active: e.target.checked ? true : false
                                                  }));
                                                }}>
                                              مفعل
                                            </Checkbox>

                                            <div className="flex items-center w-full  gap-4">
                                              <div className="flex gap-4 w-full  items-center">

                                                {!!putFormData.twelve_hour_active && (

                                                    <div className="flex w-full items-center gap-4">
                                                      <Label htmlFor="twelve_hour_price">
                                                        السعر
                                                      </Label>
                                                      <Input {...register("twelve_hour_price", field.value ? {required: "هذا الحقل مطلوب"} : {})}
                                                             className="mt-2 w-1/2" type="number" id="twelve_hour_price"
                                                             name="twelve_hour_price"
                                                             value={putFormData.twelve_hour_price}
                                                             onChange={e => setPutFormData(prevState => ({
                                                               ...prevState,
                                                               twelve_hour_price: parseInt(e.target.value)
                                                             }))}
                                                      />
                                                      <div className="flex items-center  gap-4">
                                                        <Label>
                                                          وقت الدخول
                                                        </Label>
                                                        <Controller
                                                            name="twelve_hour_checkin"
                                                            control={control}
                                                            defaultValue={putFormData.twelve_hour_checkin}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <TimePicker
                                                                    {...field}
                                                                    value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                    onChange={(time, timeString:any) => {
                                                                      if (!timeString) {
                                                                        field.onChange(null);
                                                                      } else {
                                                                        setPutFormData(prevState => ({
                                                                          ...prevState,
                                                                          twelve_hour_checkin: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                        }));
                                                                        field.onChange(timeString);
                                                                      }
                                                                    }}
                                                                    format="HH:mm"
                                                                    className="mx-2"
                                                                />
                                                            )}
                                                        />

                                                        {errors.twelve_hour_checkin && <span className="text-red-500">This field is required</span>}
                                                      </div>

                                                      <div className="flex items-center gap-4">
                                                        <Label>
                                                          وقت الخروج
                                                        </Label>
                                                        <Controller
                                                            name="twelve_hour_checkout"
                                                            control={control}
                                                            defaultValue={putFormData.twelve_hour_checkout}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <TimePicker
                                                                    {...field}
                                                                    value={field.value ? dayjs(field.value, 'HH:mm') : null} // Convert value to dayjs object if it exists
                                                                    onChange={(time, timeString:any) => {
                                                                      if (!timeString) {
                                                                        field.onChange(null);
                                                                      } else {
                                                                        setPutFormData(prevState => ({
                                                                          ...prevState,
                                                                          twelve_hour_checkout: dayjs(timeString, 'HH:mm').format('HH:mm')
                                                                        }));
                                                                        field.onChange(timeString);
                                                                      }
                                                                    }}
                                                                    format="HH:mm"
                                                                    className="mx-2"
                                                                />
                                                            )}
                                                        />


                                                        {errors.twelve_hour_checkout && <span className="text-red-500">This field is required</span>}
                                                      </div>
                                                    </div>
                                                )}

                                              </div>

                                              {errors.twelve_hour_price && <span className="text-red-500">
                                                    الحقل مطلوب
                                                  </span>}
                                            </div>

                                          </div>
                                      )}
                                  />

                                </div>


                              </CardContent>


                            </Card>

                        )}
                      </TabsContent>

                    </Tabs>
                  </Card>

                </div>
              </form>


          </Content>
        </>
      )}
    </>
  );
}
