import { createSlice,PayloadAction } from '@reduxjs/toolkit'

export interface userState {
    token: string
    is_auth:boolean,
    token_expiration:any,
    name: string
    role: string
    image_url:string
}

const initialState: userState = {
    token: "",
    is_auth:false,
    token_expiration:null,
    name: "",
    role: "",
    image_url:""
    
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserToken: (state, action: PayloadAction<any>) => {
            state.token = action.payload;
        },
        setIsAuth: (state, action: PayloadAction<any>) => {
            state.is_auth = action.payload;
        },
        setTokenExpirationDate: (state, action: PayloadAction<any>) => {
            state.token_expiration = action.payload;
        },
        
        setName: (state, action: PayloadAction<any>) => {
            state.name = action.payload
        },
        setRole: (state, action: PayloadAction<any>) => {
            state.role = action.payload
        },
        setImage: (state, action: PayloadAction<any>) => {
            state.image_url = action.payload
        },

        
    },
})

export const {  setUserToken,setIsAuth,setTokenExpirationDate,setName,setRole,setImage } = userSlice.actions

export default userSlice.reducer

