import {Card, Checkbox, Layout} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { Bank, Bezier, Book, BookSquare, Category, Edit, House, Setting2, Trash, User, Location, Star, Star1, Personalcard, Eye, SecurityCard, Data2, Profile2User } from "iconsax-react";
import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { Icon } from "@iconify/react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "../../../components/ui/alert-dialog";
import {Label} from "../../../components/ui/label";
import {Input} from "../../../components/ui/input";

const { Content } = Layout;

export default function Users() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [currentSelected, setCurrentSelected] = useState("Super+Admin")
    const [data, setData] = useState([])

    const translations: { [key: string]: string }[] = [{
        "Customer": "العملاء",
        "Hosting": "أصحاب الوحدات السكنية",
        "Super+Admin": "المدراء"
    }];

 
    const selectedTranslation = translations.find((item) => item[currentSelected]);
    const translationText = selectedTranslation ? selectedTranslation[currentSelected] : '';
    const [open, setOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState<any>({})


    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users?status=${currentSelected}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.data.items)
            } else {

            }
        } catch (error) {
            dispatch(setIsAuth(false))
            dispatch(setUserToken(''))
            navigate('/auth/admin/login')
            console.error(error)
        }

    }


    useEffect(() => {
        window.document.title = "المستخدمين"

        fetchData()

    }, [currentSelected])




    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                <Data2 variant="Bulk"
                                                    size="32" color="#475467" />

                                            </div>
                                            <div>
                                                <h1 className="text-xl">المستخدمين ( {translationText} )</h1>
                                            </div>



                                        </div>

                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Icon width={24} height={24} className="cursor-pointer" icon="pepicons-pop:dots-y" />

                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="w-56">
                                                <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                <DropdownMenuSeparator />
                                                <DropdownMenuGroup>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("Super+Admin")}>
                                                        <SecurityCard
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>المدراء</span>
                                                    </DropdownMenuItem>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("Hosting")}>
                                                        <House
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>أصحاب الوحدات السكنية</span>
                                                    </DropdownMenuItem>
                                                    <DropdownMenuItem onSelect={() => setCurrentSelected("Customer")}>
                                                        <Profile2User
                                                            color="#475467"
                                                            variant="Bulk"
                                                            className="ml-2 h-8 w-8"
                                                        />
                                                        <span>العملاء</span>
                                                    </DropdownMenuItem>




                                                </DropdownMenuGroup>

                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>

                                </CardHeader>
                            </Card>
                        </div>
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mx-6 mt-6">


                            {data?.map((item: any) => (
                                <Card>
                                    <CardHeader>


                                        <div className="flex items-center justify-between flex-row">
                                            <div className="flex items-center">
                                                <div style={{ background: "#f9f9f9" }} className={"mx-2 p-2 rounded-md"}>
                                                    <Personalcard variant="Bulk"
                                                        size="32" color="#475467" />
                                                </div>
                                                <h1>
                                                    {item.first_name + " " + item.last_name}
                                                </h1>
                                                

                                            </div>

                                            <h1 className={`text-black mr-2 ${item.is_active ? "success-text" : "error-text"}`}>( {item.is_active ? "مفعل" : "غير مفعل"} )</h1>

                                        </div>

                                    </CardHeader>
                                    <CardContent>
                                        <span>
                                            {item.review}

                                        </span>
                                    </CardContent>
                                    <CardFooter>

                                        <div>
                                            {currentSelected == "Customer" || currentSelected === "Hosting"  ? (
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger asChild>
                                                            <Button>
                                                                تعديل
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent className="w-56">
                                                            <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                            <DropdownMenuSeparator />
                                                            <DropdownMenuGroup>


                                                                <DropdownMenuItem onClick={async () => {
                                                                    const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users/${item.id}`, {
                                                                        method: "DELETE",
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                            "Authorization": "Bearer " + token,
                                                                        }
                                                                    })
                                                                    const data = await request.json()
                                                                    if (request.status == 200) {
                                                                        toast.success("تم الحذف بنجاح")
                                                                        fetchData()
                                                                    } else {
                                                                        toast.error("حدث خطأ")
                                                                    }
                                                                }} >
                                                                    <Trash
                                                                        color="#F04438"
                                                                        variant="Bulk"
                                                                        className="ml-2 h-8 w-8"
                                                                    />
                                                                    <span>حذف</span>
                                                                </DropdownMenuItem>

                                                                <DropdownMenuItem onClick={() => {
                                                                    setOpen(true)
                                                                    setSelectedUser(item)
                                                                }} >
                                                                    <Edit
                                                                        color="#475467"
                                                                        variant="Bulk"
                                                                        className="ml-2 h-8 w-8"
                                                                    />
                                                                    <span>تعديل</span>
                                                                </DropdownMenuItem>

                                                            </DropdownMenuGroup>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>

                                            ) : (
                                                null
                                            )}

                                        </div>
                                    </CardFooter>
                                </Card>
                            ))}

                        </div>
                        <AlertDialog open={open}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>
                                        تعديل المستخدم
                                    </AlertDialogTitle>
                                    <AlertDialogDescription>
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <Label>
                                                    الأسم الاول
                                                </Label>
                                                <Input
                                                    value={selectedUser.first_name}
                                                    onChange={(event) => {
                                                        setSelectedUser({
                                                            ...selectedUser,
                                                            first_name: event.target.value
                                                        })

                                                    }}/>
                                            </div>
                                            <div>
                                                <Label>
                                                    الأسم الثاني
                                                </Label>
                                                <Input
                                                    value={selectedUser.last_name}
                                                    onChange={(event) => {
                                                        setSelectedUser({
                                                            ...selectedUser,
                                                            last_name: event.target.value
                                                        })

                                                    }}/>
                                            </div>
                                            <div>
                                                <Label>
                                                   رقم الهاتف
                                                </Label>
                                                <Input
                                                    value={selectedUser.phone}
                                                    onChange={(event) => {
                                                        setSelectedUser({
                                                            ...selectedUser,
                                                            phone: event.target.value
                                                        })

                                                    }}/>
                                            </div>
                                            <div>
                                                <Label>
                                                    البريد الالكتروني
                                                </Label>
                                                <Input
                                                    value={selectedUser.email}
                                                    onChange={(event) => {
                                                        setSelectedUser({...selectedUser, email: event.target.value})

                                                    }}/>
                                            </div>
                                            <div>
                                                <Label>
                                                    مفعل
                                                </Label>
                                                <Checkbox
                                                    checked={selectedUser.is_active}
                                                    onChange={(e) => {
                                                        setSelectedUser({...selectedUser, is_active: e.target.checked})
                                                    }}>

                                                </Checkbox>

                                            </div>
                                            <div>
                                                <Label>
                                                    كلمة المرور
                                                </Label>
                                                <Input
                                                    value={selectedUser.password}
                                                    onChange={(event) => {
                                                        setSelectedUser({...selectedUser, password: event.target.value})

                                                    }}/>
                                            </div>

                                        </div>
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => setOpen(false)}>الغاء</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => {
                                        fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users/${selectedUser.id}`, {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + token,
                                            },
                                            body: JSON.stringify(selectedUser)
                                        }).then((response) => {
                                            if (response.status == 200) {
                                                toast.success("تم التعديل بنجاح")
                                                fetchData()
                                                setOpen(false)
                                            } else {
                                                toast.error("حدث خطأ")
                                            }
                                        })
                                    }}>
                                        تعديل
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>


                    </Content>
                </>
            )}




        </>
    )
}