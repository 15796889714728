import AuthContext from "./AuthContext";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {setImage, setIsAuth, setUserToken} from "../redux/slices/user_slice";
import { useNavigate } from "react-router-dom";


export const AuthProvider = ({ children }:any) => {
    const token = useAppSelector(state => state.user.token);
    const dispatch = useAppDispatch();


    useEffect(() => {
        console.log("Auth Provider");
        async function checkUser() {
          try {
              if (!token) {

              } else {
                  const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}get-account`, {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                          "Authorization": `Bearer ${token}`
                      }
                  });
                  if (request.status == 401 || request.status == 500) {
                      console.log("Unauthorized");
                      dispatch(setIsAuth(false));
                      dispatch(setUserToken(""));
                      window.location.href = "/auth/admin/login";
                  }


                  const response = await request.json();



              }

          }
            catch (e) {


            }

           }
        checkUser();
    }, []);



    return (
        <AuthContext.Provider value={""}>
    {children}
    </AuthContext.Provider>
);
};
