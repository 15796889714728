import {Card, DatePicker, Layout, Select} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  CalendarAdd,
  CalendarRemove,
  Clock,
  CardReceive,
  TransactionMinus, Star, User, Personalcard, House, Eye, CardSlash, Buliding,
} from "iconsax-react";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";
import useGetAllCategories from "src/hooks/categories/list";
import {BookingType} from "../../../types/booking";
import {useGetAllUsers} from "../../../hooks/users";

const { Content } = Layout;

export default function FinanceDetailsReservation() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData]:any = useState([]);
  const state = useLocation().state
  useEffect(() => {
    console.log(state)
    window.document.title = `التفاصيل الماليه`

  }, [])



  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                        <TransactionMinus size="48" color="#2dacf4" variant="Bulk" />

                      </div>
                      <h1 className="text-xl">
                        التفاصيل الماليه
                      </h1>

                    </div>



                  </div>
                </CardHeader>
              </Card>
            </div>
            <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
              {state && state?.length > 0 && state?.map((item: any) => (





                  <Card className="w-full">
                        <div className="flex items-center my-1 justify-between">
                          <div className="flex items-center">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                <Buliding size="48" color="#2dacf4" variant="Bulk"/>
                              </div>
                            </div>

                            <h1 className={"text-xl mr-2"}>
                              حجز رقم {item.id}#
                            </h1>



                          </div>

                          <div className="flex items-center gap-4">
                            <h1 className={"text-xl"}>
                              {item.total} ر.س
                            </h1>

                      </div>

                    </div>
                    <div className="items-center justify-between grid  gap-4 mt-6 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-5  ">
                      <div className="flex items-center gap-2">
                        <div
                            style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <TransactionMinus size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              رسوم الخدمة
                            </h4>
                            <h4>
                              {item.service}
                            </h4>
                          </div>

                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>

                              الضريبة
                            </h4>
                            <h4>
                              {item.tax} ر.س
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <User size="24" color="#7A5AF8" variant="Bulk"/>
                            </div>
                            <h4>
                              اسم العميل
                            </h4>
                            <h4>
                              {item.username}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              الحالة
                            </h4>
                            <h4>
                              {item.status}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              طريقة الدفع
                            </h4>
                            <h4>
                              {item.payment_method}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              نوع الحجز
                            </h4>
                            <h4>
                              {item.type}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                              تاريخ الحجز من
                            </h4>
                            <h4>
                              {item.reserved_from}
                            </h4>
                          </div>
                          <div className="flex items-center gap-2">
                            <div
                                style={{background: "#f9f9f9"}}
                                className={" p-2 rounded-md"}
                            >
                              <CardReceive size="24" color="#475467" variant="Bulk"/>
                            </div>
                            <h4>
                                تاريخ الحجز الى
                            </h4>
                            <h4>
                              {item.reserved_to}
                            </h4>
                          </div>


                        </div>
                      </Card>
              ))
              }
              {state && state.length === 0 && (
                <div className="flex justify-center items-center h-screen">
                  <h1 className="text-2xl">لا يوجد حجوزات</h1>
                </div>
                )
                }

            </div>


          </Content>
        </>
      )}
    </>
  );
}
