import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonEn from './locales/en/common.json';
import adminEn from './locales/en/admin.json';
import commonAr from './locales/ar/common.json';
import adminAr from './locales/ar/admin.json';


i18n.use(initReactI18next).init({
  lng: 'ar',
  fallbackLng: 'ar',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: commonEn,
    },
    ar: {
      common: commonAr,
    },
  },
}).then(r => console.log(`i18n`, r));
export default i18n;
