import {Card, Layout, Select} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Bank, Book, BookSquare, Money2, Setting2, User} from "iconsax-react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Editor} from "@tinymce/tinymce-react";
import {Textarea} from "../../../components/ui/textarea";
import {SelectShadncn, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../../components/ui/select";
import {getFetcher} from "../../../API";
import {postFetcher} from "../../../lib/API";

const { Content } = Layout;

export default function AddNewPayout() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData]:any = useState([])
    const location = useLocation()

    const [currentSelected, setSelected] = useState(location.state)
    const [users, setUsers] = useState([])
    const [form, setForm] = useState({
        type: "",
        amount: "",
        receiver_id: "",
        receiver_type: currentSelected,
        description: "",
        transaction_number: ""
})

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    useEffect(() => {
        window.document.title = "أعدادات النظام"
        async function fetchData() {
            try {
                const request = await getFetcher(token,`users?status=${currentSelected}`,true)
                console.log(request)
                // const data = await request.json()
                setUsers(request.users)

                setLoading(false)

            }
            catch (e) {
                console.log(e)
            }

        }
        fetchData()


    }, [])




    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <Setting2 size="48" color="#2dacf4" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>
                                                اضافة دفعة جديدة ({currentSelected === "HOSTING" ? "أصحاب الوحدات السكنية" : "العملاء"})
                                            </h1>
                                        </div>
                                        <Button onClick={async () => {
                                            try {
                                                if (form.type === "" || form.amount === "" || form.receiver_id === "") {
                                                    toast.error("الرجاء ملئ الحقول")
                                                    return
                                                }
                                                const request = await postFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}payouts`, form)
                                                console.log(request)
                                                toast.success("تم اضافة الدفعة بنجاح")
                                                navigate("/admin/payouts")
                                            }
                                            catch (e) {
                                                console.log(e)
                                                toast.error("حدث خطأ ما")
                                                setLoading(false)
                                            }
                                        }}>
                                            حفظ
                                        </Button>

                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div className=" mx-4 mt-4">
                            <Tabs defaultValue="addNewPayout" className="w-full">
                                <TabsList className="grid w-full grid-cols-1">
                                    <TabsTrigger value="addNewPayout">
                                        اضافة دفعة جديدة
                                    </TabsTrigger>

                                </TabsList>
                                <TabsContent value="addNewPayout">
                                    <Card>
                                        <CardHeader>
                                            <h1 className="text-xl">
                                                البيانات الاساسية
                                            </h1>


                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className="grid grid-cols-2 w-full gap-4">
                                                <div className="w-full">
                                                    <Label htmlFor="current">
                                                        نوع العملية
                                                    </Label>
                                                    <SelectShadncn onValueChange={(value: any) => {
                                                      setForm({
                                                            ...form,
                                                          type: value,
                                                      })

                                                    }}>
                                                        <SelectTrigger className="w-full">
                                                            <SelectValue placeholder="اختر نوع العملية"/>
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem value="BANK">
                                                                <div className="flex items-center gap-2">
                                                                    <Bank size={24} color="#2dacf4" variant="Bulk"/>
                                                                    <span>بنك</span>
                                                                </div>

                                                            </SelectItem>
                                                            <SelectItem value="CASH">
                                                                <div className="flex items-center gap-2">
                                                                    <Money2 size={24} color="#2dacf4" variant="Bulk"/>
                                                                    <span>كاش</span>
                                                                </div>
                                                            </SelectItem>
                                                        </SelectContent>
                                                    </SelectShadncn>

                                                </div>
                                                {form.type === "BANK" ? (
                                                    <div className="">
                                                        <Label htmlFor="current">
                                                            رقم العملية
                                                        </Label>
                                                        <Input onChange={(event)=>{
                                                            setForm({
                                                                ...form,
                                                                transaction_number: event.target.value,
                                                            })

                                                        }} className="w-full" type="text" id="current"
                                                               placeholder="القيمة"/>
                                                    </div>
                                                ) : (
                                                    null
                                                )

                                                }

                                                <div className="w-full">
                                                    <Label htmlFor="current">
                                                        {currentSelected === "HOSTING" ? "التجار" : "العملاء"}
                                                    </Label>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a person"
                                                        optionFilterProp="children"
                                                        className="w-full rounded-md mt-1"
                                                        value={form.receiver_id}
                                                        onChange={(value: any) => {
                                                            console.log(value);
                                                            setForm({
                                                                ...form,
                                                                receiver_id: value,
                                                            });
                                                        }}
                                                        filterOption={filterOption}
                                                        options={users.map((user: any) => {
                                                            return {
                                                                label: user.name,
                                                                value: user.id
                                                            };
                                                        })}
                                                    />


                                                </div>
                                                <div className="">
                                                    <Label htmlFor="current">
                                                        رقم العملية
                                                    </Label>
                                                    <Input onChange={(event)=>{
                                                        setForm({
                                                            ...form,
                                                            amount: event.target.value,
                                                        })
                                                    }} className="w-full" type="number" id="current"
                                                           placeholder="القيمة"/>
                                                </div>
                                            </div>

                                            <div>

                                            </div>


                                        </CardContent>
                                        <CardContent>
                                            <Label htmlFor="current">
                                                وصف العملية
                                            </Label>
                                            <Textarea onChange={(event)=>{
                                                setForm({
                                                    ...form,
                                                    description: event.target.value,
                                                })

                                            }}>

                                            </Textarea>
                                        </CardContent>


                                    </Card>

                                </TabsContent>


                            </Tabs>
                        </div>


                    </Content>
                </>
            )}


        </>
    )
}