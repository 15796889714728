import {Card, Checkbox, Input, Layout, Table} from "antd";
import {ArrowLeft2, ArrowRight2, Data2, House} from "iconsax-react";
import AdminLayout from "src/Layouts/Main";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader
} from "src/components/ui/alert-dialog";
import {CardContent, CardHeader} from "src/components/ui/card";
import toast, {Toaster} from "react-hot-toast";
import {HashLoader} from "react-spinners";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {setIsAuth, setUserToken} from "src/redux/slices/user_slice";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Button} from "../../../components/ui/button";
import {Icon} from "@iconify/react";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {ColumnsType} from "antd/es/table";
import {SortOrder} from "antd/es/table/interface";


const {Content} = Layout;

interface HostUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    units_count: number;
    total_reservation: number;
    bank_account: { id: number; iban: string; account_name: string | null }[];

}


export default function HostUsers() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [currentFirstNameSort, setCurrentFirstNameSort] = useState<'ascend' | 'descend' | null>("ascend");
    const [currentLastNameSort, setCurrentLastNameSort] = useState<'ascend' | 'descend' | null>("ascend");
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)


    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users?status=hosting&page=${page}&limit=${pageSize}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.data.items)
                setPage(data.data.pagination.currentPage)
                setPageSize(data.data.pagination.perPage)
                setTotal(data.data.pagination.total)
            } else {

            }
        } catch (error) {
            dispatch(setIsAuth(false))
            dispatch(setUserToken(''))
            navigate('/auth/admin/login')
            console.error(error)
        }

    }


    useEffect(() => {
        window.document.title = "المستخدمين"
        console.log(currentFirstNameSort)
        fetchData()

    }, [page, pageSize])
    const columns: ColumnsType<HostUser> = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => {
                const valueA = a.id ?? Infinity;
                const valueB = b.id ?? Infinity;
                return valueA - valueB;
            },
            defaultSortOrder: 'ascend',
        },
        {
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
            sorter: (a, b) => {
                const arabicRegex = /[\u0600-\u06FF]/;
                const aIsArabic = arabicRegex.test(a.first_name);
                const bIsArabic = arabicRegex.test(b.first_name);

                if (currentFirstNameSort === 'descend') {
                    if (a.first_name === null || a.first_name == "") return 1;
                    if (b.first_name === null || b.first_name == "") return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.first_name?.toLowerCase().localeCompare(b.first_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }

                if (currentFirstNameSort === 'ascend') {
                    if (a.first_name === null) return 1;
                    if (b.first_name === null) return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.first_name?.toLowerCase().localeCompare(b.first_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }

                return 0; // No sorting
            },
            sortOrder: currentFirstNameSort,
            sortDirections: ['ascend', 'descend', null],
            onHeaderCell: () => ({
                onClick: () => {
                    if (currentFirstNameSort === 'ascend') {
                        setCurrentFirstNameSort('descend');
                    } else if (currentFirstNameSort === 'descend') {
                        setCurrentFirstNameSort(null);
                    } else {
                        setCurrentFirstNameSort('ascend');
                    }
                },
            }),
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
            sorter: (a, b) => {
                const arabicRegex = /[\u0600-\u06FF]/;
                const aIsArabic = arabicRegex.test(a.last_name?.toLowerCase());
                const bIsArabic = arabicRegex.test(b.last_name?.toLowerCase());

                if (currentLastNameSort === 'descend') {
                    if (a.last_name === null || a.last_name == "") return 1;
                    if (b.last_name === null || b.last_name == "") return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.last_name.toLowerCase().localeCompare(b.last_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }

                if (currentLastNameSort === 'ascend') {
                    if (a.last_name === null) return 1;
                    if (b.last_name === null) return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.last_name.toLowerCase().localeCompare(b.last_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }


                return 0; // No sorting
            },
            sortOrder: currentLastNameSort,
            sortDirections: ['ascend', 'descend', null],
            onHeaderCell: () => ({
                onClick: () => {
                    if (currentLastNameSort === 'ascend') {
                        setCurrentLastNameSort('descend');
                    } else if (currentLastNameSort === 'descend') {
                        setCurrentLastNameSort(null);
                    } else {
                        setCurrentLastNameSort('ascend');
                    }
                },
            }),
        },
        {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
            sorter: (a, b) => {
                const valueA = a.phone ?? "zzzzzz";
                const valueB = b.phone ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => {
                const valueA = a.email ?? "zzzzzz";
                const valueB = b.email ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Number of Units",
            dataIndex: "units_count",
            key: "units_count",
            sorter: (a, b) => {
                const valueA = a.units_count ?? Infinity;
                const valueB = b.units_count ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Total Reservations",
            dataIndex: "total_reservation",
            key: "total_reservation",
            sorter: (a, b) => {
                const valueA = a.total_reservation ?? Infinity;
                const valueB = b.total_reservation ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Bank Account Iban",
            dataIndex: "bank_account",
            key: "bank_account",
            render: (bank_account) => (
                <div>
                    {bank_account.map((account: any) => (
                        <div key={account.id}>
                            <p>IBAN: {account.iban}</p>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "Bank Account Name",
            dataIndex: "bank_account",
            key: "bank_account",
            render: (bank_account) => (
                <div>
                    {bank_account.map((account: any) => (
                        <div key={account.id}>
                            <p>IBAN: {account.account_name}</p>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record: HostUser) => (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger>الإجراءات</DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>الإجراءات</DropdownMenuLabel>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem
                                onClick={() => {
                                    navigate(`/admin/host/properties/${record.id}`);
                                }}
                            >
                                عرض الوحدات السكنية الخاصة بهذا المالك
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            ),
        },
    ];

    const itemRender = (_: any, type: string, originalElement: any) => {
        if (type === "prev") {
            return <ArrowRight2 size="32" color="#333"/>;
        }
        if (type === "next") {
            return <ArrowLeft2 size="32" color="#333"/>;
        }
        return originalElement;
    };
    const exportToExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, "host_users.xlsx");
    };
    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7"/>
                </div>

            ) : (
                <>
                    <div><Toaster/></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                                                <House
                                                    color="#475467"
                                                    variant="Bulk"
                                                    size={32}
                                                />

                                            </div>
                                            <div>
                                                <h1 className="text-xl"> أصحاب الوحدات السكنية </h1>
                                            </div>


                                        </div>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button>
                                                    تصدير
                                                </Button>

                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="w-56">
                                                <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                <DropdownMenuSeparator/>
                                                <DropdownMenuGroup>
                                                    <DropdownMenuItem onClick={() => exportToExcel(data)}
                                                                      className="gap-2">
                                                        <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                        <span>تصدير الى Excel</span>
                                                    </DropdownMenuItem>
                                                    {/*<DropdownMenuItem>*/}
                                                    {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                                                    {/*  <span>تصدير الى pdf</span>*/}
                                                    {/*</DropdownMenuItem>*/}


                                                </DropdownMenuGroup>

                                            </DropdownMenuContent>
                                        </DropdownMenu>

                                    </div>

                                </CardHeader>
                            </Card>
                        </div>
                        <div className="mx-6 mt-6">
                            <Input
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="
                                ابحث برقم صاحب الوحدة السكنية أو البريد الإلكتروني أو الاسم
                            "
                            />
                        </div>
                        <div className="mx-6 mt-6">

                            <Table dataSource={
                                data.filter((val: any) => {
                                    if (searchText == "") {
                                        return val
                                    } else if (val.id.toString()?.includes(searchText) || val.email?.includes(searchText) || val.first_name?.includes(searchText) || val.last_name?.includes(searchText)) {
                                        return val
                                    }
                                })}
                                   rowKey="id"
                                   showHeader={true}
                                   bordered={true}
                                   scroll={{x: 'max-content'}}
                                   locale={{
                                       emptyText: (
                                           <div className="flex flex-col items-center justify-center">
                                               <Data2 size={64} color="#6B7280"/>
                                               <span className="mt-2 text-xl font-bold text-gray-600">
                                             لا توجد بيانات
                                        </span>
                                           </div>
                                       ),

                                   }}
                                   virtual={true}
                                   pagination={{
                                       showSizeChanger: true,
                                       showQuickJumper: true,
                                       showTotal: (total) => `Total ${total} items`,
                                       total: total,
                                       current: page,
                                       position: ["bottomCenter"],
                                       size: "default",
                                       itemRender: itemRender

                                   }}
                                   onChange={(pagination, filters, sorter, extra) => {
                                       console.log('params', pagination, filters, sorter, extra);
                                       setPage(pagination.current ?? 1)
                                       setPageSize(pagination.pageSize ?? 10)
                                   }}
                                   sortDirections={["ascend", "descend"]}
                                   showSorterTooltip={true}
                                   loading={loading}
                                   columns={columns}/>;


                        </div>

                    </Content>
                </>
            )}


        </>
    )
}