import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { useNavigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {setImage, setIsAuth, setName, setRole, setUserToken} from "src/redux/slices/user_slice";
import { Label } from "@radix-ui/react-label";
import React from "react";


const formSchema = z.object({
    email: z.string().min(2, {
        message: "Username must be at least 2 characters.",

    }),
    password: z.string().min(2, {
        message: "Password must be at least 2 characters.",
    })
})

export function Login() {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    })
    const dispatch: any = useAppDispatch()
    const is_auth = useAppSelector(state => state.user.is_auth)
    const navigate = useNavigate();
    const [isLoading, setLoading] = React.useState(false)

    useEffect(() => {
       
        window.document.title = "تسجيل الدخول";

    }, []);

    async function HandleSubmit(e:any) {
        e.preventDefault();
        toast.promise(
            fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: form.getValues("email"),
                    password: form.getValues("password"),
                }),
            }).then(async (response) => {
                const data = await response.json();
                console.log(data)
                if (data.data.token) {
                    console.log(data.data.profile.roles[0].name)
                    dispatch(setUserToken(data.data.token))
                    dispatch(setIsAuth(true))
                    dispatch(setName(data.data.profile.first_name + " " + data.data.profile.last_name))
                    dispatch(setRole(data.data.profile.roles[0].name))
                    dispatch(setImage(data.data.profile.storage_image))
                    
                    switch (data.data.profile.roles[0].name) {
                        case "Super Admin":
                            navigate('/admin/dashboard')
                            break;
                        case "CustomerService":
                            navigate('/customer-service/dashboard')
                            break;
                        default:
                            // navigate('/dashboard')
                            break;

                    }
                }
            }),
            {
                loading: "جاري تسجيل الدخول",
                success: "تم تسجيل الدخول بنجاح",
                error: "خطأ في تسجيل الدخول",
            }
        );
        
    }

   


    return (
        <>
            <div><Toaster /></div>

            <div style={{direction:"rtl"}} className="container sm:justify-center items-center flex  relative  h-screen bg-white flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">

                <div className="lg:p-8">
                    <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                        <div className="flex flex-col space-y-2 text-center">
                            <h1 className="text-2xl font-semibold tracking-tight">
                                تسجيل الدخول
                            </h1>

                        </div>
                        <div className="grid gap-6">
                            <form onSubmit={(e) => HandleSubmit(e)}  >
                                <div className="grid gap-2">
                                    <div className="grid gap-1">
                                        <Label className="sr-only" htmlFor="email">
                                            Email
                                        </Label>
                                        <Input
                                            id="email"
                                            placeholder="name@example.com"
                                            type="email"
                                            autoCapitalize="none"
                                            autoComplete="email"
                                            autoCorrect="off"
                                            disabled={isLoading}
                                            required={true}
                                            onChange={(e) => form.setValue("email", e.target.value)}
                                        />
                                    </div>
                                    <div className="grid gap-1">
                                        <Label className="sr-only" htmlFor="email">
                                            الأيميل
                                        </Label>
                                        <Input
                                            id="password"
                                            type="password"
                                            autoCapitalize="none"
                                            autoComplete="password"
                                            autoCorrect="off"
                                            disabled={isLoading}
                                            required={true}
                                            onChange={(e) => form.setValue("password", e.target.value)}

                                        />
                                    </div>
                                    <Button disabled={isLoading}>
                                        {/* {isLoading && (
                                            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                                        )} */}
                                       تسجيل الدخول
                                    </Button>
                                </div>
                            </form>


                        </div>

                    </div>
                </div>
                <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
                    <div className="absolute inset-0 h-full ">
                        <img style={{objectFit:"cover"}} className="h-full"  src="/assets/imgs/Villa.jpg" alt=""/>
                    </div>
                    <div className="relative z-20 flex items-center text-lg font-medium">
                        <svg width="64" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="64" height="64" rx="5.14473" fill="#22A2ED"/>
                            <path
                                d="M40.7671 55.5424C39.179 57.1645 37.2149 57.8138 35.0902 58.0646C32.3698 58.3857 29.6732 58.3577 27.0883 57.2806C23.8966 55.9507 22.3304 53.3966 22.0542 50.0865C21.6468 45.205 21.7673 40.3023 21.7703 35.4073C21.7708 34.6522 21.5555 34.1121 21.0157 33.5656C13.1473 25.6011 15.2272 12.334 25.1598 7.41207C34.0357 3.01373 45.3146 7.21071 48.1409 17.6088C49.7811 23.643 48.2979 28.9973 44.0074 33.5397C43.4722 34.1063 43.2295 34.6545 43.1964 35.4458C43.0143 39.8047 43.1507 44.1645 43.0864 48.5231C43.0486 51.0867 42.5132 53.4851 40.7671 55.5424ZM19.4764 17.4896C18.2438 21.0777 18.47 24.5773 20.1152 27.9893C20.9833 29.7898 22.2855 31.2493 23.7407 32.5847C24.0484 32.8671 24.3171 33.1508 24.2737 33.6102C24.187 34.5256 24.3087 35.438 24.3155 36.3516C24.3442 40.2271 24.3576 44.1028 24.3633 47.9785C24.3644 48.789 24.3933 49.5951 24.5688 50.3857C25.105 52.8003 26.4409 54.4961 28.8687 55.2624C30.7883 55.8683 32.7499 55.8695 34.6955 55.6395C37.5529 55.3017 39.6312 53.9031 40.3405 50.9225C40.5157 50.1863 40.6041 49.4426 40.6149 48.6849C40.6785 44.1902 40.8355 39.6945 40.7028 35.201C40.6593 33.7286 41.0673 32.6993 42.1394 31.6227C46.217 27.5277 47.3622 22.6293 45.3695 17.17C43.4328 11.8642 37.8427 7.29275 30.6572 8.33313C25.2617 9.11436 21.5343 12.2563 19.4764 17.4896Z"
                                fill="#FDFDFD" stroke="white" stroke-width="0.501646"/>
                            <path
                                d="M30.6895 53.4267C29.4834 53.056 28.6295 52.536 28.0537 51.8242C27.4768 51.1111 27.1576 50.1791 27.0719 48.9454C26.895 46.4004 26.9135 43.8627 26.9322 41.3138C26.9425 39.9092 26.9528 38.5013 26.9304 37.0869C26.9262 36.8236 26.9873 36.6979 27.0551 36.6331C27.1257 36.5656 27.2574 36.5121 27.5092 36.5143C27.7535 36.5164 28.0004 36.5171 28.2478 36.5117C28.5397 36.5052 28.682 36.5609 28.7577 36.6345C28.8333 36.7081 28.8998 36.8554 28.8995 37.1757C28.8965 40.7056 28.9349 44.2354 28.9752 47.7633C28.9812 48.2873 29.0376 48.8186 29.1429 49.3323C29.2535 49.8722 29.4735 50.3338 29.8111 50.7022C30.1488 51.0706 30.5932 51.3337 31.134 51.4935C31.9495 51.7344 32.7754 51.7329 33.5706 51.6317C34.2148 51.5498 34.7461 51.301 35.1474 50.8979C35.5472 50.4964 35.8021 49.9577 35.9234 49.321C36.0899 48.447 36.0785 47.5563 36.0675 46.6963C36.0637 46.3969 36.0599 46.1013 36.0637 45.8114C36.0813 44.4672 36.0895 43.1328 36.0978 41.7994C36.1068 40.3385 36.1158 38.8788 36.1371 37.4087C36.1381 37.3345 36.1335 37.2501 36.1293 37.1749C36.1278 37.1475 36.1264 37.1214 36.1253 37.0974C36.1208 36.9972 36.1209 36.9137 36.1324 36.8427C36.1435 36.7737 36.1632 36.7308 36.1875 36.7019C36.2099 36.6752 36.2524 36.6411 36.3459 36.6181C36.9251 36.4755 37.4959 36.4407 38.0128 36.6719C38.0358 36.6821 38.0439 36.6919 38.0486 36.6991C38.0553 36.7091 38.064 36.7284 38.07 36.7652C38.0761 36.8027 38.0778 36.8486 38.0767 36.9065C38.0762 36.9349 38.075 36.9637 38.0738 36.995L38.0737 36.9958C38.0725 37.0264 38.0712 37.06 38.0706 37.0931C38.0467 38.5756 38.0588 40.0512 38.0708 41.5233C38.0924 44.1661 38.1139 46.7974 37.9269 49.4373C37.7588 51.8109 36.0043 53.5008 33.6033 53.5382C33.4111 53.5412 33.2211 53.5468 33.0325 53.5524C32.2486 53.5757 31.4887 53.5983 30.6895 53.4267Z"
                                fill="#FDFDFD" stroke="white" stroke-width="0.501646"/>
                        </svg>


                    </div>

                </div>

            </div>


        </>

    )
}
