import {Card, Checkbox, Layout} from "antd";
import {useEffect, useState} from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
    Book,
    Building3, Edit,
    Edit2, Eye,
    MoneyRecive,
    PathTool,
    Personalcard, SecurityUser,
    Setting2,
    Trash,
    User,
    UserOctagon
} from "iconsax-react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../components/ui/alert-dialog";
import { Editor } from "@tinymce/tinymce-react";
import {deleteFettcher, getFetcher} from "../../../API";
import {Button} from "../../../components/ui/button";
import {Input} from "../../../components/ui/input";
import {Label} from "../../../components/ui/label";
import {Switch} from "../../../components/ui/switch";
import {postFetcher} from "../../../lib/API";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";

const { Content } = Layout;

export default function Roles() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [roles, setRoles] = useState([])
    const [isDelete, setIsDelete] = useState(false)
    const [roleId, setRoleId] = useState(0)
    async function fetchData(){
        try {
            const request = await getFetcher(token,"roles",true)
            if (request) setLoading(false)
            setRoles(request.roles)



            console.log(request)
        }catch (e) {

        }
    }


    useEffect(() => {
        window.document.title = "الاعدادات"

        fetchData()
    }, [])





    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <SecurityUser variant="Bulk"
                                                             size="48" color="#475467" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>
                                                الصلاحيات
                                            </h1>
                                        </div>

                                            <div>
                                                <Button onClick={() => {
                                                    navigate("/admin/users/role/add")
                                                }
                                                }>
                                                    اضافة  صلاحية
                                                </Button>
                                            </div>
                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div className="mx-6 mt-6">
                            <div className="grid grid-cols-2 gap-4">
                                {roles.map((role:any) => (
                                    <Card>
                                        <div className="flex items-center my-1 justify-between">
                                            <div className="flex items-center">
                                                <div
                                                    style={{ background: "#f9f9f9" }}
                                                    className={" p-2 rounded-md"}
                                                >
                                                    <SecurityUser variant="Bulk"
                                                                  size="48" color="#475467" />

                                                </div>

                                                <h1 className={"text-xl mr-2"}>
                                                    {role.name}
                                                </h1>
                                            </div>
                                            <div>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                        <Icon
                                                            width={24}
                                                            height={24}
                                                            className="cursor-pointer"
                                                            icon="pepicons-pop:dots-y"
                                                        />
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent className="w-56">
                                                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                        <DropdownMenuSeparator />
                                                        <DropdownMenuGroup>
                                                            <DropdownMenuItem onClick={async function () {
                                                                navigate(`/admin/users/role/edit`,{state:role})

                                                            }}>
                                                                <Edit
                                                                    color="#475467"
                                                                    variant="Bulk"
                                                                    className="ml-2 h-8 w-8"
                                                                />
                                                                <span>تعديل</span>
                                                            </DropdownMenuItem>

                                                            <DropdownMenuItem onClick={()=>{
                                                                setRoleId(role.id)
                                                                setIsDelete(true)

                                                            }}>
                                                                <Trash
                                                                    color="#F04438"
                                                                    variant="Bulk"
                                                                    className="ml-2 h-8 w-8"
                                                                />
                                                                <span>حذف</span>
                                                            </DropdownMenuItem>
                                                        </DropdownMenuGroup>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        </div>
                                    </Card>
                                ))
                                }

                            </div>

                        </div>

                        <AlertDialog open={isDelete}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>
                                        تأكيد الحذف
                                    </AlertDialogTitle>

                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => {
                                        setIsDelete(false)

                                    }}>الغاء</AlertDialogCancel>
                                    <AlertDialogAction onClick={async () => {
                                        const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}roles/${roleId}`, {
                                            method: 'DELETE',
                                            headers: {
                                                "Authorization": `Bearer ${token}`,
                                                "Content-Type": "application/json",
                                                "Accept": "application/json"
                                            },
                                            body: JSON.stringify({id: roleId})

                                        })
                                        const data = await request.json()
                                        if (request.status === 200) {
                                            toast.success("تم الحذف بنجاح")
                                            fetchData()
                                            setIsDelete(false)

                                        } else {
                                            toast.error(data.message)

                                        }
                                    }}


                                    >حذف</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </Content>
                </>
            )}


        </>
    )
}