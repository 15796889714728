import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { Bank, Book, BookSquare, Setting2, User } from "iconsax-react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import { Editor } from "@tinymce/tinymce-react";
import {useForm} from "react-hook-form";

const { Content } = Layout;

export default function EditPage() {
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()

    const location = useLocation()
    const [data, setData]:any = React.useState({})
    const [form, setForm] = React.useState({
        titleAr: "",
        descriptionAr: "",
        titleEn: "",
        descriptionEn: "",
    })









    useEffect(() => {
        console.log(location.state)
        window.document.title = "أعدادات النظام"
        async function fetchData() {
            try {

                const request = await fetch(`${process.env.REACT_APP_DEV_API_URL}get-page?slug=${location.state.slug}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                })
                const data = await request.json();
                console.log(data)
                if (request.status == 200) {
                    setLoading(false)

                }
                setData(data.data)

                const titleArEntry = data.data.translation.find((entry:any) => entry.language.code === 'ar' && entry.key === 'title');
                const descriptionArEntry = data.data.translation.find((entry:any) => entry.language.code === 'ar' && entry.key === 'description');
                const titleEnEntry = data.data.translation.find((entry:any) => entry.language.code === 'en' && entry.key === 'title');
                const descriptionEnEntry = data.data.translation.find((entry:any) => entry.language.code === 'en' && entry.key === 'description');

                setForm({
                    titleAr: titleArEntry?.value || '',
                    descriptionAr: descriptionArEntry?.value || '',
                    titleEn: titleEnEntry?.value || '',
                    descriptionEn: descriptionEnEntry?.value || '',
                });

            } catch (error) {
                console.error(error)
                dispatch(setIsAuth(false))
                dispatch(setUserToken(''))
                navigate('/auth/admin/login')
            }
        }
        fetchData()


    }, [])




    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction:"rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                                                <Setting2 size="48" color="#2dacf4" variant="Bulk" />
                                            </div>

                                            <h1 className={"text-xl mr-2"}>أعدادات النظام</h1>
                                        </div>
                                        <Button onClick={async () => {
                                            if (form.titleAr === "" && form.descriptionAr === "" && form.titleEn === "" && form.descriptionEn === "") {
                                                toast("الرجاء كتابة المحتوى باللغتين العربية والانقليزية", )
                                                return
                                            }
                                            const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}pages/${location.state.id}`, {
                                                method: "PUT",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    "Authorization": "Bearer " + token,
                                                    "Accept": "application/json",
                                                },
                                                body:JSON.stringify(form)
                                            })
                                            const data = await request.json();
                                            console.log(data)
                                            if (request) {
                                                toast.success('تم تحديث الصفحة بنجاح')

                                            }

                                        }}>
                                            حفظ
                                        </Button>

                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <div className=" mx-4 mt-4">
                            <Tabs defaultValue="arabic" className="w-full">
                                <TabsList className="grid w-full grid-cols-2">
                                    <TabsTrigger value="english">بالانقليزي</TabsTrigger>
                                    <TabsTrigger value="arabic">بالعربي</TabsTrigger>

                                </TabsList>
                                <TabsContent value="english">
                                    <Card>
                                        <CardHeader>

                                            
                                          
                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-full">
                                                <Label  htmlFor="current">العنوان</Label>
                                                <Input
                                                    onChange={(event) => setForm({
                                                        ...form,
                                                        titleEn: event.target.value
                                                    })}
                                                    value={form.titleEn}
                                                    id="titleEn"
                                                />






                                            </div>
                                            <div>

                                            </div>




                                        </CardContent>
                                        <CardContent>
                                            <Editor
                                                apiKey='yytl70c7b5e96ey2zbtm8fopv1huio0fv1837anrnivt4sgz'
                                                init={{
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }}

                                                id="descriptionEn"
                                                value={
                                                    form.descriptionEn
                                                }

                                                onInit={(evt, editor) => editor.setContent(form.descriptionEn)}
                                                onEditorChange={(event) => {
                                                    setForm({
                                                        ...form,
                                                        descriptionEn: event
                                                    })
                                                }
                                                }
                                            />
                                        </CardContent>

                                        
                                    </Card>
                                    
                                </TabsContent>
                                <TabsContent value="arabic">
                                    <Card>
                                        <CardHeader>



                                        </CardHeader>
                                        <CardContent className="space-y-2 flex flex-row justify-between w-full items-baseline">
                                            <div className=" w-full">
                                                <Label  htmlFor="current">العنوان</Label>
                                                <Input
                                                    onChange={(event) => setForm({
                                                        ...form,
                                                        titleAr: event.target.value
                                                    })}
                                                    value={
                                                        form.titleAr
                                                    }  id="titleAr"  />

                                            </div>
                                            <div>

                                            </div>




                                        </CardContent>
                                        <CardContent>
                                            <Editor
                                                apiKey='yytl70c7b5e96ey2zbtm8fopv1huio0fv1837anrnivt4sgz'
                                                init={{
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                    directionality: 'ltr',
                                                    language: 'ar'
                                                }}
                                                value={
                                                form.descriptionAr
                                                }
                                                onInit={(evt, editor) => editor.setContent(form.descriptionAr)}
                                                onEditorChange={(event) => {
                                                    setForm({
                                                        ...form,
                                                        descriptionAr: event
                                                    })
                                                }
                                                }
                                                id="descriptionAr"
                                            />
                                        </CardContent>


                                    </Card>

                                </TabsContent>


                                
                            </Tabs>
                        </div>



                    </Content>
                </>
            )}




        </>
    )
}